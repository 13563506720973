import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class DialogDeviceInput extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			shown:false,
			inputType:"capture",
			mediaCasterType:"rtp",
			mediaCasterStreamInfo:"",
			decodingImplementation:"default",
			deinterlace: "none",
			pendingCommandIndex:0
		};

		this.close = this.close.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.onShow = this.onShow.bind(this);
		this.save = this.save.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
	}

	onEnter()
	{
		/*
			"broadcastInputConfigCalculated": {
			"inputType": "capture",
			"mediaCasterType": null,
			"mediaCasterStreamInfo": null,
			"decodingImplementation": "default",
			"deinterlace": false,
			"deinterlaceDoubleRate": false,
			"deinterlaceImplementation": "default"
		 */

		let device = this.props.clearcaster.currentLiveEncoder;

		let newState = {};

		if (device !== undefined && device.status !== undefined && device.status.hasOwnProperty("broadcastInputConfigCalculated"))
		{
			let deviceInfo = device.status.broadcastInputConfigCalculated;

			console.log("onEnter[source]:\n"+JSON.stringify(deviceInfo, null, 2));

			newState.inputType = deviceInfo.inputType.toLocaleLowerCase();

			if (newState.inputType === "mediacaster")
			{
				newState.mediaCasterType = deviceInfo.mediaCasterType.toLocaleLowerCase();
				newState.mediaCasterStreamInfo = deviceInfo.mediaCasterStreamInfo;
				newState.decodingImplementation = deviceInfo.decodingImplementation.toLocaleLowerCase();

				if (deviceInfo.deinterlace)
				{
					newState.deinterlace = "deinterlace";
					if (deviceInfo.deinterlaceDoubleRate)
					{
						newState.deinterlace += "-double";
					}
					else
					{
						newState.deinterlace += "-single";
					}

					if (deviceInfo.deinterlaceImplementation.toLocaleLowerCase() === "yadif")
					{
						newState.deinterlace += "-yadif";
					}
					else
					{
						newState.deinterlace += "-default";
					}
				}
				else
				{
					newState.deinterlace = "none"
				}
			}
			else
			{
				newState.mediaCasterType = "rtp";
				newState.mediaCasterStreamInfo = "";
				newState.decodingImplementation = "default";
				newState.deinterlace = "none";
			}
		}

		newState.pendingCommandIndex = 0;

		console.log("onEnter[set]:\n"+JSON.stringify(newState, null, 2));

		this.setState(newState);
	}

	onShow()
	{
	}

	close(event)
	{
		this.props.controller.hideDialogDeviceInput();
		if (event !== null && !(typeof event === 'undefined'))
			event.preventDefault();
	}

	componentWillReceiveProps(nextProps)
	{
		if (nextProps.clearcaster && nextProps.clearcaster.currentLiveEncoder && nextProps.clearcaster.currentLiveEncoder.status && nextProps.clearcaster.currentLiveEncoder.status.index)
		{
			if (this.state.pendingCommandIndex > 0 && nextProps.clearcaster.currentLiveEncoder.status.index >= this.state.pendingCommandIndex)
			{
				let _this = this;

				this.setState({pendingCommandIndex:0});

				setTimeout(function() { _this.props.controller.hideDialogDeviceInput(); }, 500);
			}
		}
	}

	save(event)
	{
		let deviceInfo = {};

		deviceInfo.inputType = this.state.inputType;
		deviceInfo.mediaCasterType = this.state.mediaCasterType;
		deviceInfo.mediaCasterStreamInfo = this.state.mediaCasterStreamInfo;
		deviceInfo.decodingImplementation = this.state.decodingImplementation;

		if (this.state.deinterlace === "none")
		{
			deviceInfo.deinterlace = false;
			deviceInfo.deinterlaceDoubleRate = false;
			deviceInfo.deinterlaceImplementation = "default";
		}
		else
		{
			let parts = this.state.deinterlace.split("-");

			deviceInfo.deinterlace = true;
			deviceInfo.deinterlaceDoubleRate = (parts[1]==="double");
			deviceInfo.deinterlaceImplementation = parts[2];
		}

		console.log("save:\n"+JSON.stringify(deviceInfo, null, 2));

		this.setState({pendingCommandIndex:this.props.controller.getNextCommandIndex});
		this.props.controller.setDeviceInput(this.props.clearcaster.currentLiveEncoder.encoder_uuid, deviceInfo);

		if (event !== null && !(typeof event === 'undefined'))
			event.preventDefault();
	}

	render()
	{
		let device = this.props.clearcaster.currentLiveEncoder;

		if (device == null)
			return null;

		// let version = "0.0.0.0";
		// if (device.status)
		// {
		// 	if (device.status.versionCurrent)
		// 		version = device.status.versionCurrent;
		// }

		let enableMediaCaster = (this.state.inputType === "mediacaster");

		return (
			<Modal show={this.props.clearcaster.dialogs.showDeviceInput} onEnter={ this.onEnter } onShow={ this.onShow } onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.DeviceInputColon } { device.name }</Modal.Title>
				</Modal.Header>
				<form>
					<Modal.Body>
						<div className="form-group" style={{marginBottom:0}}>
							<div id="InputType" style={{marginBottom:'1em'}}>
								<div className="row">
									<label style={{width:'100%'}}>
										{this.props.strings.app.InputType}<br />
									</label>
								</div>
								<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
									<select className="form-control" id="dialog-deviceinput-inputtype" onChange={evt => this.setState({ inputType: evt.target.value })} value={ this.state.inputType }>
										<option value="capture">{this.props.strings.app.SourceCapture}</option>
										<option value="mediacaster">{this.props.strings.app.SourceMediaCaster}</option>
									</select>
								</div>
							</div>
							<div id="MediaCasterType" style={{marginBottom:'1em'}}>
								<div className="row">
									<label style={{width:'100%'}}>
										{this.props.strings.app.MediaCasterType}<br />
									</label>
								</div>
								<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
									<select className="form-control" id="dialog-deviceinput-mediacastertype" disabled={ !enableMediaCaster } onChange={evt => this.setState({ mediaCasterType: evt.target.value })} value={ this.state.mediaCasterType }>
										<option value="rtp">{this.props.strings.app.MediaCasterTypeRTP}</option>
										<option value="srt">{this.props.strings.app.MediaCasterTypeSRT}</option>
										<option value="liverepeater">{this.props.strings.app.MediaCasterTypeLiveRepeater}</option>
										<option value="applehls">{this.props.strings.app.MediaCasterTypeAppleHLS}</option>
									</select>
								</div>
							</div>
							<div id="MediaCasterStreamInfo" style={{marginBottom:'1em'}}>
								<div className="row">
									<label style={{width:'100%'}}>
										{this.props.strings.app.MediaCasterStreamInfo}<br />
									</label>
								</div>
								<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
									<input id="dialog-deviceinput-mediacasterstreaminfo" className="form-control" type="text" disabled={ !enableMediaCaster } onChange={evt => this.setState({ mediaCasterStreamInfo: evt.target.value })} value={ this.state.mediaCasterStreamInfo }/>
								</div>
							</div>

							<div id="DecoderImplementation" style={{marginBottom:'1em'}}>
								<div className="row">
									<label style={{width:'100%'}}>
										{this.props.strings.app.DecoderImplementation}<br />
									</label>
								</div>
								<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
									<select className="form-control" id="dialog-deviceinput-decoding-implementation" disabled={ !enableMediaCaster } onChange={evt => this.setState({ decodingImplementation: evt.target.value })} value={ this.state.decodingImplementation }>
										<option value="default">{this.props.strings.app.DecoderImplementationDefault}</option>
										<option value="quicksync">{this.props.strings.app.DecoderImplementationQuickSync}</option>
									</select>
								</div>
							</div>

							<div id="DecoderDeinterlace" style={{marginBottom:'1em'}}>
								<div className="row">
									<label style={{width:'100%'}}>
										{this.props.strings.app.Deinterlacing}<br />
									</label>
								</div>
								<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
									<select className="form-control" id="dialog-deviceinput-deinterlacing" disabled={ !enableMediaCaster } onChange={evt => this.setState({ deinterlace: evt.target.value })} value={ this.state.deinterlace }>
										<option value="none">{this.props.strings.app.DeinterlacNone}</option>
										<option value="deinterlace-single-default">{this.props.strings.app.DeinterlacSingleRateDef}</option>
										<option value="deinterlace-single-yadif">{this.props.strings.app.DeinterlacSingleRateYADIF}</option>
										<option value="deinterlace-double-default">{this.props.strings.app.DeinterlacDoubleRateDef}</option>
										<option value="deinterlace-double-yadif">{this.props.strings.app.DeinterlacDoubleRateYADIF}</option>
									</select>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginRight:0}}>
								<button id="dialog-deviceinput-cancel-button" className="btn" onClick={this.close}>{ this.props.strings.app.Cancel }</button>
								<button id="dialog-deviceinput-ok-button" className="btn btn-primary" onClick={this.save}>{ this.props.strings.app.OK }</button>
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>
		);
	}
}

export default DialogDeviceInput;
