var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

import useSheet from "react-jss";
import transitionStyles from "../transition-styles";
export default useSheet(_extends({
  container: {
    position: "fixed",
    paddingTop: "6px",
    paddingRight: "15px",
    paddingBottom: "6px",
    paddingLeft: "0",
    zIndex: "10301"
  },
  "top-center": {
    top: 0,
    left: 0,
    right: 0
  }
}, transitionStyles));