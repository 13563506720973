import AppStrings from '../model/AppStrings';
import { getEncoderTopicByPairingId } from '../transport/MQTTConnection';
import { getWowzaPairingKeyFromUUID, isVersionGreaterThan } from './ClearCasterAdminUtils.js';

export default function ClearCasterAdminControllerUpdatesMixin (controller)
{
    controller.getUpdate = (localVersions, currentVersionStr) =>
	{
		if (localVersions && localVersions !== null && typeof localVersions !== 'undefined' &&
			currentVersionStr !== null && typeof currentVersionStr === 'string')
		{
			if (localVersions.osupdate && localVersions.osupdate !== null && localVersions.osupdate.length > 0)
			{
				for (var i = 0; i < localVersions.osupdate.length; i++)
				{
					if (isVersionGreaterThan(localVersions.osupdate[i].v,currentVersionStr))
					{
						return localVersions.osupdate[i];
					}
				}
			}
			if (localVersions.clearcaster && localVersions.clearcaster !== null && localVersions.clearcaster.length > 0)
			{
				for (var j = 0; j < localVersions.clearcaster.length; j++)
				{
					if (isVersionGreaterThan(localVersions.clearcaster[j].v,currentVersionStr))
					{
						return localVersions.clearcaster[j];
					}
				}
			}
		}
		return null;
	}

	controller.getRollback = (localVersions,currentVersionStr) =>
	{
		if (localVersions && localVersions !== null && typeof localVersions !== 'undefined' &&
			currentVersionStr !== null && typeof currentVersionStr === 'string')
		{
			if (localVersions.clearcaster && localVersions.clearcaster !== null && localVersions.clearcaster.length > 0)
			{
				for (var j = 0; j < localVersions.clearcaster.length; j++)
				{
					if (isVersionGreaterThan(currentVersionStr,localVersions.clearcaster[j].v))
					{
						return localVersions.clearcaster[j];
					}
				}
			}
		}
		return null;
	}

	controller.applyUpdate = (device_id,update,isRollback = false) =>
	{
		let updateStr = JSON.stringify(update);
		updateStr = btoa(updateStr);

		let alertHeader = "Update started...";
		if (isRollback)
			alertHeader = "Roll back started...";

		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			controller.raiseAlert({
				type:'success',
				headline: alertHeader,
				message: ""
			});
			controller.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicestartsoftwareudpate',
				deviceId:0,
				jsonStrBase64:updateStr
			});
		}
		else
		{
			controller.raiseAlert({
				type:'danger',
				headline: AppStrings.app.Error,
				message: "Unable to apply update"
			});
		}
	}
}
