import React, { Component } from 'react';
import { AlertList } from "react-bs-notifier";


class Alerts extends Component
{

	constructor(props)
	{
		super(props);
		this.onDismiss = this.onDismiss.bind(this);
	}

	onDismiss(event)
	{
		this.props.controller.dismissAlert(event.id);
	}

	render()
	{
		return (
			<div id="alerts-container">
				<AlertList
					position='top-center'
					alerts={this.props.clearcaster.alerts}
					timeout={ 3000 }
					dismissTitle="Begone!"
					onDismiss={ this.onDismiss }
				/>
			</div>
		);
	}
}

export default Alerts;
