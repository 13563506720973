import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class DialogSimulcastAddTarget extends Component
{

	constructor(props)
	{
		super(props);
		this.close = this.close.bind(this);
    this.removeTargets = this.removeTargets.bind(this);
    this.removeMessage = this.removeMessage.bind(this);
    this.getTargetName = this.getTargetName.bind(this);
    this.removeTargetText = this.removeTargetText.bind(this);
	}

	close()
	{
		this.props.controller.hideDialogSimulcastRemoveTarget();
	}

	removeTargets()
	{
    let ids = this.props.clearcaster.dialogs.showSimulcastRemoveTarget.ids;
    ids.forEach((id) => {
      this.props.controller.removeSimulcastStreamTarget(id);
    });
    this.close();
	}
  getTargetName(id)
	{
		let target_name="";
    if (this.props.clearcaster.simulcast.streamTargets[id] && this.props.clearcaster.simulcast.streamTargets[id].data && this.props.clearcaster.simulcast.streamTargets[id].data.name)
      target_name=this.props.clearcaster.simulcast.streamTargets[id].data.name;
    return target_name;
	}
	removeMessage()
	{
		let ids = this.props.clearcaster.dialogs.showSimulcastRemoveTarget.ids;
		if (ids.length > 1) {
			let targets=[];
      ids.forEach((id) => {
      	let targetName=this.getTargetName(id);
				targets.push(<li key={id.toString()}><b>{targetName}</b></li>)
			});
     	return (
				<div>
					<p>{this.props.strings.app.SimulcastingRemoveTargetsPrompt}</p>
					<div class="well well-sm pre-scrollable">
						<ul>{targets}</ul>
					</div>
					<br/>
					<p><b>{this.props.strings.app.Note}</b> {this.props.strings.app.SimulcastingRemoveTargetNote}</p>
				</div>
			);
		} else {
			let id;
			if (ids && ids[0])
				id=ids[0];
      let targetName=this.getTargetName(id);
			return (
				<div>
					<p>{this.props.strings.app.SimulcastingRemoveTargetPrompt} <b>{targetName}</b>?</p>
					<p><b>{this.props.strings.app.Note}</b> {this.props.strings.app.SimulcastingRemoveTargetNote}</p>
				</div>
			);
		}
	}
	removeTargetText()
	{
		let text;
		if (this.props.clearcaster.dialogs.showSimulcastRemoveTarget.ids.length > 1) {
			text=this.props.strings.app.SimulcastingRemoveTargets;
		} else {
      text=this.props.strings.app.SimulcastingRemoveTarget;
		}
		return text;
	}

	render()
	{
		if (this.props.clearcaster.dialogs.showSimulcastRemoveTarget.show === true && this.props.clearcaster.dialogs.showSimulcastRemoveTarget.ids) {
      return (
				<Modal show="true" onHide={this.close}>
					<Modal.Header closeButton>
						<Modal.Title>{this.removeTargetText()}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.removeMessage()}
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle="default" onClick={this.close}>{this.props.strings.app.Cancel}</Button>
						<Button bsStyle="primary" onClick={this.removeTargets}>{this.removeTargetText()}</Button>
					</Modal.Footer>
				</Modal>
      );
    } else {
			return null;
		}
	}
}

export default DialogSimulcastAddTarget;
