export const SimulcastActionTypes =
{
	SET_SIMULCAST_STREAM_TARGET: 'SET_SIMULCAST_STREAM_TARGET',
	SET_SIMULCAST_STREAM_TARGETS: 'SET_SIMULCAST_STREAM_TARGETS',
	SET_SIMULCAST_STREAM_TARGET_STATE: 'SET_SIMULCAST_STREAM_TARGET_STATE',
	REMOVE_SIMULCAST_STREAM_TARGET: 'REMOVE_SIMULCAST_STREAM_TARGET',
}

export default function ClearCasterSimulcastReducer (
		state = {
			recievedStreamTargetData:false,
			streamTargets:{}
		}, action )
{
		switch (action.type)
	{
		case SimulcastActionTypes.SET_SIMULCAST_STREAM_TARGET:
			let oldStreamTarget;
			if (state.streamTargets[action.streamTarget['id']])
				oldStreamTarget=state.streamTargets[action.streamTarget['id']];
			state.streamTargets[action.streamTarget['id']] = action.streamTarget;
			if (oldStreamTarget && oldStreamTarget.data && oldStreamTarget.data.state
				&& state.streamTargets[action.streamTarget['id']]
				&& state.streamTargets[action.streamTarget['id']].data)
				state.streamTargets[action.streamTarget['id']].data.state = oldStreamTarget.data.state;

			return state;
		case SimulcastActionTypes.SET_SIMULCAST_STREAM_TARGETS:
			state.recievedStreamTargetData=true;
			let oldStreamTargets=state.streamTargets;
			state.streamTargets={};
			if (action.objects && action.objects.length) {
				for (let i = 0; i < action.objects.length; i++) {
					let object = action.objects[i];
					if (object.streamTarget && object.streamTarget.id) {
						state.streamTargets[object.streamTarget.id] = object.streamTarget;
						if (oldStreamTargets[object.streamTarget.id]
						&& oldStreamTargets[object.streamTarget.id].data
						&& oldStreamTargets[object.streamTarget.id].data.state
						&& state.streamTargets[object.streamTarget.id].data)
							state.streamTargets[object.streamTarget.id].data.state = oldStreamTargets[object.streamTarget.id].data.state;
					}
				}
			}
			return state;
		case SimulcastActionTypes.SET_SIMULCAST_STREAM_TARGET_STATE:
			  if (state.streamTargets[action.streamTargetId]) {
					state.streamTargets[action.streamTargetId].data.state=action.state;
				}
				return state;
		case SimulcastActionTypes.REMOVE_SIMULCAST_STREAM_TARGET:
			if (action.status_code === 200 && state.streamTargets[action.streamTarget['id']]) {
				delete state.streamTargets[action.streamTarget['id']]
			}
			return state;
		default:
			return state;
	}
}
