export const DialogsActionTypes =
{
	SHOW_DIALOG_ACTIVATE: 'SHOW_DIALOG_ACTIVATE',
	HIDE_DIALOG_ACTIVATE: 'HIDE_DIALOG_ACTIVATE',
	SHOW_DIALOG_CONFIRM: 'SHOW_DIALOG_CONFIRM',
	HIDE_DIALOG_CONFIRM: 'HIDE_DIALOG_CONFIRM',
	DIALOG_CONFIRM_RESOLVE: 'DIALOG_CONFIRM_RESOLVE',
	DIALOG_CONFIRM_REJECT: 'DIALOG_CONFIRM_REJECT',
	SHOW_DIALOG_EMBED_CODE: 'SHOW_DIALOG_EMBED_CODE',
	HIDE_DIALOG_EMBED_CODE: 'HIDE_DIALOG_EMBED_CODE',
	SHOW_DIALOG_FACEBOOK: 'SHOW_DIALOG_FACEBOOK',
	HIDE_DIALOG_FACEBOOK: 'HIDE_DIALOG_FACEBOOK',
	SHOW_DIALOG_FACEBOOK_UNPAIR: 'SHOW_DIALOG_FACEBOOK_UNPAIR',
	HIDE_DIALOG_FACEBOOK_UNPAIR: 'HIDE_DIALOG_FACEBOOK_UNPAIR',
	SHOW_DIALOG_INFO: 'SHOW_DIALOG_INFO',
	HIDE_DIALOG_INFO: 'HIDE_DIALOG_INFO',
	SHOW_DIALOG_INTELLIGENTMONITORING: 'SHOW_DIALOG_INTELLIGENTMONITORING',
	HIDE_DIALOG_INTELLIGENTMONITORING: 'HIDE_DIALOG_INTELLIGENTMONITORING',
	SHOW_DIALOG_REBOOT: 'SHOW_DIALOG_REBOOT',
	HIDE_DIALOG_REBOOT: 'HIDE_DIALOG_REBOOT',
	SHOW_DIALOG_REDUNDANTPREVIEW: 'SHOW_DIALOG_REDUNDANTPREVIEW',
	HIDE_DIALOG_REDUNDANTPREVIEW: 'HIDE_DIALOG_REDUNDANTPREVIEW',
	SHOW_DIALOG_ROLLBACK: 'SHOW_DIALOG_ROLLBACK',
	HIDE_DIALOG_ROLLBACK: 'HIDE_DIALOG_ROLLBACK',
	SHOW_DIALOG_SETTINGS: 'SHOW_DIALOG_SETTINGS',
	HIDE_DIALOG_SETTINGS: 'HIDE_DIALOG_SETTINGS',
	SHOW_DIALOG_DEVICEINPUT: 'SHOW_DIALOG_DEVICEINPUT',
	HIDE_DIALOG_DEVICEINPUT: 'HIDE_DIALOG_DEVICEINPUT',
	SHOW_DIALOG_UPDATES: 'SHOW_DIALOG_UPDATES',
	HIDE_DIALOG_UPDATES: 'HIDE_DIALOG_UPDATES',
	SHOW_DIALOG_CHANGE_UUID: 'SHOW_DIALOG_CHANGE_UUID',
	HIDE_DIALOG_CHANGE_UUID: 'HIDE_DIALOG_CHANGE_UUID',
	SHOW_DIALOG_SIMULCAST_ADD_TARGET: 'SHOW_DIALOG_SIMULCAST_ADD_TARGET',
	HIDE_DIALOG_SIMULCAST_ADD_TARGET: 'HIDE_DIALOG_SIMULCAST_ADD_TARGET',
	SHOW_DIALOG_SIMULCAST_REMOVE_TARGET: 'SHOW_DIALOG_SIMULCAST_REMOVE_TARGET',
	HIDE_DIALOG_SIMULCAST_REMOVE_TARGET: 'HIDE_DIALOG_SIMULCAST_REMOVE_TARGET'
}

export default function ClearCasterDialogsReducer (
    state = {
		showActivate:false,
		showConfirm:false,
		confirmTitle:'',
		confirmMessge:'',
		confirmConfirmText:'',
		confirmCancelText:'',
		confirmShowWarning:false,
		confirmResolve:false,
		confirmReject:false,
		showEmbedCode:false,
		showError:false,
		showFacebook:false,
		showFacebookUnpair:false,
		showInfo:false,
		showIntelligentMonitoring:false,
		showReboot:false,
		showRedundantPreview:false,
		redundantPreviewData:{},
		showRollback:false,
		showSettings:false,
		showDeviceInput:false,
		showUpdates:false,
		showChangeUUID:false,
		showSimulcastAddTarget:{show:false,id:undefined},
		showSimulcastRemoveTarget:{show:false,ids:undefined}
    },
    action )
{
    switch (action.type)
	{
		case DialogsActionTypes.SHOW_DIALOG_ACTIVATE:
			state.showActivate = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_ACTIVATE:
			state.showActivate = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_CONFIRM:
			state.showConfirm = true;
			state.confirmTitle = action.confirmProperties.title;
			state.confirmMessage = action.confirmProperties.message;
			state.confirmConfirmText = action.confirmProperties.confirmText;
			state.confirmCancelText = action.confirmProperties.cancelText;
			state.confirmShowWarning = action.confirmProperties.showWarning;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_CONFIRM:
			state.showConfirm = false;
			state.confirmTitle = '';
			state.confirmMessage = '';
			state.confirmButtonText = '';
			return state;
		case DialogsActionTypes.DIALOG_CONFIRM_RESOLVE:
			state.confirmResolve = action.confirmResolve;
			return state;
		case DialogsActionTypes.DIALOG_CONFIRM_REJECT:
			state.confirmReject = action.confirmReject;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_EMBED_CODE:
			state.showEmbedCode = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_EMBED_CODE:
			state.showEmbedCode = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_FACEBOOK:
			state.showFacebook = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_FACEBOOK:
			state.showFacebook = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_FACEBOOK_UNPAIR:
			state.showFacebookUnpair = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_FACEBOOK_UNPAIR:
			state.showFacebookUnpair = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_INFO:
			state.showInfo = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_INFO:
			state.showInfo = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_INTELLIGENTMONITORING:
			state.showIntelligentMonitoring = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_INTELLIGENTMONITORING:
			state.showIntelligentMonitoring = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_REBOOT:
			state.showReboot = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_REBOOT:
			state.showReboot = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_REDUNDANTPREVIEW:
			state.showRedundantPreview = true;
			state.redundantPreviewData = action.redundantPreviewData;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_REDUNDANTPREVIEW:
			state.showRedundantPreview = false;
			state.redundantPreviewData = {};
			return state;
		case DialogsActionTypes.SHOW_DIALOG_ROLLBACK:
			state.showRollback = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_ROLLBACK:
			state.showRollback = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_SETTINGS:
			state.showSettings = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_SETTINGS:
			state.showSettings = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_DEVICEINPUT:
			state.showDeviceInput = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_DEVICEINPUT:
			state.showDeviceInput = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_UPDATES:
			state.showUpdates = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_UPDATES:
			state.showUpdates = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_CHANGE_UUID:
			state.showChangeUUID = true;
			return state;
		case DialogsActionTypes.HIDE_DIALOG_CHANGE_UUID:
			state.showChangeUUID = false;
			return state;
		case DialogsActionTypes.SHOW_DIALOG_SIMULCAST_ADD_TARGET:
			state.showSimulcastAddTarget = {show:true,id:action.id};
			return state;
		case DialogsActionTypes.HIDE_DIALOG_SIMULCAST_ADD_TARGET:
			state.showSimulcastAddTarget = {show:false,id:undefined};
			return state;
    case DialogsActionTypes.SHOW_DIALOG_SIMULCAST_REMOVE_TARGET:
      state.showSimulcastRemoveTarget = {show:true,ids:action.ids};
      return state;
    case DialogsActionTypes.HIDE_DIALOG_SIMULCAST_REMOVE_TARGET:
      state.showSimulcastRemoveTarget = {show:false,ids:undefined};
      return state;
		default:
			return state;
    }
}
