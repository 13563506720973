import React, { Component } from 'react';
import { Button, FormGroup, ControlLabel, FormControl, Modal, Checkbox } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';

import ClearCasterStore from '../../model/ClearCasterStore';
import EncodingSettingsAspectRatio from '../../model/EncodingSettingsAspectRatio';
import FacebookDestinationType from '../../model/FacebookDestinationType';
import FacebookConnectionState from '../../model/FacebookConnectionState';
import FacebookPrivacyLevel from '../../model/FacebookPrivacyLevel';
import Loading from '../components/Loading';

class DialogCreateBroadcast extends Component
{
	static get NONE() {
		return "NONE";
	}

	static getDefaultState()
	{
		//console.log("getDefaultState");
		let appState = ClearCasterStore.getState();
		let defaultDestinationType = FacebookDestinationType.TIMELINE;
		let defaultDestination = "me";
		let defaultPrivacy = "ALL_FRIENDS";
		let lastDestinationSelected = {
			'TIMELINE':{destination:'me',privacy:FacebookPrivacyLevel.ALL_FRIENDS},
			'PAGE':{destination:'',privacy:FacebookPrivacyLevel.EVERYONE},
			'GROUP':{destination:'',privacy:FacebookPrivacyLevel.ALL_FRIENDS},
			'EVENT':{destination:'',privacy:FacebookPrivacyLevel.ALL_FRIENDS},
			'TEST':{destination:'me',privacy:FacebookPrivacyLevel.SELF}
		};
		if (appState['userFacebook'] && appState['userFacebook']['pages'] && appState['userFacebook']['pages'].length > 0)
		{
			defaultDestinationType = FacebookDestinationType.PAGE;
			defaultDestination = appState['userFacebook']['pages'][0].id;
			defaultPrivacy = FacebookPrivacyLevel.EVERYONE;
			lastDestinationSelected['PAGE']['destination'] = appState['userFacebook']['pages'][0].id;			
		}
		if (appState['userFacebook'] && appState['userFacebook']['groups'] && appState['userFacebook']['groups'].length > 0)
		{
			lastDestinationSelected['GROUP']['destination'] = appState['userFacebook']['groups'][0].id;
		}
		if (appState['userFacebook'] && appState['userFacebook']['events'] && appState['userFacebook']['events'].length > 0)
		{
			lastDestinationSelected['EVENT']['destination'] = appState['userFacebook']['events'][0].id;
		}

		return {
			creating:false,
			broadcastId: undefined,
			encoder1: DialogCreateBroadcast.NONE,
			encoder2: DialogCreateBroadcast.NONE,
			encoder3: DialogCreateBroadcast.NONE,
			encoder4: DialogCreateBroadcast.NONE,
			simulcastingTarget: 0,
			privacy: defaultPrivacy,
			destinationType: defaultDestinationType,
			lastDestinationSelected:lastDestinationSelected,
			destination: defaultDestination,
			description: "",
			title: "",
			titleValidation: null,
			isRedundancyEnabled: true,
			isSpherical: false,
			isFishEye: false,
			fov: undefined,
			isSpatialAudio: false,
			embeddable: true,
			unpublishAtEnd: undefined,
			disturbing: undefined,
			outputAspectRatio: "source",
			broadcast60FPS: false,
			status: undefined
		};
   }

	constructor(props, context) {
		super(props, context);

		this.state = DialogCreateBroadcast.getDefaultState();

		this.onClose = this.onClose.bind(this);
		this.onShow = this.onShow.bind(this);
		this.ok = this.ok.bind(this);
		this.onDestinationType = this.onDestinationType.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
	}

	onShow()
	{
		if (this.props.clearcaster.broadcasts.createBroadcastInfo !== undefined)
		{
			// console.log("onShow: "+JSON.stringify(this.props.clearcaster.broadcasts.createBroadcastInfo));
			this.setState(this.props.clearcaster.broadcasts.createBroadcastInfo);
		}
		else
			this.setState(DialogCreateBroadcast.getDefaultState());
	}

	onClose(event)
	{
		this.props.controller.hideDialogCreateBroadcast();
		this.setState(DialogCreateBroadcast.getDefaultState());
	}

	ok(event)
	{
		// console.log("ok");
	}

	onDestinationType(event)
	{
		// console.log('onDestinationType:' + event.target.value);
		let nextDestination = 'me';
		let nextPrivacy = FacebookPrivacyLevel.ALL_FRIENDS;
		let lastDestinationSelected = this.state.lastDestinationSelected;

		for (var type in FacebookDestinationType)
		{
			if (event.target.value === FacebookDestinationType[type])
			{
				nextDestination = this.state.lastDestinationSelected[FacebookDestinationType[type]]['destination'];
				nextPrivacy = this.state.lastDestinationSelected[FacebookDestinationType[type]]['privacy'];
			}
			if (this.state.destinationType === FacebookDestinationType[type])
			{
				lastDestinationSelected[FacebookDestinationType[type]] = {
					destination: this.state.destination,
					privacy: this.state.privacy
				}
			}
		}

		// console.log('nextDestination:' + nextDestination);
		// console.log('nextPrivacy:' + nextPrivacy);
		// console.log('lastDestinationSelected:');
		// console.log(lastDestinationSelected);

		// console.log({ 
		// 	destination: nextDestination,
		// 	destinationType: event.target.value,
		// 	lastDestinationSelected: lastDestinationSelected,
		// 	privacy:nextPrivacy
		// });

		this.setState({ 
			destination: nextDestination,
			destinationType: event.target.value,
			lastDestinationSelected: lastDestinationSelected,
			privacy:nextPrivacy
		});
	}

	handleCreate(event)
	{
		let _this = this;
		let createTitle = this.state.title;

		let encoder1 = this.state.encoder1;

		// TODO, if encoder1 is set:
		// 1. Create the broadcast without the encoder
		// 2. Call Will's API to store info against the broadcast
		// 3. Add the encoder to the broadcast
		// 4. Redirect to the broadcast page
		//history.push('/broadcast/' + redirectBroadcastId);

		if (this.state.title ===undefined || this.state.title.length <= 0)
		{
			if (this.state.destination === "me" && this.props.clearcaster.userFacebook && this.props.clearcaster.userFacebook.name)
			{
				createTitle=this.props.clearcaster.userFacebook.name
			}
			else if (this.state.destinationType === "PAGE" && this.state.destination && this.props.clearcaster.userFacebook.pages)
			{
				let page=this.props.clearcaster.userFacebook.pages.find(element => {return element.id === this.state.destination});
				if (page && page.name)
					createTitle=page.name;
			}
			else if (this.state.destinationType === "GROUP" && this.state.destination && this.props.clearcaster.userFacebook.groups)
			{
				let group=this.props.clearcaster.userFacebook.groups.find(element => {return element.id === this.state.destination});
				if (group && group.name)
					createTitle=group.name;
			}

			createTitle = createTitle + " Live";
		}

		var createBroadcastInfo = {
			createdByClearCaster: true,
			liveEncoders: [],
			privacy: this.state.privacy,
			destination: this.state.destination,
			destinationType: this.state.destinationType,
			description: this.state.description,
			title: createTitle,
			simulcastingTarget: this.state.simulcastingTarget,
			enableDonateLive: false,
			donateLiveCharityId: undefined
		};
	   
		if (this.props.clearcaster.userFacebook.pageIdMap[this.state.destination] !== undefined)
			createBroadcastInfo.access_token = this.props.clearcaster.userFacebook.pageIdMap[this.state.destination].access_token;

		createBroadcastInfo.isSpherical = this.state.isSpherical;
		createBroadcastInfo.isFishEye = this.state.isFishEye;
		createBroadcastInfo.fov = this.state.fov;
		createBroadcastInfo.embeddable = this.state.embeddable;
		createBroadcastInfo.unpublishAtEnd = this.state.unpublishAtEnd;
		createBroadcastInfo.disturbing = this.state.disturbing;

		if (process.env.REACT_APP_ENABLE_PERBROADCAST_SETTINGS === "true")
		{
			createBroadcastInfo.outputAspectRatio = this.state.outputAspectRatio;
			createBroadcastInfo.broadcast60FPS = this.state.broadcast60FPS;

			createBroadcastInfo.encodingSettings = {};


			switch(createBroadcastInfo.outputAspectRatio)
			{
			default:
			case "source":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.SOURCE.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.SOURCE.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.SOURCE.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.SOURCE.aspectRatioRotation;
				break;
			case "square":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.SQUARE.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.SQUARE.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.SQUARE.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.SQUARE.aspectRatioRotation;
				break;
			case "standard":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.STANDARD.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.STANDARD.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.STANDARD.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.STANDARD.aspectRatioRotation;
				break;
			case "vertical_crop":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.VERTICAL_CROP.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.VERTICAL_CROP.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.VERTICAL_CROP.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.VERTICAL_CROP.aspectRatioRotation;
				break;
			case "vertical_rotate_right":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT.aspectRatioRotation;
				break;
			case "vertical_rotate_left":
				createBroadcastInfo.encodingSettings.aspectRatioMode = EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT.aspectRatioMode;
				createBroadcastInfo.encodingSettings.aspectRatioWidth = EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT.aspectRatioWidth;
				createBroadcastInfo.encodingSettings.aspectRatioHeight = EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT.aspectRatioHeight;
				createBroadcastInfo.encodingSettings.aspectRatioRotation = EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT.aspectRatioRotation;
				break;
			}

			createBroadcastInfo.maxFrameRate = {
				frameRateEnum: 30,
				frameRateDen: 1
			};

			if (createBroadcastInfo.broadcast60FPS)
			{
				createBroadcastInfo.maxFrameRate.frameRateEnum = 0;
				createBroadcastInfo.maxFrameRate.frameRateDen = 0;
			}
		}

		let finishCreate = function (broadcastId)
		{
			_this.props.controller.hideDialogCreateBroadcast();
			_this.setState(DialogCreateBroadcast.getDefaultState());

			if (broadcastId)
			{
				_this.props.history.push('/broadcast/'+broadcastId);
			}
		}

		this.setState({ creating:true });

		this.props.controller.createBroadcast(undefined, createBroadcastInfo)
		.then((response) => {

			if (!response.error && response['id'])
			{
				// TODO: Save Properties via Will's API
				if (encoder1 !== DialogCreateBroadcast.NONE)
				{
					createBroadcastInfo.liveEncoders.push(encoder1);
					this.props.controller.createBroadcast(response['id'], createBroadcastInfo)
				}

			}
			finishCreate(response['id']);
		})
	}

	handleAlertDismiss(event)
	{
		this.setState({ alert:null });
	}

	render()
	{
		let isOnPage = this.state.destinationType === FacebookDestinationType.PAGE;
		let isTest = this.state.destinationType === FacebookDestinationType.TEST;
		let showPrivacy = !(this.state.destinationType === FacebookDestinationType.GROUP || this.state.destinationType === FacebookDestinationType.EVENT);

		let userFacebook = this.props.clearcaster.userFacebook;
		if (userFacebook.facebookConnected !== FacebookConnectionState.CONNECTED)
			return null;
		let destinations = [{value:FacebookDestinationType.TIMELINE,label:this.props.strings.app.GoLiveShareTimeline}];
		if (userFacebook.pages.length > 0)
			destinations.unshift ({value:FacebookDestinationType.PAGE,label:this.props.strings.app.GoLiveSharePage});
		if (userFacebook.groups.length > 0)
			destinations.push ({value:FacebookDestinationType.GROUP,label:this.props.strings.app.GoLiveShareGroup});
		if (userFacebook.events.length > 0)
			destinations.push ({value:FacebookDestinationType.EVENT,label:this.props.strings.app.GoLiveShareEvent});
		destinations.push ({value:FacebookDestinationType.TEST,label:this.props.strings.app.GoLiveTestBroadcast});

		return (
			<Modal show={this.props.clearcaster.broadcasts.showCreateBroadcast} onShow={ this.onShow } onHide={ this.onClose }>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.strings.app.CreateBroadcast}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
						<form>

						<FormGroup controlId="dialog-createbroadcast-destination-type">
							<ControlLabel>{this.props.strings.app.CreateBroadcastDestination}</ControlLabel>
							<FormControl componentClass="select" value={ this.state.destinationType } onChange={ this.onDestinationType }>
								{ destinations.map((dest,index) => (
									<option key={index} value={dest.value}>{dest.label}</option>
								))}
							</FormControl>
						</FormGroup>

						{ this.state.destinationType === FacebookDestinationType.PAGE && 
							<FormGroup controlId="dialog-createbroadcast-destination-page">
								<ControlLabel>{this.props.strings.app.CreateBroadcastPage}</ControlLabel>
								<FormControl componentClass="select" value={ this.state.destination } onChange={evt => this.setState({ destination: evt.target.value })}>
									{ this.props.clearcaster.userFacebook.pages.map ((v,key) => { return (
										<option key={ v.id } value={ v.id }>{ v.name }</option>
									)})}
								</FormControl>
							</FormGroup>
						}

						{ this.state.destinationType === FacebookDestinationType.GROUP && 
							<FormGroup controlId="dialog-createbroadcast-destination-group">
								<ControlLabel>{this.props.strings.app.CreateBroadcastGroup}</ControlLabel>
								<FormControl componentClass="select" value={ this.state.destination } onChange={evt => this.setState({ destination: evt.target.value })}>
									{ this.props.clearcaster.userFacebook.groups.map ((v,key) => { return (
										<option key={ v.id } value={ v.id }>{ v.name }</option>
									)})}
								</FormControl>
							</FormGroup>
						}

						{ this.state.destinationType === FacebookDestinationType.EVENT && 
							<FormGroup controlId="dialog-createbroadcast-destination-event">
								<ControlLabel>{this.props.strings.app.CreateBroadcastEvent}</ControlLabel>
								<FormControl componentClass="select" value={ this.state.destination } onChange={evt => this.setState({ destination: evt.target.value })}>
									{ this.props.clearcaster.userFacebook.events.map ((v,key) => { return (
										<option key={ v.id } value={ v.id }>{ v.name }</option>
									)})}
								</FormControl>
							</FormGroup>
						}
						{ showPrivacy && 
							<FormGroup controlId="dialog-createbroadcast-privacy">
								<ControlLabel>
									{ this.props.strings.app.GoLivePrivacyPrompt }&nbsp;
									{ this.state.destinationType === FacebookDestinationType.TIMELINE && 
										<span>
											<i className="fa fa-question-circle" style={{color:'#428bca'}} title={this.props.strings.app.PrivacyCeilingHover}></i>&nbsp;
										</span>
									}
								</ControlLabel>
								<FormControl componentClass="select" disabled={ isOnPage || isTest } value={ this.state.privacy } onChange={evt => this.setState({ privacy: evt.target.value })}>
									<option value={FacebookPrivacyLevel.EVERYONE}>{this.props.strings.app.FacebookPrivacyLevel_EVERYONE}</option>
									<option value={FacebookPrivacyLevel.FRIENDS_OF_FRIENDS}>{this.props.strings.app.FacebookPrivacyLevel_FRIENDS_OF_FRIENDS}</option>
									<option value={FacebookPrivacyLevel.ALL_FRIENDS}>{this.props.strings.app.FacebookPrivacyLevel_ALL_FRIENDS}</option>
									<option value={FacebookPrivacyLevel.SELF}>{this.props.strings.app.FacebookPrivacyLevel_SELF}</option>
								</FormControl>
								{ this.state.destinationType === FacebookDestinationType.TIMELINE && 
									<span>
										<a style={{fontWeight:'normal'}} href="https://www.wowza.com/docs/how-to-change-the-facebook-privacy-settings-for-wowza-clearcaster" target="_blank" rel="noopener noreferrer">{this.props.strings.app.PrivacyCeilingHowToChange} <i className="fa fa-external-link"></i></a>&nbsp;
									</span>
								}
							</FormGroup>
						}

						{ process.env.REACT_APP_ENABLE_PERBROADCAST_SETTINGS === "true" &&
							<FormGroup controlId="outputAspcectRatio">
								<ControlLabel>{ this.props.strings.app.GoLiveOutputAspectRatio }</ControlLabel>
								<FormControl componentClass="select" value={ this.state.outputAspectRatio } onChange={evt => this.setState({ outputAspectRatio: evt.target.value })}>
									<option value="source">{ this.props.strings.app.GoLiveOutputAspectRatioSource }</option>
									<option value="square">{ this.props.strings.app.GoLiveOutputAspectRatioSquare }</option>
									<option value="standard">{ this.props.strings.app.GoLiveOutputAspectRatioStandard }</option>
									<option value="vertical_crop">{ this.props.strings.app.GoLiveOutputAspectRatioVerticalCrop }</option>
									<option value="vertical_rotate_right">{ this.props.strings.app.GoLiveOutputAspectRatioVerticalRotateRight }</option>
									<option value="vertical_rotate_left">{ this.props.strings.app.GoLiveOutputAspectRatioVerticalRotateLeft }</option>
								</FormControl>
							</FormGroup>
						}

						<FormGroup controlId="dialog-createbroadcast-settings">
							<ControlLabel>{ this.props.strings.app.Settings }</ControlLabel>

							{ process.env.REACT_APP_ENABLE_PERBROADCAST_SETTINGS === "true" &&
								<Checkbox id="setting-broadcast60fps" value={ this.state.broadcast60FPS } onChange={evt => this.setState({ broadcast60FPS: evt.target.checked })}>
								{ this.props.strings.app.MotionQualityPrompt }
								</Checkbox>
							}

							<Checkbox id="dialog-createbroadcast-settings-isspherical" value={ this.state.isSpherical } onChange={evt => this.setState({ isSpherical: (evt.target.checked?true:false), isFishEye: false, isSpatialAudio: false })}>
								{this.props.strings.app.GoLive360Prompt} <a href="https://www.facebook.com/help/828417127257368" target="_blank" rel="noopener noreferrer">{this.props.strings.app.LearnMore} <i className="fa fa-external-link"/></a>
							</Checkbox>
							{ this.state.isSpherical && (
								<span>
									<Checkbox id="dialog-createbroadcast-settings-isfisheye" value={ this.state.isFishEye } onChange={evt => this.setState({ isFishEye: (evt.target.checked?true:false) })}>
										{this.props.strings.app.GoLive360FishEyePrompt}
									</Checkbox>
									{ this.state.isFishEye && (
										<span>
											<input type="number" min="0" max="360" id="dialog-createbroadcast-settings-fov" value={ this.state.fov || 180 } onChange={evt => this.setState({ fov: (evt.target.value)})}/>
											&nbsp;Field of View (FOV)
										</span>
									)}
									{/* TODO: Spatial audio when supported}
									<Checkbox id="dialog-createbroadcast-settings-isspatialaudio" value={ this.state.isSpatialAudio } onChange={evt => this.setState({ isSpatialAudio: (evt.target.checked?true:false) })}>
										{this.props.strings.app.GoLive360SpatialAudioPrompt}
									</Checkbox> 
									*/}
								</span>
							)}							
					   	</FormGroup>

						
						</form>
				</Modal.Body>
				<Modal.Footer>
					{ this.state.creating && 
						<Loading />
					}
					{ !this.state.creating && (
						<span>
							<Button id="dialog-createbroadcast-cancel" onClick={this.onClose}>{this.props.strings.app.Cancel}</Button>
							&nbsp;<Button id="dialog-createbroadcast-create" bsStyle="primary" onClick={this.handleCreate}>{this.props.strings.app.Create}</Button>
						</span>
					)}
				</Modal.Footer>
			</Modal>
		);
	}
}

export default withRouter(DialogCreateBroadcast);
