import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Flags from './view/Flags';
import WZHeader from './view/shell/WZHeader';
import WZFooter from './view/shell/WZFooter';
import Hotjar from './view/shell/Hotjar';
import AppStrings from './model/AppStrings';
import LogLevels from './log/LogLevels';

import Loading from './view/components/Loading';
import Alerts from './view/components/Alerts';
import Notifications from './view/components/Notifications';
import UserSessionWarning from './view/components/UserSessionWarning';
import FacebookScopeWarning from './view/components/FacebookScopeWarning';

import Dialogs from './view/dialogs/Dialogs';

import { withCookies } from 'react-cookie';

import ClearCasterStore, { ActionTypes } from './model/ClearCasterStore';
import ClearCasterAdminController from './controller/ClearCasterAdminController';
import { redirectToLogin } from './controller/ClearCasterAdminUtils';

import { getParameterByName } from './view/UIUtils';

import './App.css';

const UnpairingToolLoadable = Loadable({
	loader: () => import('./view/unpairing_tool/UnpairingTool'),
	loading: Loading
});
const DevicesLoadable = Loadable({
	loader: () => import('./view/devices/Devices'),
	loading: Loading
});
const BroadcastLoadable = Loadable({
	loader: () => import('./view/broadcast/Broadcast'),
	loading: Loading
});
const ViewBroadcastsLoadable = Loadable({
	loader: () => import('./view/broadcasts/ViewBroadcasts'),
	loading: Loading
});
const SupportLoadable = Loadable({
	loader: () => import('./view/support/Support'),
	loading: Loading
});
const SupportToolsLoadable = Loadable({
	loader: () => import('./view/SupportTools'),
	loading: Loading
});
const SimulcastLoadable = Loadable({
  loader: () => import('./view/simulcast/Simulcast'),
  loading: Loading
});

class App extends Component
{
	constructor(props)
	{
		super(props);

		this.isWowzaAuth = this.isWowzaAuth.bind(this);

		let state = ClearCasterStore.getState();

		if (typeof this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE) !== 'undefined' && typeof this.props.cookies.get(window.config.REACT_APP_USER_COOKIE) !== 'undefined')
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_USER_JWT,
				jwt: this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE),
				username: this.props.cookies.get(window.config.REACT_APP_USER_COOKIE)
			});
		}

		let defaultRoute = "clearcasters";
		if (typeof props.cookies.get(window.config.REACT_APP_APP_COOKIE) !== 'undefined')
		{
			defaultRoute = props.cookies.get(window.config.REACT_APP_APP_COOKIE);
		}

		let activateForm = state.activateForm;
		activateForm.activationCode = getParameterByName('code') || '';
		activateForm.activateName = getParameterByName('name') || '';
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_ACTIVATE_FORM,
			activateForm: activateForm
		});

		let logLevel = getParameterByName('loglevel') || '';
		logLevel = logLevel.toUpperCase();
		if (!LogLevels.hasOwnProperty(logLevel))
		{
			logLevel = process.env.REACT_APP_LOG_LEVEL || LogLevels.ERROR;
		}
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_LOG_LEVEL,
			logLevel:logLevel
		});

		this.state = {
			clearcaster: ClearCasterStore.getState(),
			controller: new ClearCasterAdminController(),
			strings: AppStrings,
			defaultRoute: defaultRoute
		};

		console.log("Wowza ClearCaster Remote Manager Tool");
		console.log("Version: " + process.env.REACT_APP_VERSION);
		console.log("Revision: " + process.env.REACT_APP_GIT_REVISION);
		console.log("Build Number: " + process.env.REACT_APP_BUILD_NUMBER);
		console.log("Log Level: "+logLevel);
	}
	 
	isWowzaAuth()
	{
		// See if none of the wowza cookies are present. If so, redirect.
		if (typeof this.props.cookies.get(window.config.REACT_APP_AUTH_COOKIE) === 'undefined' || typeof this.props.cookies.get(window.config.REACT_APP_USER_COOKIE) === 'undefined')
		{
			redirectToLogin();
			return false;
		}
		return true;
	}

	componentDidMount()
	{
		ClearCasterStore.subscribe(this.onClearCasterStateChanged.bind(this));
	}

	onClearCasterStateChanged()
	{
		let clearCasterState = ClearCasterStore.getState();
		if (clearCasterState.userWowza.required && clearCasterState.userWowza.redirectToLogin)
		{
			redirectToLogin();
		}
		else if (!clearCasterState.userWowza.required && clearCasterState.userWowza.redirectToLogin)
		{
			this.state.controller.mqttConnection.closeConnection(true);
		}
		else
		{
			this.setState({
				clearcaster: clearCasterState,
				controller: this.state.controller,
				strings: this.state.strings
			});
		}
	}

	render()
	{
		return (
			<BrowserRouter>
				<div className="App container-fluid">
					<Hotjar></Hotjar>
					<WZHeader strings={AppStrings} clearcaster={this.state.clearcaster} controller={this.state.controller} />

					<Alerts clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings}></Alerts>

					<Dialogs clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings}></Dialogs>

					<div className="row">
						<div className="col-sm-12" style={{margin:'0 auto',maxWidth:'1140px'}}>
							<UserSessionWarning strings={this.state.strings} />
							<Notifications />

							<div className="alert alert-danger">
								As of Friday, July 23, 2021 Facebook Manager is deprecated on all versions of ClearCaster.<br />
								Upgrade to <a href="https://www.wowza.com/docs/wowza-clearcaster-encoder-software-release-notes#latest-updates" target="_blank" rel="noopener noreferrer">Wowza ClearCaster encoder release 2.3.9.00</a> to use <a href="https://www.wowza.com/docs/manage-facebook-paired-broadcasts" target="_blank" rel="noopener noreferrer">Facebook Paired Templates</a> to stream directly to Facebook live.
							</div>

							<FacebookScopeWarning controller={this.state.controller} strings={this.state.strings} />
							<Switch>
							<Route path="/support/flags" render={() => <Flags />} />
							<Route path="/unpairing_tool" render={() => <UnpairingToolLoadable clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/broadcasts" render={() => this.isWowzaAuth() && <ViewBroadcastsLoadable clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/broadcast/:broadcastid" render={({ match }) => this.isWowzaAuth() && <BroadcastLoadable broadcastId={match.params.broadcastid} clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/support" render={() => <SupportLoadable clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/support_tools" render={() => this.isWowzaAuth() && <SupportToolsLoadable clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/rbui" render={() => this.isWowzaAuth() && <DevicesLoadable showRollbackUI={true} showForceUpdate={false} clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/rbui-force-hidden" render={() => this.isWowzaAuth() && <DevicesLoadable showRollbackUI={true} showForceUpdate={true} clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/clearcasters" render={() => this.isWowzaAuth() && <DevicesLoadable showRollbackUI={false} showForceUpdate={false} clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Route path="/simulcast" render={() => this.isWowzaAuth() && <SimulcastLoadable clearcaster={this.state.clearcaster} controller={this.state.controller} strings={this.state.strings} />}/>
							<Redirect path="/" to={this.state.defaultRoute} />
							</Switch>
						</div>
					</div>

					<WZFooter strings={AppStrings} />
				</div>
			</BrowserRouter>
		);

	}
}

export default withCookies(App);
