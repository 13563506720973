import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { checkFeature } from '../../controller/ClearCasterAdminUtils';
import AudioInputLevelSlider from '../components/AudioInputLevelSlider';
import AudioChannelMapper from '../components/AudioChannelMapper';
import EncodingSettingsAspectRatio, { aspectRatiosEqual, getEncodingSettingAspectRatio } from '../../model/EncodingSettingsAspectRatio';
import ClearCasterPersonality from '../../model/ClearCasterPersonality';

class DialogSettingsPaired extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			shown:false,
			clearcasterName:'',
			clearcasterLocation:'',
			personality:ClearCasterPersonality.FACEBOOK,
			personalityPending:false,
			captureSetting:0,
			captureSettingPending:false,
			countdownNumberOfSteps:3,
			countdownNumberOfStepsPending:false,
			overlayGraphicsVendor:'',
			overlayGraphicsUrl:'',
			overlayGraphicsUrlDirty:false,
			overlayGraphicsPending:false,
			talentViewLayout:1,
			talentViewLayoutPending:false,
			audioLevel:0,
			update:null,
			encodingSettingsAspectRatio: EncodingSettingsAspectRatio.SOURCE,
			encodingConfigAspectRatioModePending:false,
			enableHighMotionQuality:false,
			enableHighMotionQualityPending:false,
			enableFacebookPublishPathOptimization:false,
			enableFacebookPublishPathOptimizationPending:false,
			audioChannelMap:{left:0,right:1},
			audioChannelMapPending:false,
			pendingCommandIndex:0
		};

		this.renderCaptureSettingRadios = this.renderCaptureSettingRadios.bind(this);

		this.close = this.close.bind(this);
		this.save = this.save.bind(this);
		this.isPending = this.isPending.bind(this);
		this.restart = this.restart.bind(this);
		this.sendLogs = this.sendLogs.bind(this);
		this.openUpdate = this.openUpdate.bind(this);
		this.onClearcasterName = this.onClearcasterName.bind(this);
		this.onClearcasterLocation = this.onClearcasterLocation.bind(this);
		this.onPersonalityChange = this.onPersonalityChange.bind(this);
		this.onCaptureSetting = this.onCaptureSetting.bind(this);
		this.onCountdownNumberOfSteps = this.onCountdownNumberOfSteps.bind(this);
		this.onOverlayGraphicsVendor = this.onOverlayGraphicsVendor.bind(this);
		this.onOverlayGraphicsUrl = this.onOverlayGraphicsUrl.bind(this);
		this.onOverlayGraphicsSaveUrl = this.onOverlayGraphicsSaveUrl.bind(this);
		this.onTalentViewLayout = this.onTalentViewLayout.bind(this);
		this.onAudioLevel = this.onAudioLevel.bind(this);
		this.onEncodingConfigAspectRatioMode = this.onEncodingConfigAspectRatioMode.bind(this);
		this.onMotionQuality = this.onMotionQuality.bind(this);
		this.onFacebookPublishPathOptimization = this.onFacebookPublishPathOptimization.bind(this);
		this.onAudioChannelMapChange = this.onAudioChannelMapChange.bind(this);
	}

	componentWillReceiveProps(nextProps)
	{
		let currentState = this.state;

		if (!nextProps.clearcaster.dialogs.showSettings)
		{
			currentState.shown = false;
		}

		if (nextProps.clearcaster.currentLiveEncoder == null || nextProps.clearcaster.currentLiveEncoder.serial == null)
		{
			// clear state that shouldn't linger between uses of this dialog
			currentState.overlayGraphicsUrl = "";
			currentState.overlayGraphicsVendor = "";
		}

		if (nextProps.clearcaster.currentLiveEncoder !== null &&
			typeof nextProps.clearcaster.currentLiveEncoder !== 'undefined' &&
			nextProps.clearcaster.currentLiveEncoder !== {})
		{
			if (nextProps.clearcaster.currentLiveEncoder.status !== null &&
				typeof nextProps.clearcaster.currentLiveEncoder.status !== 'undefined')
			{
				if (currentState.pendingCommandIndex <= 0 || nextProps.clearcaster.currentLiveEncoder.status.index > currentState.pendingCommandIndex)
				{
					currentState.pendingCommandIndex = 0;

					if (!currentState.shown && nextProps.clearcaster.dialogs.showSettings)
					{
						currentState.clearcasterName = this.props.clearcaster.currentLiveEncoder.name;
						currentState.receivedDeviceStatus = false;
						currentState.update = null;
						this.props.controller.getDeviceStatus(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
					}
					if (currentState.shown && !currentState.receivedDeviceStatus)
					{
						currentState.receivedDeviceStatus = true;
					}
	
					currentState.shown = nextProps.clearcaster.dialogs.showSettings;
					currentState.personality = nextProps.clearcaster.currentLiveEncoder.status.personality || ClearCasterPersonality.FACEBOOK;
					currentState.personalityPending = false;
					currentState.captureSetting = nextProps.clearcaster.currentLiveEncoder.status.videoCaptureInputSelected;
					currentState.captureSettingPending = false;
					currentState.countdownNumberOfSteps = nextProps.clearcaster.currentLiveEncoder.status.countdownNumberOfSteps;
					currentState.countdownNumberOfStepsPending = false;
					if (nextProps.clearcaster.currentLiveEncoder.status.audioCaptureChannelMap)
					{
						currentState.audioChannelMap = nextProps.clearcaster.currentLiveEncoder.status.audioCaptureChannelMap;
						currentState.audioChannelMapPending = false;
					}
	
					if (nextProps.clearcaster.currentLiveEncoder.status.overlayGraphicsConfig)
					{
						if (currentState.overlayGraphicsPending || (currentState.overlayGraphicsVendor === "" && currentState.overlayGraphicsUrl === ""))
						{
							currentState.overlayGraphicsVendor = nextProps.clearcaster.currentLiveEncoder.status.overlayGraphicsConfig.vendor;
							if (currentState.overlayGraphicsVendor === 'vidpresso' || currentState.overlayGraphicsVendor === 'custom-vidpresso')
							{
								currentState.overlayGraphicsVendor = 'custom';
							}
							currentState.overlayGraphicsUrl = nextProps.clearcaster.currentLiveEncoder.status.overlayGraphicsConfig.url;
							currentState.overlayGraphicsPending = false;
							currentState.overlayGraphicsUrlDirty = false;
						}
					}
					currentState.talentViewLayoutPending = false;
					if (nextProps.clearcaster.currentLiveEncoder.status.talentViewConfig)
					{
						currentState.talentViewLayout = nextProps.clearcaster.currentLiveEncoder.status.talentViewConfig.layout;
					}
					currentState.audioLevel = nextProps.clearcaster.currentLiveEncoder.status.audioCaptureVolumeLevel;
					if (nextProps.clearcaster.currentLiveEncoder.status.encodingConfigAspectRatioMode)
					{
						currentState.encodingConfigAspectRatioMode = nextProps.clearcaster.currentLiveEncoder.status.encodingConfigAspectRatioMode;
						currentState.encodingConfigAspectRatioModePending = false;
					}
					if (nextProps.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateEnum !== undefined &&
						nextProps.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateDen !== undefined)
					{
						currentState.enableHighMotionQuality = nextProps.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateEnum === 0 &&
							nextProps.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateDen === 0;
						currentState.enableHighMotionQualityPending = false;
					}
					if (nextProps.clearcaster.currentLiveEncoder.status.facebookPublishPathOptimization !== undefined)
					{
						currentState.enableFacebookPublishPathOptimization = nextProps.clearcaster.currentLiveEncoder.status.facebookPublishPathOptimization;
						currentState.enableFacebookPublishPathOptimizationPending = false;
					}
					}
			}
			if (nextProps.clearcaster.currentLiveEncoder.localVersions !== null &&
				typeof nextProps.clearcaster.currentLiveEncoder.localVersions !== 'undefined' &&
				nextProps.clearcaster.currentLiveEncoder.status !== null &&
				typeof nextProps.clearcaster.currentLiveEncoder.status !== 'undefined')
			{
				currentState.update = this.props.controller.getUpdate(nextProps.clearcaster.currentLiveEncoder.localVersions,nextProps.clearcaster.currentLiveEncoder.status.versionCurrent);
			}
		}
		this.setState(currentState);
	}

	isPending()
	{
		return this.state.captureSettingPending || 
			this.state.countdownNumberOfStepsPending || 
			this.state.enableHighMotionQualityPending || 
			this.state.enableFacebookPublishPathOptimizationPending || 
			this.state.encodingConfigAspectRatioModePending ||
			this.state.overlayGraphicsPending ||
			this.state.overlayGraphicsUrlDirty;
	}

	close(event)
	{
		this.props.controller.hideDialogSettings();
		if (event !== null && !(typeof event === 'undefined'))
			event.preventDefault();
	}

	save(event)
	{
		if (this.state.clearcasterName !== this.props.clearcaster.currentLiveEncoder.status.encoderName)
		{
			let currentState = this.state;
			currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
			this.props.controller.setDeviceName(this.props.clearcaster.currentLiveEncoder.encoder_uuid,this.state.clearcasterName);
		}
		if (event !== null)
			event.preventDefault();
	}

	restart(event)
	{
		let _this = this;
		let encoder_uuid = this.props.clearcaster.currentLiveEncoder.encoder_uuid;
		this.props.controller.showDialogConfirmPromise({
			title:this.props.strings.app.RestartConfirm,
			message:this.props.strings.app.RestartConfirmMessage,
			confirmText:this.props.strings.app.RestartConfirmButton,
			cancelText:this.props.strings.app.Cancel,
			showWarning:true
		})
			.then(()=>{
				_this.props.controller.hideDialogSettings();
				_this.props.controller.restart(encoder_uuid);
			}, ()=>{
				// rejected
			});
		if (event !== null)
			event.preventDefault();
	}

	sendLogs(event)
	{
		this.props.controller.sendLogs(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		if (event !== null)
			event.preventDefault();
	}

	openUpdate(event)
	{
		this.props.controller.dialogs.showUpdatesCurrentDevice();
		if (event !== null)
			event.preventDefault();
	}
	onClearcasterName(event)
	{
		let currentState = this.state;
		currentState.clearcasterName = event.target.value;
		this.setState(currentState);
	}
	onClearcasterLocation(event)
	{
		let currentState = this.state;
		currentState.clearcasterLocation = event.target.value;
		this.setState(currentState);
	}
	onPersonalityChange(event)
	{
		let newPersonality = parseInt(event.target.value,10);
		if (newPersonality !== this.props.clearcaster.currentLiveEncoder.status.personality)
		{
				let currentState = this.state;
				currentState.personality = newPersonality;
				currentState.personalityPending = true;
				currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
				this.props.controller.setDevicePersonality(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.personality);
				this.setState(currentState);
		}
	}

	onCaptureSetting(event)
	{
		let currentState = this.state;
		currentState.captureSetting = parseInt(event.target.value,10);
		currentState.captureSettingPending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setDeviceVideoInput(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.captureSetting);
		this.setState(currentState);
	}
	onCountdownNumberOfSteps(event)
	{
		let currentState = this.state;
		currentState.countdownNumberOfSteps = parseInt(event.target.value,10);
		currentState.countdownNumberOfStepsPending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setDeviceCountdownConfig(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.countdownNumberOfSteps,this.props.clearcaster.currentLiveEncoder.status.countdownTimePerStep);
		this.setState(currentState);
	}
	onOverlayGraphicsVendor(event)
	{
		let currentState = this.state;
		currentState.overlayGraphicsVendor = event.target.value;
		if (currentState.overlayGraphicsVendor === 'none')
		{
			currentState.overlayGraphicsUrl = '';
		}
		currentState.overlayGraphicsPending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setDeviceOverlayGraphicsConfig(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.overlayGraphicsVendor,currentState.overlayGraphicsUrl);
		this.setState(currentState);
	}
	onOverlayGraphicsUrl(event)
	{
		let currentState = this.state;
		currentState.overlayGraphicsUrl = event.target.value;
		if (currentState.overlayGraphicsUrl !== this.props.clearcaster.currentLiveEncoder.status.overlayGraphicsConfig.url)
		{
			currentState.overlayGraphicsUrlDirty = true;
		}
		else
		{
			currentState.overlayGraphicsUrlDirty = false;
		}
		this.setState(currentState);
	}
	onOverlayGraphicsSaveUrl(event)
	{
		let currentState = this.state;
		if (currentState.overlayGraphicsUrlDirty)
		{
			currentState.overlayGraphicsPending = true;
			currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
			this.props.controller.setDeviceOverlayGraphicsConfig(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.overlayGraphicsVendor,currentState.overlayGraphicsUrl);
			this.setState(currentState);
		}
	}
	onTalentViewLayout(event)
	{
		let currentState = this.state;
		currentState.talentViewLayout = parseInt(event.target.value,10);
		currentState.talentViewLayoutPending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setDeviceTalentViewConfig(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.talentViewLayout);
		this.setState(currentState);
	}
	onAudioLevel(event)
	{
		let currentState = this.state;
		if (event.tentativeValue !== currentState.audioLevel)
		{
			currentState.audioLevel = event.tentativeValue;
			this.setState(currentState);
			currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
			this.props.controller.setDeviceAudioLevel(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.audioLevel);
		}
	}
	onEncodingConfigAspectRatioMode(event)
	{
		let currentState = this.state;
		let nextAspectRatio = EncodingSettingsAspectRatio.NONE;
		switch(event.target.value)
		{
			case "square":
				nextAspectRatio = EncodingSettingsAspectRatio.SQUARE;
				break;
			case "standard":
				nextAspectRatio = EncodingSettingsAspectRatio.STANDARD;
				break;
			case "vertical_crop":
				nextAspectRatio = EncodingSettingsAspectRatio.VERTICAL_CROP;
				break;
			case "vertical_rotate_right":
				nextAspectRatio = EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT;
				break;
			case "vertical_rotate_left":
				nextAspectRatio = EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT;
				break;
			case "source":
			default:
				nextAspectRatio = EncodingSettingsAspectRatio.SOURCE;
				break;
			}

		currentState.encodingSettingsAspectRatio = nextAspectRatio;
		currentState.encodingConfigAspectRatioModePending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setEncodingConfigAspectRatioMode(this.props.clearcaster.currentLiveEncoder.encoder_uuid,nextAspectRatio);
		this.setState(currentState);
	}

	onMotionQuality(event)
	{
		let currentState = this.state;
	    currentState.enableHighMotionQuality = event.target.checked;
	    currentState.enableHighMotionQualityPending = true;
			currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
	    this.props.controller.setEnableHighMotionQuality(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.enableHighMotionQuality);
	    this.setState(currentState);
	}

	onFacebookPublishPathOptimization(event)
	{
		let currentState = this.state;
	    currentState.enableFacebookPublishPathOptimization = event.target.checked;
	    currentState.enableFacebookPublishPathOptimizationPending = true;
			currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
	    this.props.controller.setFacebookPublishPathOptimization(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.enableFacebookPublishPathOptimization);
	    this.setState(currentState);
	}

	onAudioChannelMapChange (newMap)
	{
		let currentState = this.state;
		currentState.audioChannelMap = newMap;
		currentState.audioChannelMapPending = true;
		currentState.pendingCommandIndex = this.props.controller.getNextCommandIndex();
		this.props.controller.setDeviceAudioChannelMap(this.props.clearcaster.currentLiveEncoder.encoder_uuid,currentState.audioChannelMap);
		this.setState(currentState);
	}

	renderCaptureSettingRadios()
	{
		if (this.props.clearcaster.currentLiveEncoder.status !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined' &&
			this.props.clearcaster.currentLiveEncoder.status.videoCaptureInputSelected !== null &&
			!this.state.captureSettingPending)
		{
			let disabled = this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STREAM-RUNNING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STOPPING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'UPDATING';
			let rowClassname = "row" + (disabled ? " text-muted" : "");
			return(
				<div className={ rowClassname } style={{marginBottom:'1em'}}>
					<label className="radio-inline">
						<input id="dialog-settings-capture-radio-auto" type="radio" onChange={this.onCaptureSetting} checked={this.state.captureSetting === 0} disabled={disabled} value="0" />
						{this.props.strings.app.CaptureSettingAuto}
					</label>
					<label className="radio-inline">
						<input id="dialog-settings-capture-radio-sdi" type="radio" onChange={this.onCaptureSetting} checked={this.state.captureSetting === 1} disabled={disabled} value="1" />
						{this.props.strings.app.CaptureSettingSDI}
					</label>
					<label className="radio-inline">
						<input id="dialog-settings-capture-radio-hdmi" type="radio" onChange={this.onCaptureSetting} checked={this.state.captureSetting === 2} disabled={disabled} value="2" />
						{this.props.strings.app.CaptureSettingHDMI}
					</label>
				</div>
			);
		}
		else
		{
			return (
				<div className="row text-muted" style={{marginBottom:'1em'}}>
					<label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
				</div>
			);
		}

	}

	renderCountdownNumberOfStepsRadios()
	{
		if (this.props.clearcaster.currentLiveEncoder.status !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined' &&
			checkFeature('countdownNumberOfSteps',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
			this.props.clearcaster.currentLiveEncoder.status.countdownNumberOfSteps !== null)
		{
			if (!this.state.countdownNumberOfStepsPending)
			{
				return (
					<div>
						<div className="row">
							<label style={{width:'100%'}}>
								{this.props.strings.app.CountdownSettings}<br />
							</label>
						</div>
						<div style={{marginBottom:'1em'}}>
							<label className="radio-inline">
								<input id="dialog-settings-countdown-radio-3" type="radio" onChange={this.onCountdownNumberOfSteps} checked={this.state.countdownNumberOfSteps === 3} value="3" />
								3
							</label>
							<label className="radio-inline">
								<input id="dialog-settings-countdown-radio-5" type="radio" onChange={this.onCountdownNumberOfSteps} checked={this.state.countdownNumberOfSteps === 5} value="5" />
								5
							</label>
							<label className="radio-inline">
								<input id="dialog-settings-countdown-radio-10" type="radio" onChange={this.onCountdownNumberOfSteps} checked={this.state.countdownNumberOfSteps === 10} value="10" />
								10
							</label>
							<label className="radio-inline">
								<input id="dialog-settings-countdown-radio-15" type="radio" onChange={this.onCountdownNumberOfSteps} checked={this.state.countdownNumberOfSteps === 15} value="15" />
								15
							</label>
						</div>
					</div>
				);
			}
			else
			{
				return (
					<div>
						<div className="row" style={{marginBottom:'1em'}}>
							<label style={{width:'100%'}}>
								{this.props.strings.app.CountdownSettings}<br />
							</label>
							{this.props.strings.app.CountdownSettingsPrompt}<br />
						</div>
						<div className="row text-muted" style={{marginBottom:'1em'}}>
							<label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
						</div>
					</div>
				);
			}
		}
		else
		{
			return null;
		}
	}

	renderOverlayGraphics()
	{
		if (this.props.clearcaster.currentLiveEncoder.status &&
			this.props.clearcaster.currentLiveEncoder.status.overlayGraphicsConfig)
		{
			if (!this.state.overlayGraphicsPending)
			{
				return (
					<div className="form-group">
						<label htmlFor="dialog-settings-overlaygraphics-vendor">{this.props.strings.app.BroadcastPropertyOverlayVideoLabel}</label>
						<select id="dialog-settings-overlaygraphics-vendor" className="form-control" value={this.state.overlayGraphicsVendor} onChange={this.onOverlayGraphicsVendor}>
								<option value='none' key='0'>{this.props.strings.app.None}</option>
								<option value='custom' key='2'>{this.props.strings.app.Custom}</option>
						</select>
						{ this.state.overlayGraphicsVendor !== 'none' && this.state.overlayGraphicsVendor !== 'default' &&
						<input className="form-control"
								type="text"
								value={this.state.overlayGraphicsUrl}
								placeholder={this.props.strings.app.BroadcastPropertyOverlayVideoURLHint}
								onBlur={this.onOverlayGraphicsSaveUrl}
								onChange={this.onOverlayGraphicsUrl}/>
						}
					</div>
				);
			}
			else
			{
				return (
					<div>
						<div className="row" style={{marginBottom:'1em'}}>
							<label style={{width:'100%'}}>
								{this.props.strings.app.BroadcastPropertyOverlayVideoLabel}<br />
							</label>
						</div>
						<div className="row text-muted" style={{margin:'0',height:68}}>
							<label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
						</div>
					</div>
				);
			}
	
		}
		else
		{
			return null;
		}

	}

	renderTalentViewLayoutRadios()
	{
		if (this.props.clearcaster.currentLiveEncoder.status &&
			this.props.clearcaster.currentLiveEncoder.status.versionCurrent &&
			checkFeature('talentViewLayout',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
			this.props.clearcaster.currentLiveEncoder.status.talentViewConfig &&
			this.props.clearcaster.currentLiveEncoder.status.talentViewConfig.layout)
		{
			if (!this.state.talentViewLayoutPending)
			{
				return(
					<div>
						<div className="row">
							<label style={{width:'100%'}}>
								{this.props.strings.app.TalentViewSettings}<br />
							</label>
						</div>
						<div className="row">
							<div className="col-sm-6" style={{marginBottom:'1em'}}>
								<label className={this.state.talentViewLayout === 1 ? "radio-inline active" : "radio-inline"}>
									<input id="dialog-settings-talentview-radio-1" type="radio" onChange={this.onTalentViewLayout} checked={this.state.talentViewLayout === 1} value="1" />
									<img src="/images/talentview-1.png" alt={this.props.strings.app.TalentViewLargeComments} style={{width:224,height:126}} />
								</label>
							</div>
							<div className="col-sm-6" style={{marginBottom:'1em'}}>
								<label className={this.state.talentViewLayout === 2 ? "radio-inline active" : "radio-inline"}>
									<input id="dialog-settings-talentview-radio-2" type="radio" onChange={this.onTalentViewLayout} checked={this.state.talentViewLayout === 2} value="2" />
									<img src="/images/talentview-2.png" alt={this.props.strings.app.TalentViewFullScreenVideo} style={{width:224,height:126}} />
								</label>
							</div>
						</div>
					</div>
				);
			}
			else
			{
				return (
					<div>
						<div className="row" style={{marginBottom:'1em'}}>
							<label style={{width:'100%'}}>
								{this.props.strings.app.TalentViewSettings}<br />
							</label>
						</div>
						<div className="row text-muted" style={{margin:'0',height:126}}>
							<label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
						</div>
					</div>
				);
			}
		}
		else
		{
			return null;
		}
	}

	renderAspectRatio()
	{
    if (this.props.clearcaster.currentLiveEncoder.status &&
      this.props.clearcaster.currentLiveEncoder.status.versionCurrent &&
      checkFeature('encodingConfigAspectRatioMode',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
      this.props.clearcaster.currentLiveEncoder.status.encodingConfigAspectRatioMode !== undefined &&
      this.props.clearcaster.currentLiveEncoder.status.encodingConfigAspectRatioMode != null) {
      return (
        <div id="AspectRatio">
          <div className="row">
            <label style={{width: '100%'}}>
              {this.props.strings.app.OutputAspectRatio}
            </label>
          </div>
          {this.renderAspectRatioSelect()}
        </div>
      );
    }
      return null;
	}
  renderAspectRatioSelect()
  {
    if (this.props.clearcaster.currentLiveEncoder.status !== null &&
      typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined')
		{
			if (!this.state.encodingConfigAspectRatioModePending) {
        let disabled = this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STREAM-RUNNING' ||
					this.props.clearcaster.currentLiveEncoder.status.encoderState === "STREAM-PREVIEW" ||
					this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STOPPING' ||
					this.props.clearcaster.currentLiveEncoder.status.encoderState === 'UPDATING';

				let currentAspectRatio = getEncodingSettingAspectRatio(this.props.clearcaster.currentLiveEncoder.status);
				let currentAspectRatioValue = 'none';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.SOURCE))
					currentAspectRatioValue = 'source';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.SQUARE))
					currentAspectRatioValue = 'square';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.STANDARD))
					currentAspectRatioValue = 'standard';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.VERTICAL_CROP))
					currentAspectRatioValue = 'vertical_crop';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT))
					currentAspectRatioValue = 'vertical_rotate_right';
				if (aspectRatiosEqual(currentAspectRatio,EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT))
					currentAspectRatioValue = 'vertical_rotate_left';

				return (
					<div className="form-group" style={{marginBottom: '1em', marginTop: '0'}}>
							{ checkFeature('verticalVideo',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
								<select className="form-control" id="dialog-settings-aspect-ratio-select"
									onChange={this.onEncodingConfigAspectRatioMode}
									value={currentAspectRatioValue}
									disabled={disabled}>
									<option value="source">{this.props.strings.app.AspectRatioSource}</option>
									<option value="square">{this.props.strings.app.AspectRatioSquare}</option>
									<option value="standard">{this.props.strings.app.AspectRatioStandard}</option>
									<option value="vertical_crop">{this.props.strings.app.AspectRatioVerticalCrop}</option>
									{ checkFeature('verticalRotate',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
										<option value="vertical_rotate_right">{this.props.strings.app.AspectRatioVerticalRotateRight}</option>
									}
									{ checkFeature('verticalRotate',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
										<option value="vertical_rotate_left">{this.props.strings.app.AspectRatioVerticalRotateLeft}</option>
									}
								</select>
							}
							{ !checkFeature('verticalVideo',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
								<select className="form-control" id="dialog-settings-aspect-ratio-select"
									onChange={this.onEncodingConfigAspectRatioMode}
									value={JSON.stringify(currentAspectRatio)}
									disabled={disabled}>
									<option value={JSON.stringify(EncodingSettingsAspectRatio.SOURCE)}>{this.props.strings.app.AspectRatioSource}</option>
									<option value={JSON.stringify(EncodingSettingsAspectRatio.SQUARE)}>{this.props.strings.app.AspectRatioSquare}</option>
								</select>
							}
					</div>
        );
      } else {
				return (
				<div className="checkbox" style={{marginBottom: '1em'}}>
					<label>{ this.props.strings.app.UpdatingElipsis }</label>
				</div>
				);
			}
    }
    else
    {
      return null;
    }

	}
	
	renderFacebookPublishPathOptimization()
	{
		if (this.props.clearcaster.currentLiveEncoder.status &&
			this.props.clearcaster.currentLiveEncoder.status.versionCurrent &&
			checkFeature('facebookPublishPathOptimization',this.props.clearcaster.currentLiveEncoder.status.versionCurrent)
		)
		{
			return (
				<div id="FacebookPublishPathOptimization">
					<div className="row">
						<label style={{width: '100%'}}>
							{this.props.strings.app.FacebookPublishPathOptimization}
						</label>
					</div>
					{this.renderFacebookPublishPathOptimizationCheckBox()}
				</div>
			);
		}
		else
		{
			return null;
		}
	}

	renderFacebookPublishPathOptimizationCheckBox()
	{
		if (this.props.clearcaster.currentLiveEncoder.status !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined')
		{
			if (!this.state.enableFacebookPublishPathOptimizationPending)
			{
				let disabled = this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STREAM-RUNNING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === "STREAM-PREVIEW" ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STOPPING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'UPDATING';

				let checked = this.state.enableFacebookPublishPathOptimization; //this.props.clearcaster.currentLiveEncoder.status.facebookPublishPathOptimization;

				return (
					<div className="checkbox" style={{marginBottom: '1em', marginTop:'0'}}>
						<label><input id="dialog-settings-facebook-publish-path-optimization-checkbox" type="checkbox"
							onChange={this.onFacebookPublishPathOptimization}
							checked={checked}
							disabled={disabled}/>
							<span title={this.props.strings.app.FacebookPublishPathOptimizationHoverText}>{ this.props.strings.app.FacebookPublishPathOptimizationPrompt }</span>
						</label>
						&nbsp;<span title={this.props.strings.app.FacebookPublishPathOptimizationHoverText}><i className="fa fa-question-circle" style={{color:'#428bca',cursor:'pointer'}}></i></span>
					</div>
				);
			}
			else
			{
				return (
					<div className="checkbox" style={{marginBottom: '1em'}}>
						<label>{ this.props.strings.app.UpdatingElipsis }</label>
					</div>
				);
			}
		}
		else
		{
		return null;
		}

	}

	renderMotionQuality()
	{
		if (this.props.clearcaster.currentLiveEncoder.status &&
			this.props.clearcaster.currentLiveEncoder.status.versionCurrent &&
			checkFeature('enableHighMotionQuality',this.props.clearcaster.currentLiveEncoder.status.versionCurrent) &&
			typeof this.props.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateDen !== 'undefined' &&
			typeof this.props.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateEnum !== 'undefined')
		{
			return (
				<div id="MotionQuality">
					<div className="row">
						<label style={{width: '100%'}}>
							{this.props.strings.app.MotionQuality}
						</label>
					</div>
					{this.renderMotionQualityCheckBox()}
				</div>
			);
		}
		else
		{
			return null;
		}
	}
	renderMotionQualityCheckBox()
	{
		if (this.props.clearcaster.currentLiveEncoder.status !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined')
		{
			if (!this.state.enableHighMotionQualityPending)
			{
				let disabled = this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STREAM-RUNNING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === "STREAM-PREVIEW" ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STOPPING' ||
				this.props.clearcaster.currentLiveEncoder.status.encoderState === 'UPDATING';

				let checked = this.props.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateDen === 0 &&
					this.props.clearcaster.currentLiveEncoder.status.videoCaptureMaxFrameRateEnum === 0;

				return (
					<div className="checkbox" style={{marginBottom: '1em', marginTop:'0'}}>
						<label><input id="dialog-settings-motion-quality-checkbox" type="checkbox"
							onChange={this.onMotionQuality}
							checked={checked}
							disabled={disabled}/>
							<span title={this.props.strings.app.MotionQualityHoverText}>{ this.props.strings.app.MotionQualityPrompt }</span>
						</label>
						&nbsp;<span title={this.props.strings.app.MotionQualityHoverText}><i className="fa fa-question-circle" style={{color:'#428bca',cursor:'pointer'}}></i></span>
					</div>
				);
			}
			else
			{
				return (
					<div className="checkbox" style={{marginBottom: '1em'}}>
						<label>{ this.props.strings.app.UpdatingElipsis }</label>
					</div>
				);
			}
		}
		else
		{
		return null;
		}

	}

	render()
	{
		let device = this.props.clearcaster.currentLiveEncoder;

		if (device == null)
			return null;

		let version = "0.0.0.0";
		if (device.status && device.status.versionCurrent)
			version = device.status.versionCurrent;

		return (
			<Modal show={this.props.clearcaster.dialogs.showSettings} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.SettingsColon } { device.name }</Modal.Title>
				</Modal.Header>
				<form>
					<Modal.Body>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginBottom:'1em',marginRight:0}}>
								<label style={{width:'100%'}}>
									{this.props.strings.app.ClearCasterName}<br />
								</label>
								<div className="input-group">
									<input id="dialog-settings-name-input" className="form-control" type="text" value={this.state.clearcasterName} onChange={this.onClearcasterName} />
									<span className="input-group-btn">
										<button id="dialog-settings-name-save-button" className="btn btn-primary" type="button" onClick={this.save}>{this.props.strings.app.Save}</button>
									</span>
								</div>
							</div>
							{ checkFeature('workplace',version) && (
								<div className="row" style={{marginBottom:'1em',marginRight:0}}>
									<label style={{width: '100%'}} htmlFor="dialog-settings-personality">
										{this.props.strings.app.UseThisClearCasterWith}&nbsp;<span title={this.props.strings.app.WorkplaceHelpText}><i className="fa fa-question-circle" style={{color:'#428bca',cursor:'pointer'}}></i></span>
									</label>
									{ this.state.personalityPending &&
										<div className="row text-muted" style={{marginBottom:'1em'}}>
											<label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
										</div>
									}
									{ !this.state.personalityPending &&
										<span>
											<select className="form-control" id="dialog-activsettingsate-personality" value={this.state.personality} onChange={this.onPersonalityChange}>
												{Object.keys(ClearCasterPersonality).map((personality,keyIndex) =>
													<option key={keyIndex} value={ClearCasterPersonality[personality]}>{this.props.strings.app['ClearCasterPersonality_'+personality]}</option>
												)}
											</select>
											<div style={{color:'#d9534f',fontSize:'13px',marginTop:'5px'}}>{this.props.strings.app.ClearCasterPersonalityWarning}</div>
										</span>
									}
								</div>
							)}
							{/*
							<div className="row" style={{marginBottom:'1em'}}>
								<label style={{width:'100%'}}>
									{this.props.strings.app.ClearCasterLocation}<br />
									<input className="form-control" type="text" value={this.state.clearcasterLocation} onChange={this.onClearcasterLocation} />
								</label>
							</div>
							*/}
							<div className="row" style={{marginBottom:'1em'}}>
								<label style={{width:'100%'}}>
									{this.props.strings.app.CaptureSetting}<br />
								</label>
								{this.props.strings.app.CaptureSettingPrompt}<br />
							</div>
							{ this.renderCaptureSettingRadios() }

							<div className="row" style={{marginBottom:'1em'}}>
								<label>{ this.props.strings.app.AudioLevel }</label><br />
								{ this.state.receivedDeviceStatus && (
									<AudioInputLevelSlider
										title=""
										value={ this.state.audioLevel }
										tentativeValue={ this.state.audioLevel }
										stepNumerator={ 1 }
										stepDenominator={ 2 }
										visible={ true }
										min={ -12 }
										max={ 12 }
										onChange={ this.onAudioLevel }
									/>
								)}
							</div>

							{ this.renderAspectRatio() }

							{ this.renderMotionQuality() }

							{ this.renderCountdownNumberOfStepsRadios() }

							{ this.renderTalentViewLayoutRadios() }

							{ this.renderOverlayGraphics() }

							{ checkFeature('defaultAudioChannelMap',version) &&
								<AudioChannelMapper
									id="audioChannelMapper"
									strings={this.props.strings}
									showDefaults={false}
									audioChannelMap={this.state.audioChannelMap}
									onChange={this.onAudioChannelMapChange}
									updating={this.state.audioChannelMapPending}
								/>						
							}

							{ this.renderFacebookPublishPathOptimization() }

						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginRight:0}}>
								<button id="dialog-settings-ok-button" className="btn btn-primary" onClick={this.close} disabled={this.isPending()}>{ this.props.strings.app.OK }</button>
								<button id="dialog-settings-sendlogs-button" className="btn btn-default pull-left" onClick={this.sendLogs}>Send Logs</button>&nbsp;
								{ this.state.update !== null && (
									<span className="pull-left">&nbsp;<button id="dialog-settings-applyupdate-button" className="btn btn-info" onClick={this.openUpdate}>{ this.props.strings.app.UpdateApply }</button></span>
								)}
								{ checkFeature('devicereboot',version) && (
									<span className="pull-left">&nbsp;<button id="dialog-settings-restart-button" className="btn btn-default" onClick={this.restart}>{ this.props.strings.app.Restart }</button></span>
								)}
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>
		);
	}
}

export default DialogSettingsPaired;
