var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

import useSheet from "react-jss";
import transitionStyles from "../transition-styles";
export default useSheet(_extends({}, transitionStyles, {
  alert: {
    composes: "alert",
    marginBottom: "6px",
    display: "block",
    minWidth: "200px",
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left"
  },
  info: {
    composes: "alert-info"
  },
  success: {
    composes: "alert-success"
  },
  warning: {
    composes: "alert-warning"
  },
  danger: {
    composes: "alert-danger"
  },
  dismissable: {
    composes: "alert-dismissable"
  },
  close: {
    composes: "close"
  },
  msgContainer: {
    display: "inline-block"
  },
  icon: {
    verticalAlign: "top",
    fontSize: "18px",
    paddingRight: "16px",
    opacity: 0.2
  },
  headline: {
    margin: 0,
    marginBottom: "6px"
  },
  body: {
    maxWidth: "40em"
  }
}));