import React from "react";
import Icon from "./icon";
import styles from "./styles";

var Alert = function Alert(_ref) {
  var _ref$type = _ref.type,
      type = _ref$type === undefined ? "info" : _ref$type,
      children = _ref.children,
      headline = _ref.headline,
      onDismiss = _ref.onDismiss,
      _ref$dismissTitle = _ref.dismissTitle,
      dismissTitle = _ref$dismissTitle === undefined ? "Dismiss" : _ref$dismissTitle,
      classes = _ref.sheet.classes,
      _ref$showIcon = _ref.showIcon,
      showIcon = _ref$showIcon === undefined ? true : _ref$showIcon;
  var isDismissable = !!onDismiss;
  var css = (isDismissable ? classes.dismissable : "") + " " + classes[type] + " " + classes.alert;
  var dismiss = isDismissable ? React.createElement("button", {
    type: "button",
    className: classes.close,
    title: dismissTitle,
    onClick: onDismiss
  }, "\xD7") : null;
  return React.createElement("div", null, React.createElement("div", {
    className: css
  }, dismiss, showIcon ? React.createElement(Icon, {
    className: classes.icon,
    type: type
  }) : null, React.createElement("div", {
    className: classes.msgContainer
  }, headline ? React.createElement("h4", {
    className: classes.headline
  }, headline) : null, React.createElement("div", {
    className: classes.body
  }, children))));
};

export default styles(Alert);