import ClearCasterStore from '../model/ClearCasterStore';
import AppStrings from '../model/AppStrings';
import Log from '../log/Log';
import Cookies from 'cookies-js';

// TODO: Pull state-related transformations into a ClearCasterStoreUtils

export function doubleEquals(a, b, threshold = 0.01)
{
	return (Math.abs((a-b)) <= threshold);
}

export function redirectToLogin()
{
	let currentURL = window.location.href;
	let redirectURL = window.config.REACT_APP_AUTH_URL + '?format=clearcaster&onsuccess=' + encodeURIComponent(currentURL);
	setTimeout(function () {
		window.location.href = redirectURL;
	},1);
}

export function redirectToLogout()
{
	let currentURL = window.location.href;
	let redirectURL = window.config.REACT_APP_AUTH_LOGOUT_URL + '?format=clearcaster&onsuccess=' + encodeURIComponent(currentURL);
	setTimeout(function () {
		window.location.href = redirectURL;
	},1);
}

export function logout()
{
	Cookies.expire(window.config.REACT_APP_AUTH_COOKIE,{domain:'.wowza.com'});
	Cookies.expire(window.config.REACT_APP_USER_COOKIE,{domain:'.wowza.com'});
	redirectToLogout();
}

export function queryStringToObject(queryString)
{
	var pairs = queryString.split('&');
	var result = {};
	pairs.forEach(function(pair) 
	{
			pair = pair.split('=');
			result[pair[0]] = decodeURIComponent(pair[1] || '');
	});	
	return result;
}

export function isActiveEncoderByBroadcast(encoderId,broadcast)
{
	let tag = "ClearCasterAdminUtils";
	let encoder = getEncoderFromFacebookEncoderId(encoderId);
	if (encoder && encoder['status'] && encoder['status']['broadcastDestinationUrl'] && broadcast['ingest_streams'] && broadcast['ingest_streams'].length > 0)
	{
		Log.debug(tag + '.isActiveEncoder: encoderId:' + encoderId + ', name:' + (encoder['name'] || 'undefined'));
		// it is better to look for ingest streams based on what the encoder knows its destination is, so try that first
		// but requires 1.0.4.17. just test for broadcastDestinationUrl in the status
		let destinationUrl = encoder['status']['broadcastDestinationUrl'];
		let destinationQuery = {};
		Log.debug(tag + '.isActiveEncoder: destinationUrl:' + destinationUrl);
		if (destinationUrl.indexOf('?') > 0) 
		{
			let s = destinationUrl.split('?');
			destinationUrl = s[0];
			destinationQuery = queryStringToObject(s[1]);
		}

		if (destinationQuery['s_sw'] !== undefined && parseInt(destinationQuery['s_sw']) < broadcast.ingest_streams.length)
		{
			Log.debug(tag + '.isActiveEncoder[s_sw]: --- ingestStream.is_master:' + broadcast.ingest_streams[parseInt(destinationQuery['s_sw'])]['is_master']);
			return broadcast.ingest_streams[parseInt(destinationQuery['s_sw'])]['is_master'] ? true : false;
		}
		else
		{
			for (var i = 0; i < broadcast.ingest_streams.length; i++)
			{
				let ingestStreamUrl = broadcast.ingest_streams[i].secure_stream_url;
				Log.debug(tag + '.isActiveEncoder: - ingestStreamUrl:' + ingestStreamUrl);
				if (ingestStreamUrl.indexOf(destinationUrl) === 0)
				{
					let reallyMatch = false;
					// Facebook changed what these urls look like. If it has a "_0" or "_1" we are fine.
					// Otherwise double check the "a" query param to decide if this is a match.
					if (ingestStreamUrl.indexOf("_0") > 0 || ingestStreamUrl.indexOf("_1") > 0)
					{
						reallyMatch = true;
					}
					else
					{
						let s2 = ingestStreamUrl.split('?');
						if (s2.length > 1)
						{
							let ingestStreamQuery = queryStringToObject(s2[1]);
							if (destinationQuery['a'] === ingestStreamQuery['a'])
							{
								reallyMatch = true;
							}	
						}
					}
					if (reallyMatch)
					{
						Log.info(tag + '.isActiveEncoder[loop]: --- ingestStream.is_master:' + broadcast.ingest_streams[i]['is_master']);
						return broadcast.ingest_streams[i]['is_master'] ? true : false;
					}
				}
			}
		}
	}
	Log.debug(tag + '.isActiveEncoder: no status or ingest_streams, returning based on index: ' + (broadcast.ingestMap[encoderId] && broadcast.ingestMap[encoderId]['is_master'] ? true : false));
	// this works in cases where the order of the CC's in the live_encoders array have not changed
	return broadcast.ingestMap[encoderId] && broadcast.ingestMap[encoderId]['is_master'] ? true : false;
}

export function isEncoderUUIDEqual (uuid1, uuid2)
{
	if (uuid1.indexOf(":") > 0)
		uuid1 = uuid1.split(":")[0];
	if (uuid2.indexOf(":") > 0)
		uuid2 = uuid2.split(":")[0];
	return (uuid1 === uuid2);
}

export function getWowzaPairingKeyFromUUID (uuid)
{
	if (uuid === undefined || uuid === null)
		return '';
	if (uuid.indexOf(":") > 0)
	{
		uuid = uuid.split(":")[0];
	}
	let state = ClearCasterStore.getState();
	if (state.wowzaPairings && state.wowzaPairings.pairings)
	{
		for (var pairingIdKey in state.wowzaPairings.pairings)
		{
			if (state.wowzaPairings.pairings[pairingIdKey].encoder_uuid === uuid)
			{
				return pairingIdKey;
			}
		}
	}
	return '';
}

export function getWowzaPairingKeyFromEncoderId (encoderID)
{
	let state = ClearCasterStore.getState();

	if (state.wowzaPairings && state.wowzaPairings.pairings)
	{
		for (var pairingKey in state.wowzaPairings.pairings)
		{
			if (state.wowzaPairings.pairings[pairingKey]['status'] && typeof state.wowzaPairings.pairings[pairingKey]['status'] !== 'undefined' )
			{
				let pairing = state.wowzaPairings.pairings[pairingKey];
				if (pairing['status']['encoderId'] === encoderID)
				{
					return pairingKey;
				}
			}
		}
	}
	return '';
}

export function getEncoderFromWowzaPairingKey (pairingKey)
{
	let state = ClearCasterStore.getState();

	if (state.wowzaPairings && state.wowzaPairings.pairings)
	{
		for (var localPairingKey in state.wowzaPairings.pairings)
		{
			if (pairingKey === localPairingKey)
			{
				return state.wowzaPairings.pairings[localPairingKey];
			}
		}
	}
	return null;
}

export function getEncoderFromFacebookEncoderId (encoderId)
{
	return getEncoderFromWowzaPairingKey(getWowzaPairingKeyFromEncoderId(encoderId));
}

export function getFacebookLiveEncoderFromId (encoderId)
{
	let state = ClearCasterStore.getState();
	return state.facebookLiveEncoderMap[encoderId];
}

// TODO: deleteme
export function getLiveBroadcastsForEncoder (facebookEncoderId)
{
	let state = ClearCasterStore.getState();
	let broadcasts = [];
	if (state.facebookLiveBroadcasts !== null)
	{
		for (var key1 in state.facebookLiveBroadcasts)
		{
			for (var key2 in state.facebookLiveBroadcasts[key1].live_encoders)
			{
				if (facebookEncoderId === state.facebookLiveBroadcasts[key1].live_encoders[key2].id)
				{
					broadcasts.push(state.facebookLiveBroadcasts[key1]);
					break;
				}
			}
		}
	}
	return broadcasts;
}

export function isLoggedInFacebookUserID (userID)
{
	let state = ClearCasterStore.getState();
	if (state.userFacebook !== null &&
		typeof state.userFacebook !== 'undefined' &&
		state.userFacebook.userID !== null &&
		typeof state.userFacebook.userID !== 'undefined' &&
		state.userFacebook.userID === userID)
	{
		return true;
	}
	return false;
}

export function isVersionGreaterThan (v1, v2)
{
	let v1parts = v1.split(".");
	let v2parts = v2.split(".");

	if (v1parts.length !== v2parts.length)
	{
		return v1parts.length > v2parts.length;
	}

	for (var i = 0; i < v1parts.length; i++)
	{
		let v1part = parseInt(v1parts[i],10);
		let v2part = parseInt(v2parts[i],10);
		if (v1part === v2part)
			continue;
		return (v1part > v2part);
	}

	return false;
}

export function checkFeature (featureName, encoderVersion)
{
	if (process.env.REACT_APP_CHECK_FEATURE_VERSIONS === 'false')
		return true;

	let state = ClearCasterStore.getState();
	let featureVersion = state.featureEncoderVersions[ featureName ];

	if (featureVersion === null || typeof featureVersion === 'undefined')
		return false;

	if ( isVersionGreaterThan(featureVersion, encoderVersion))
		return false;

	return true;
}

export function getMaxVersionFromPairedEncoders ()
{
	let version = "0.0.0.0";
	let state = ClearCasterStore.getState();

	if (state.wowzaPairings && state.wowzaPairings.pairings)
	{
		for (var pairingKey in state.wowzaPairings.pairings)
		{
			if (state.wowzaPairings.pairings[pairingKey]['status'] && typeof state.wowzaPairings.pairings[pairingKey]['status'] !== 'undefined' && state.wowzaPairings.pairings[pairingKey]['status']['versionCurrent'])
			{
				let testVersion = state.wowzaPairings.pairings[pairingKey]['status']['versionCurrent'];
				if (isVersionGreaterThan(testVersion,version))
				{
					version = testVersion;
				}
			}
		}
	}
	return version;
}


export function getUniqueClearCasterName ()
{
	let state = ClearCasterStore.getState();
	let pairings = state.wowzaPairings.pairings;
	let clearCasterName = AppStrings.app.DefaultNewClearCasterName;

	for (var i = 1; i <= Object.keys(pairings).length+1; i++)
	{
		let foundName = false;
		if (i > 1)
			clearCasterName = AppStrings.app.DefaultNewClearCasterName + ' ' + i;

		for (var key in pairings)
		{
			if (pairings[key].name === clearCasterName)
			{
				foundName = true;
				console.log('---- found it!');
				break;
			}
		}
		if (!foundName)
			break;
	}
	return clearCasterName;
}

export function partitionArray (arr,partitionSize)
{
	let returnArray = [];
	for (var i = 0; i < arr.length; i += partitionSize)
	{
		returnArray[returnArray.length] = arr.slice(i,i+partitionSize);
	}
	return returnArray;
}

export function formatFPS(fps)
{
	if (Math.round(fps) === fps)
	{
		return fps+'';
	}
	else
	{
		return fps.toFixed(2);
	}
}

export function testListOfObjectsWithIds (a,b)
{
	if (!a && !b)
	{
		return true;
	}
	if (!a || !b)
	{
		return false;
	}
	if (a.length !== b.length)
	{
		return false;
	}
	for (var i = 0; i < a.length; i++)
	{
		let curr = a[i];
		let foundMatch = false;
		for (var j = 0; j < b.length; j++)
		{
			if (curr['id'] === b[j]['id'])
			{
				foundMatch = true;
				break;
			}
		}
		if (!foundMatch)
		{
			return false;
		}
	}	
	return true;
}

export function mixObjects ()
{
	let obj = {};
	for (var i = 0; i < arguments.length; i++)
	{
		for (var key in arguments[i])
		{
			obj[key] = arguments[i][key];
		}
    }
	return obj;
}
