export const BroadcastsActionTypes =
{
	SET_BROKER_BROADCAST_INFO_MAP:'SET_BROKER_BROADCAST_INFO_MAP',
	SET_FACEBOOK_ACTIVE_BROADCASTS:'SET_FACEBOOK_ACTIVE_BROADCASTS',
	SET_FACEBOOK_LIVE_VIDEOS_MAP:'SET_FACEBOOK_LIVE_VIDEOS_MAP',
	SET_BROADCASTS_LIST_RELOAD:'SET_BROADCASTS_LIST_RELOAD',
	SHOW_DIALOG_CREATE_BROADCAST: 'SHOW_DIALOG_CREATE_BROADCAST',
	HIDE_DIALOG_CREATE_BROADCAST: 'HIDE_DIALOG_CREATE_BROADCAST',
}

export const TargetingGender = 
{
    MALE:1,
    FEMALE:2
};


export default function ClearCasterBroadcastsReducer ( state = {
	brokerBroadcastInfoMap:{},
	facebookActiveBroadcasts:[],
	facebookActiveBroadcastMap:{},
	facebookCrosspostedBroadcasts:[],
	facebookLiveVideosMap:{},
	showCreateBroadcast:false,
	createBroadcastId:undefined,
	createBroadcastInfo:undefined,
	isFirstLoad:true,
	isReload:false
}, action )
{
    switch (action.type)
	{
		case BroadcastsActionTypes.SET_BROADCASTS_LIST_RELOAD:
			state.isReload = true;
			return state;
		case BroadcastsActionTypes.SET_BROKER_BROADCAST_INFO_MAP:
			state.brokerBroadcastInfoMap = action.brokerBroadcastInfoMap;
			return state;
		case BroadcastsActionTypes.SET_FACEBOOK_ACTIVE_BROADCASTS:
			state.facebookActiveBroadcasts = action.activeBroadcasts;
			state.facebookActiveBroadcastMap = action.activeBroadcastMap;
			state.facebookCrosspostedBroadcasts = action.crosspostedBroadcasts;
			state.isFirstLoad = false;
			state.isReload = false;
			return state;
		case BroadcastsActionTypes.SET_FACEBOOK_LIVE_VIDEOS_MAP:
			if (action.facebookLiveVideosMap !== null)
			{
				state.facebookLiveVideosMap = action.facebookLiveVideosMap;
			}
			else
			{
				state.facebookLiveVideos = {};
			}
			return state;
		case BroadcastsActionTypes.SHOW_DIALOG_CREATE_BROADCAST:
			state.showCreateBroadcast = true;
			state.showDialogCreateBroadcast = true;
			state.createBroadcastId = action.broadcastId;
			state.createBroadcastInfo = action.broadcastInfo;
			return state;
		case BroadcastsActionTypes.HIDE_DIALOG_CREATE_BROADCAST:
			state.showCreateBroadcast = false;
			return state;
		default:
            return state;
    }
}
