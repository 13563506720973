import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ClearCasterPersonality from '../../model/ClearCasterPersonality';

class DialogFacebookUnpair extends Component
{
	constructor(props)
	{
		super(props);
		this.close = this.close.bind(this);
		this.unpairWithFacebook = this.unpairWithFacebook.bind(this);
	}

	componentWillReceiveProps(nextProps)
	{
		if (this.props.clearcaster.dialogs.showFacebookUnpair &&
			this.props.clearcaster.currentLiveEncoder.status &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined' &&
			this.props.clearcaster.currentLiveEncoder.status.encoderState === "UNPAIRED-GRAPHAPI")
		{
			this.props.controller.hideDialogFacebookUnpair();
		}
	}

	close(event)
	{
		this.props.controller.hideDialogFacebookUnpair();
		if (event !== null)
			event.preventDefault();
	}

	unpairWithFacebook(event)
	{
		this.props.controller.deviceFBUnpair(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		let personality = this.props.clearcaster.currentLiveEncoder['status'] && this.props.clearcaster.currentLiveEncoder['status']['personality'] ? this.props.clearcaster.currentLiveEncoder['status']['personality'] : ClearCasterPersonality.FACEBOOK;
		let promptString = this.props.strings.app.FacebookUnpairFacebookPrompt;

		if (personality === ClearCasterPersonality.FACEBOOK)
		{
			promptString = promptString.replace(/\{facebook-link\}/g,'<a href="https://www.facebook.com/device" target="_blank">');
			promptString = promptString.replace(/\{end-link\}/g,'</a>');
		}
		else if (personality === ClearCasterPersonality.WORKPLACE)
		{
			promptString = this.props.strings.app.FacebookUnpairWorkplacePrompt;
			promptString = promptString.replace(/\{facebook-link\}/g,'<a href="https://work.facebook.com/device" target="_blank">');
			promptString = promptString.replace(/\{end-link\}/g,'</a>');
		}

		let updating = this.props.clearcaster.currentLiveEncoder.updating;

		return (
			<Modal show={this.props.clearcaster.dialogs.showFacebookUnpair} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>
						{personality === ClearCasterPersonality.FACEBOOK && this.props.strings.app.FacebookUnpairWithFacebook}
						{personality === ClearCasterPersonality.WORKPLACE && this.props.strings.app.FacebookUnpairWithWorkplace}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<div dangerouslySetInnerHTML={{ __html: promptString }}></div>
							<br />
							<button id="dialog-facebookunpair-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.strings.app.Cancel }</button>&nbsp;
							{ updating && (
								<button id="dialog-facebookunpair-unpair-button" className="btn btn-default" disabled="disabled">Unpairing...</button>
							)}
							{ !updating && (
								<button id="dialog-facebookunpair-unpair-button" className="btn btn-primary" onClick={this.unpairWithFacebook}>
									{personality === ClearCasterPersonality.FACEBOOK && this.props.strings.app.FacebookUnpairWithFacebook}
									{personality === ClearCasterPersonality.WORKPLACE && this.props.strings.app.FacebookUnpairWithWorkplace}
								</button>
							)}
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogFacebookUnpair;
