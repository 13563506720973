export const ChangeUUIDFormActionTypes =
{
    SET_CHANGE_UUID_FORM: 'SET_CHANGE_UUID_FORM'
}

export default function ClearCasterChangeUUIDFormReducer (
    state = {
        serial:"",
    }, action )
{
    switch (action.type)
	{
    case ChangeUUIDFormActionTypes.SET_CHANGE_UUID_FORM:
      state.serial = action.changeUUIDForm['serial'];
			return state;
    default:
      return state;
  }
}
