import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class DialogChangeUUID extends Component
{

	constructor(props)
	{
		super(props);


		this.onShow = this.onShow.bind(this);
		this.close = this.close.bind(this);
		this.renderConfirmChange = this.renderConfirmChange.bind(this);
		this.initiateChange = this.initiateChange.bind(this);
    this.renderBlankModal = this.renderBlankModal.bind(this);
	}

	onShow()
	{}

	componentWillReceiveProps(nextProps)
	{}

	close(event)
	{
		this.props.controller.hideDialogChangeUUID();
	}

  initiateChange() {
    let changeUUIDForm = this.props.clearcaster.changeUUIDForm;
    changeUUIDForm.status="submitted";
    this.props.controller.changeUUIDForm(changeUUIDForm);
    this.props.controller.clearCasterChangeUUID();
	}

	renderConfirmChange() {
    let wowza_org_name, encoder_name;
    const encoder_serial=this.props.clearcaster.changeUUIDForm.serial;
    if (encoder_serial && this.props.clearcaster.encoderInfo[encoder_serial]) {
      wowza_org_name=this.props.clearcaster.encoderInfo[encoder_serial].wowza_org_name||"Retrieving...";
      encoder_name=this.props.clearcaster.encoderInfo[encoder_serial].encoder_name||"Retrieving...";
    }
    if (encoder_serial && this.props.clearcaster.encoderInfo[encoder_serial] && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name.length && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name.length > 0) {
      wowza_org_name=this.props.clearcaster.encoderInfo[encoder_serial].wowza_org_name;
      encoder_name=this.props.clearcaster.encoderInfo[encoder_serial].encoder_name;
    } else {
      wowza_org_name="Retrieving...";
      encoder_name="Retrieving...";
    }

    return (
			<Modal show={this.props.clearcaster.dialogs.showChangeUUID} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure you want to change this ClearCaster's UUID?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="well">
						<strong>Organization: </strong>{wowza_org_name}<br/>
						<strong>ClearCaster: </strong>{encoder_name}<br/>
					</div>
					<p>By clicking <strong>YES</strong> below, this ClearCaster will perform a reboot and change its UUID.
						<br/>This cannot be undone!</p>
					<button className="btn btn-default" onClick={this.initiateChange}><strong>Yes, change UUID</strong></button>&nbsp;&nbsp;
					<button className="btn btn-primary" onClick={this.close}>Cancel</button>
				</Modal.Body>
			</Modal>
    );
	}

	renderBlankModal()
	{
    return (
			<Modal show={this.props.clearcaster.dialogs.showChangeUUID} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
				</Modal.Body>
			</Modal>
    );
	}

  renderStatusModal(status,error)
  {
  	let encoder_name, header, body;
    const encoder_serial=this.props.clearcaster.changeUUIDForm.serial;
    if (encoder_serial && this.props.clearcaster.encoderInfo[encoder_serial] && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name.length && this.props.clearcaster.encoderInfo[encoder_serial].encoder_name.length > 0) {
      encoder_name=this.props.clearcaster.encoderInfo[encoder_serial].encoder_name;
    } else {
      encoder_name="ClearCaster"
		}
    switch (status) {
      case 'pending':
        header="Updating ClearCaster UUID...";
        body=<div>
					<strong>{encoder_name}</strong>is now updating its UUID.<br/>
					Please do not turn off power during this process.
				</div>
        break;
      case 'partial success':
        header="Rebooting ClearCaster...";
        body=<div>
					<strong>{encoder_name}</strong>is now rebooting.<br/>
					Error: {error}<br/>
					Please do not turn off power during this process.
					<button className="btn btn-primary" onClick={this.close}><strong>OK</strong></button>
				</div>
        break;
      case 'success':
        header="Rebooting ClearCaster...";
        body=<div>
					<strong>{encoder_name}</strong>is now rebooting and changing its UUID.<br/>
					Please do not turn off power during this process.
					<button className="btn btn-primary" onClick={this.close}><strong>OK</strong></button>
				</div>
        break;
      case 'error':
        header="Error Updating ClearCaster UUID!";
        body=<div>
					<strong>{encoder_name}</strong>has experienced an error updating.<br/>
					Please escalate to development.
					Error: {error}<br/>
					<button className="btn btn-primary" onClick={this.close}><strong>OK</strong></button>
				</div>
        break;
      default:
    };

    return (
			<Modal show={this.props.clearcaster.dialogs.showChangeUUID} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{body}
				</Modal.Body>
			</Modal>
    );
  }

	render()
	{
    const encoder_serial=this.props.clearcaster.changeUUIDForm.serial;
    let status,error;
    if (encoder_serial && this.props.clearcaster.encoderInfo[encoder_serial] && this.props.clearcaster.encoderInfo[encoder_serial].changes && this.props.clearcaster.encoderInfo[encoder_serial].changes.change_uuid) {
      status=this.props.clearcaster.encoderInfo[encoder_serial].changes.change_uuid.status;
      error=this.props.clearcaster.encoderInfo[encoder_serial].changes.change_uuid.error;
    }

		let ret_val;
    switch (status) {
      case undefined:
        ret_val=this.renderConfirmChange();
        break;
			case 'pending':
				ret_val=this.renderStatusModal(status,error);
				break;
			case 'partial success':
				ret_val=this.renderStatusModal(status,error);
				break;
			case 'success':
        ret_val=this.renderStatusModal(status,error);
        break;
			case 'error':
				ret_val=this.renderStatusModal(status,error);
				break;
      default:
      	ret_val=this.renderBlankModal();
    };
		return ret_val
	}
}

export default DialogChangeUUID;
