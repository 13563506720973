import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import './DialogRollback.css';


class DialogRollback extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			update:null,
			updating:false
		};

		this.onShow = this.onShow.bind(this);
		this.close = this.close.bind(this);
		this.applyUpdate = this.applyUpdate.bind(this);
	}

	setUpdate(device)
	{
		let update = null;
		if (device !== null &&
			typeof device !== 'undefined' &&
			device.localVersions !== null &&
			typeof device.localVersions !== 'undefined' &&
			device.status &&
			typeof device.status.versionCurrent !== 'undefined'
		)
		{
			update = this.props.controller.getRollback(device.localVersions,device.status.versionCurrent);
		}
		let state = this.state;
		state.update = update;
		this.setState(state);
	}

	onShow()
	{
		this.setUpdate(this.props.clearcaster.currentLiveEncoder);
	}
	componentWillReceiveProps(nextProps)
	{
		this.setUpdate(nextProps.clearcaster.currentLiveEncoder);
	}

	close(event)
	{
		this.props.controller.hideDialogRollback();
		if (event !== null)
			event.preventDefault();
	}

	applyUpdate(event)
	{
		if (this.state.update !== null)
		{
			this.props.controller.applyUpdate(this.props.clearcaster.currentLiveEncoder.encoder_uuid,this.state.update,true);
		}
		// TODO: SOMETHING MORE GRACEFUL
		this.props.controller.hideDialogRollback();
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		let device = this.props.clearcaster.currentLiveEncoder;

		if (device === null ||
			typeof device === 'undefined' ||
			device.status === null ||
			typeof device.status === 'undefined')
		{
			return null;
		}

		if (this.state.update == null || this.state.update.v == null)
		{
			return null;
		}

		return (
			<Modal show={this.props.clearcaster.dialogs.showRollback} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.RollbackDialogTitle }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							{ this.props.strings.app.RollbackDialogPrompt1 }<br />
							<br />
							<strong>"{ device.status.encoderName }"</strong> { this.props.strings.app.RollbackDialogPrompt2 }<br />
							<br />
							<div className="rollback-panel">
								<div className="current-version">{ this.props.strings.app.CurrentVersionColon }<br />{device.status.versionCurrent}</div>
								<div className="rollback-icon"><i className="fa fa-angle-double-right fa-3x" /></div>
								<div className="rollback-version">{ this.props.strings.app.RollbackToVersion }<br />{this.state.update.v}</div>
							</div>
							<br />
							<br />
							<button id="dialog-rollback-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.strings.app.Cancel }</button>&nbsp;
							<button id="dialog-rollback-applyupdate-button" className="btn btn-primary" onClick={this.applyUpdate}>{ this.props.strings.app.RollbackBegin }</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogRollback;
