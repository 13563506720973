export const ActivateFormActionTypes =
{
    SET_ACTIVATE_FORM: 'SET_ACTIVATE_FORM',
    ADD_PENDING_PERSONALITY_CHANGE: 'ADD_PENDING_PERSONALITY_CHANGE',
    REMOVE_PENDING_PERSONALITY_CHANGE: 'REMOVE_PENDING_PERSONALITY_CHANGE'
}

export default function ClearCasterActivateFormReducer (
    state = {
        activationCode:"",
        activateName:"",
        acceptedTOS:false,
        personality:1,
        tosError:false,
        pendingPersonalityChanges:{}
    }, action )
{
    switch (action.type)
	{
        case ActivateFormActionTypes.SET_ACTIVATE_FORM:
            state.activationCode = action.activateForm['activationCode'];
            state.activateName = action.activateForm['activateName'];
            state.acceptedTOS = action.activateForm['acceptedTOS'] || false;
            state.personality = action.activateForm['personality'] || state.personality;
            state.tosError = action.activateForm['tosError'] || false;
            return state;
        case ActivateFormActionTypes.ADD_PENDING_PERSONALITY_CHANGE:
            state.pendingPersonalityChanges[action.correlationid] = action.personality;
            return state;
        case ActivateFormActionTypes.REMOVE_PENDING_PERSONALITY_CHANGE:
            if (state.pendingPersonalityChanges[action.correlationid])
            {
                delete state.pendingPersonalityChanges[action.correlationid];
            }
            return state;
        default:
            return state;
    }
}
