class Messenger
{
	constructor()
	{
		this.messageListeners = [];
		this.errorListeners = [];
	}
	addListener(listeners, listener)
	{
		for (var i = 0; i < listeners.length; i++)
		{
			if (listeners[i] === listener)
				return false;
		}
		listeners.push (listener);
		return true;
	}
	removeListener(listeners, listener)
	{
		for (var i = 0; i < listeners.length; i++)
		{
			if (listeners[i] === listener)
			{
				listeners.slice(i,1);
				return true;
			}
		}
		return false;
	}
	updateListeners (listeners, message, pairingKey)
	{
		for (var i = 0; i < listeners.length; i++)
		{
			listeners[i](message,pairingKey);
		}
	}
	addMessageListener (listener)
	{
		return this.addListener(this.messageListeners, listener);
	}
	removeMessageListener (listener)
	{
		return this.removeListener(this.messageListeners, listener);
	}
	sendMessageToListeners (message, pairingKey)
	{
		this.updateListeners(this.messageListeners, message, pairingKey);
	}
	addErrorListener (listener)
	{
		return this.addListener(this.errorListeners, listener);
	}
	removeErrorListener (listener)
	{
		return this.removeListener(this.errorListeners, listener);
	}
	handleError(errorMessage)
	{
		this.updateListeners(this.errorListeners,errorMessage, null);
	}
}
export default Messenger;
