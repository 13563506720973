import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class DialogUpdates extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			update:null,
			updating:false
		};

		this.onShow = this.onShow.bind(this);
		this.close = this.close.bind(this);
		this.applyUpdate = this.applyUpdate.bind(this);
	}

	setUpdate(device)
	{
		let update = null;
		if (device !== null &&
			typeof device !== 'undefined' &&
			device.localVersions !== null &&
			typeof device.localVersions !== 'undefined' &&
			device.status !== null &&
			typeof device.status !== 'undefined')
		{
			update = this.props.controller.getUpdate(device.localVersions,device.status.versionCurrent);
		}
		let state = this.state;
		state.update = update;
		this.setState(state);
	}

	setForcedUpdate(versionStr)
	{
		let update = null;
		if (this.props.clearcaster.settings.force_versions === undefined || this.props.clearcaster.settings.force_versions.length <= 0)
		{
			return;
		}
		for (var i = 0; i < this.props.clearcaster.settings.force_versions.length; i++)
		{
			let curr = this.props.clearcaster.settings.force_versions[i];
			if (curr.v === versionStr)
			{
				update = curr;
				break;
			}
		}
		let state = this.state;
		state.update = update;
		this.setState(state);
	}

	onShow()
	{
		if (this.props.clearcaster.showForceUpdate && this.props.clearcaster.forceUpdateVersion !== '')
		{
			this.setForcedUpdate(this.props.clearcaster.forceUpdateVersion);
		}
		else
		{
			this.setUpdate(this.props.clearcaster.currentLiveEncoder);
		}
	}

	componentWillReceiveProps(nextProps)
	{
		if (this.props.clearcaster.showForceUpdate && this.props.clearcaster.forceUpdateVersion !== '')
		{
			this.setForcedUpdate(this.props.clearcaster.forceUpdateVersion);
		}
		else
		{
			this.setUpdate(this.props.clearcaster.currentLiveEncoder);
		}
	}

	close(event)
	{
		this.props.controller.hideDialogUpdates();
		if (event !== null)
			event.preventDefault();
	}

	applyUpdate(event)
	{
		if (this.state.update !== null)
		{
			this.props.controller.applyUpdate(this.props.clearcaster.currentLiveEncoder.encoder_uuid,this.state.update);
		}
		// TODO: SOMETHING MORE GRACEFUL
		this.props.controller.hideDialogUpdates();
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		let prompt = '';
		let currentVersion = '';
		let currentDeviceName = '';
		let device = this.props.clearcaster.currentLiveEncoder;

		if (this.state.update !== null && this.state.update.v !== null)
		{
			prompt = this.props.strings.app.UpdateAvailablePrompt.replace("{version}","<b>"+this.state.update.v+"<b>");
		}
		if (device !== null &&
			typeof device !== 'undefined' &&
			device.status !== null &&
			typeof device.status !== 'undefined')
		{
			if (device.status.versionCurrent !== null)
			{
				currentVersion = this.props.strings.app.CurrentVersion.replace("{version}",device.status.versionCurrent);
			}
			if (device.status.encoderName !== null)
			{
				currentDeviceName = ': ' + device.status.encoderName;
			}
		}

		return (
			<Modal show={this.props.clearcaster.dialogs.showUpdates} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.UpdateAvailable }{ currentDeviceName }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<span dangerouslySetInnerHTML={{ __html: prompt }}></span><br />
							<br />
							<button id="dialog-updates-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.strings.app.Cancel }</button>&nbsp;
							<button id="dialog-updates-applyupdate-button" className="btn btn-primary" onClick={this.applyUpdate}>{ this.props.strings.app.UpdateApply }</button>
							<br />
							<div className="pull-right"><i>{ currentVersion }</i></div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogUpdates;
