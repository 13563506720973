import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import FacebookConnectionState from '../../model/FacebookConnectionState';

class DialogFacebook extends Component
{

	constructor(props)
	{
		super(props);
		this.close = this.close.bind(this);
		this.logoutOfFacebook = this.logoutOfFacebook.bind(this);
		this.loginToFacebook = this.loginToFacebook.bind(this);
		this.pairWithFacebook = this.pairWithFacebook.bind(this);
		this.doNotPairWithFacebook = this.doNotPairWithFacebook.bind(this);
	}

	componentWillReceiveProps(nextProps)
	{
		if (this.props.clearcaster.dialogs.showFacebook &&
			this.props.clearcaster.currentLiveEncoder.status &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined' &&
			this.props.clearcaster.currentLiveEncoder.status.encoderState !== "UNPAIRED-GRAPHAPI")
		{
			this.props.controller.hideDialogFacebook();
		}
	}

	close(event)
	{
		this.props.controller.hideDialogFacebook();
		if (event !== null)
			event.preventDefault();
	}

	logoutOfFacebook(event)
	{
		this.props.controller.userFacebookLogoutReloadRequest(true);
		if (event !== null)
			event.preventDefault();
	}
	loginToFacebook(event)
	{
		this.props.controller.userFacebookLogin();
		if (event !== null)
			event.preventDefault();
	}
	pairWithFacebook(event)
	{
		if (this.props.clearcaster.currentLiveEncoder.status)
		{
			this.props.controller.deviceFBPair(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		}
		else
		{
			this.props.controller.activateDevice(true);
		}
		if (event !== null)
			event.preventDefault();
	}
	doNotPairWithFacebook(event)
	{
		if (this.props.clearcaster.currentLiveEncoder.status)
		{
			this.props.controller.hideDialogFacebook();
		}
		else
		{
			this.props.controller.activateDevice(false);
		}
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		let updating = false;
		if (typeof this.props.clearcaster.currentLiveEncoder.updating !== 'undefined')
		{
			updating = this.props.clearcaster.currentLiveEncoder.updating;
		}

		if (this.props.clearcaster.userFacebook.facebookConnected !== FacebookConnectionState.CONNECTED)
		{
			let connectPromptString = this.props.strings.app.FacebookNotConnected;
			connectPromptString = connectPromptString.replace(/\{facebook-link\}/g,'<a href="https://www.facebook.com/device" target="_blank">');
			connectPromptString = connectPromptString.replace(/\{end-link\}/g,'</a>');

			return (
				<Modal show={this.props.clearcaster.dialogs.showFacebook} onHide={this.close}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.FacebookPairWithFacebook}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="form-group">
								<div dangerouslySetInnerHTML={{ __html: connectPromptString }}></div>
								<br />
								<button id="dialog-facebook-donotconnect-button" className="btn btn-default" onClick={this.doNotPairWithFacebook}>{ this.props.strings.app.FacebookDoNotConnect }</button>&nbsp;
								{ !updating && (
									<button id="dialog-facebook-connect-button" className="btn btn-primary" onClick={this.loginToFacebook}>{ this.props.strings.app.FacebookConnect }</button>
								)}
								{ updating && (
									<button id="dialog-facebook-connect-button" className="btn btn-default" disabled="disabled">Pairing... </button>
								)}
							</div>
						</form>
					</Modal.Body>
				</Modal>
			);
		}
		else
		{
			let pairPromptString = this.props.strings.app.FacebookPairPrompt;
			if (this.props.clearcaster.currentLiveEncoder &&
				this.props.clearcaster.currentLiveEncoder.status &&
				this.props.clearcaster.currentLiveEncoder.status.pairingCode)
			{
				pairPromptString = this.props.strings.app.FacebookPairPromptWithCode;
				pairPromptString = pairPromptString.replace(/\{pairing-code}/g,this.props.clearcaster.currentLiveEncoder.status.pairingCode);
				pairPromptString += '<br /><i>' + this.props.strings.app.FacebookPairPromptWithCodeChangeNotice + '</i>';
			}
			pairPromptString = pairPromptString.replace(/\{facebook-link\}/g,'<a href="https://www.facebook.com/device" target="_blank">');
			pairPromptString = pairPromptString.replace(/\{end-link\}/g,'</a>');

			let pairConfirmString = this.props.strings.app.FacebookPairConfirm;
			pairConfirmString = pairConfirmString.replace(/\{name\}/g, this.props.clearcaster.userFacebook.name );

			return (
				<Modal show={this.props.clearcaster.dialogs.showFacebook} onHide={this.close}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.FacebookPairWithFacebook}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div style={{padding:'0 1em'}}>
								<div style={{position:'relative',height:'100px'}}>
									<div style={{position:'absolute',top:'0',left:'0'}}>
									{ this.props.clearcaster.userFacebook.picture !== null && this.props.clearcaster.userFacebook.picture.data && this.props.clearcaster.userFacebook.picture.data.url !== '' &&
										<img src={ this.props.clearcaster.userFacebook.picture.data.url } alt="" style={{marginRight:'1em'}}/>
									}
									</div>
									<div style={{position:'absolute',top:'0',left:'115px',right:'0',height:'100px'}}>
										{this.props.strings.app.FacebookConnectedAs} <strong>{ this.props.clearcaster.userFacebook.name }</strong>.<br />
										<span className="text-muted" style={{fontStyle:'italic'}} dangerouslySetInnerHTML={{ __html: this.props.strings.app.FacebookSignInAsDifferentUser }}></span><br />
										<div style={{position:'absolute',bottom:'0',left:'0'}}>
											<button id="dialog-facebook-donotpair-button" className="btn btn-default" onClick={this.doNotPairWithFacebook}>{ this.props.strings.app.Cancel }</button>&nbsp;
											{ !updating && (
												<button id="dialog-facebook-pair-button" className="btn btn-primary" onClick={this.pairWithFacebook}>{ pairConfirmString }</button>
											)}
											{ updating && (
												<button id="dialog-facebook-pair-button" className="btn btn-default" disabled="disabled">Pairing... </button>
											)}
										</div>
									</div>
								</div>
								<div style={{backgroundColor:'#eeeeee',margin:'2em 0 0 0',padding:'1em 2em',borderRadius:'1em'}}>
									<div dangerouslySetInnerHTML={{ __html: pairPromptString }}></div>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			);
		}

	}
}

export default DialogFacebook;
