import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import AppStrings from '../model/AppStrings';
import Log from '../log/Log';
import Facebook from '../transport/Facebook';
import FacebookConnectionState from '../model/FacebookConnectionState';
import MQTTConnection, { getUserStatusTopic, getEncoderTopicByPairingId, getUpdatePairTopicByPairingId, getEncoderInfoTopic } from '../transport/MQTTConnection';
import { getWowzaPairingKeyFromUUID } from './ClearCasterAdminUtils.js';
import CheckWithTimeoutPromiseFactory from './CheckWithTimeoutPromiseFactory';

import ClearCasterAdminControllerActivateMixin from './ClearCasterAdminControllerActivateMixin';
import ClearCasterAdminControllerAlertsMixin from './ClearCasterAdminControllerAlertsMixin';
import ClearCasterAdminControllerBroadcastsMixin from './ClearCasterAdminControllerBroadcastsMixin';
import ClearCasterAdminControllerBrokerMixin from './ClearCasterAdminControllerBrokerMixin';
import ClearCasterAdminControllerChangeUUIDMixin from './ClearCasterAdminControllerChangeUUIDMixin';
import ClearCasterAdminControllerDialogsMixin from './ClearCasterAdminControllerDialogsMixin';
import ClearCasterAdminControllerFacebookMixin from './ClearCasterAdminControllerFacebookMixin';
import ClearCasterAdminControllerGoLiveMixin from './ClearCasterAdminControllerGoLiveMixin';
import ClearCasterAdminControllerSimulcastMixin from './ClearCasterAdminControllerSimulcastMixin';
import ClearCasterAdminControllerUpdatesMixin from './ClearCasterAdminControllerUpdatesMixin';

const pako = require('pako');

class ClearCasterAdminController
{
	constructor()
	{
		this.tag = "ClearCasterAdminController";

		this.statusTimeoutId = null;

		this.facebookConnection = null;
		this.mqttConnection = new MQTTConnection();

		this.onMqttMessage = this.onMqttMessage.bind(this);

		this.mqttConnection.addMessageListener( this.onMqttMessage );

		ClearCasterAdminControllerActivateMixin(this);
		ClearCasterAdminControllerAlertsMixin(this);
		ClearCasterAdminControllerBroadcastsMixin(this);
		ClearCasterAdminControllerBrokerMixin(this);
		ClearCasterAdminControllerChangeUUIDMixin(this);
		ClearCasterAdminControllerDialogsMixin(this);
		ClearCasterAdminControllerFacebookMixin(this);
		ClearCasterAdminControllerGoLiveMixin(this);
		ClearCasterAdminControllerSimulcastMixin(this);
		ClearCasterAdminControllerUpdatesMixin(this);

		let _this = this;
		fetch('https://clearcaster.wowza.com/updates/v1/versions.json')
		.then(function(response) {
			if (response.status >= 400) {
				Log.error('Error loading settings file:');
				Log.error(response);
				this.raiseAlert({
					type:'danger',
					headline: AppStrings.app.Error,
					message: "Error loading settings: Bad response from server"
				});
				return;
			}
			return response.json();
		})
		.then(function(data) {

			//data['settings'].remote.facebook_deviceinput_serials = ["AV08170022"];

			// console.log(JSON.stringify(data.settings, null, 2));

			if (data['settings'])
			{
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_SETTINGS,
					settings:data.settings
				});
				_this.facebookConnection = new Facebook(_this,process.env.REACT_APP_FACEBOOK_APPID,data['settings']['remote']['facebook_graph_api_version'],data['settings']['remote']['facebook_required_scopes']);
			}
			if (data['notifications'])
			{
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_NOTIFICATIONS,
					notifications:data.notifications
				});
			}
		});

	}

	// OTHER PAGE STATES
	setUserWowzaRequired(required)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_USER_WOWZA_REQUIRED,
			required:required
		});
	}

	showRollbackUI(showRollbackUI)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_ROLLBACK_UI,
			showRollbackUI:showRollbackUI
		});
	}

	// ENCODER ACTIONS



	getAccountStatus()
	{
		let state = ClearCasterStore.getState();
		if (state.userWowza.username !== null && state.userWowza.username !== '')
		{
			if (!this.mqttConnection.isConnected())
			{
				this.mqttConnection.openConnection(state.userWowza.username,state.userWowza.jwt);
			}
			this.mqttConnection.sendMessage(getUserStatusTopic(state.userWowza.username),{
				"subject":"get user pairing status",
				"wowza_jwt":state.userWowza.jwt
			});
		}
	}

	ensureWowzaPairingStatusPromise()
	{
		let state0 = ClearCasterStore.getState();
		if (!state0.userWowza.receivedUserPairingStatus)
		{
			this.getAccountStatus();
		}
		return CheckWithTimeoutPromiseFactory(() =>
		{
			let state = ClearCasterStore.getState();
			if (!state.userWowza.receivedUserPairingStatus)
			{
				return null;
			}
			else
			{
				return {'success':'true'};
			}			
		},
		process.env.REACT_APP_REQUEST_TIMEOUT,
		{
			type:'danger',
			headline:'Timeout',
			message:'Timeout getting account pairing status'
		});
	}

	ensureFacebookConnectedPromise()
	{
		let controller = this;
		return CheckWithTimeoutPromiseFactory(() =>
		{
			let state = ClearCasterStore.getState();
			if (state.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED)
			{
				controller.facebookGetLiveEncoders();
				return {'success':'true'};
			}
			else
			{
				return null;
			}
		},
		process.env.REACT_APP_REQUEST_TIMEOUT,
		{
			type:'danger',
			headline: 'Timeout',
			message: 'Timeout getting facebook connection status'
		});
	}

	getDeviceStatus(device_id)
	{
		// console.log('----. ClearCasterAdminController.getDeviceStatus');
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'deviceGetStatus',
				deviceId:0
			});
		}
	}

	getDeviceLocalVersions(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'deviceGetLocalVersions',
				deviceId:0
			});
		}
	}

	getDeviceNetworkInfo(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'networkgetinfo',
				deviceId:0
			});
		}
	}

	setDeviceName(device_id,encoderName)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		let state = ClearCasterStore.getState();
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getUpdatePairTopicByPairingId(pairingKey),{
				"subject":"update pairing",
				"name":encoderName,
				"wowza_jwt":state.userWowza.jwt
			});
			// this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
			// 	command:'devicesetname',
			// 	deviceId:0,
			// 	encoderName:encoderName
			// });
		}
	}

	setDevicePersonality(device_id,personality)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessagePromise(getEncoderTopicByPairingId(pairingKey),{
				command:'devicefbunpair',
				deviceId:0
			})
			.then(() =>{
				this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
					command:'devicesetencoderpersonality',
					deviceId:0,
					personality:personality
				});
			});
		}
	}

	getDeviceAudioLevel(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicegetparameters',
				deviceId:0,
				parameterValues:[{
					name:'blackmagic-capture.bmdDeckLinkConfigDigitalAudioInputScale',
					value:''
				}]
			});
		}
	}

	setDeviceAudioLevel(device_id,audioLevel)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{

			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetaudiovolumelevel',
				deviceId:0,
				volumeLevel:audioLevel
			});
		}
	}

	setDeviceAudioChannelMap(device_id,audioChannelMap)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetaudiochannelmap',
				deviceId:0,
				left:audioChannelMap['left'],
				right:audioChannelMap['right']
			});
		}
	}

	setDeviceCountdownConfig(device_id, countdownNumberOfSteps, countdownTimePerStep)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetcountdownconfig',
				deviceId:0,
				numberOfSteps:countdownNumberOfSteps,
				timePerStep:countdownTimePerStep
			});
		}
	}

	setDeviceOverlayGraphicsConfig(device_id, vendor, url)
	{
		console.log('setDeviceOverlayGraphicsConfig');
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			console.log('pairingkey:'+pairingKey);
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetoverlaygraphicsconfig',
				deviceId:0,
				vendor:vendor,
				url:url
			});
		}
	}

	setDeviceTalentViewConfig(device_id, talentViewLayout)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesettalentviewconfig',
				deviceId:0,
				layout:talentViewLayout
			});
		}
	}

  setEncodingConfigAspectRatioMode(device_id, aspectRatio)
  {
    let pairingKey = getWowzaPairingKeyFromUUID(device_id);
    if (pairingKey !== '')
    {
      this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
        command:'devicesetencodingsettings',
        deviceId:0,
				aspectRatioMode:aspectRatio.aspectRatioMode,
				aspectRatioWidth:aspectRatio.aspectRatioWidth,
				aspectRatioHeight:aspectRatio.aspectRatioHeight,
				aspectRatioRotation:aspectRatio.aspectRatioRotation
      });
    }
  }

	setEnableHighMotionQuality(device_id, enableHighMotionQuality)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		let frameRateDen = 0;
		let frameRateEnum = 0;
		if (!enableHighMotionQuality)
		{
			frameRateDen = 1;
			frameRateEnum = 30;
		}
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetvideocapturemaxframerate',
				deviceId:0,
				frameRateEnum:frameRateEnum,
				frameRateDen:frameRateDen
			});
		}
	}

	getNextCommandIndex()
	{
		return this.mqttConnection.getNextCommandIndex();
	}

	setFacebookPublishPathOptimization(device_id, facebookPublishPathOptimization)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetencoderfacebookpublishpathoptimization',
				deviceId:0,
				facebookPublishPathOptimization:facebookPublishPathOptimization
			});
		}
	}

	setDeviceInput(device_id, inputInfo)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetbroadcastinputconfig',
				deviceId:0,
				inputType:inputInfo.inputType,
				mediaCasterType:inputInfo.mediaCasterType,
				mediaCasterStreamInfo:inputInfo.mediaCasterStreamInfo,
				decodingImplementation:inputInfo.decodingImplementation,
				deinterlace:inputInfo.deinterlace,
				deinterlaceDoubleRate:inputInfo.deinterlaceDoubleRate,
				deinterlaceImplementation:inputInfo.deinterlaceImplementation,
			});
		}
	}

	setDeviceVideoInput(device_id, videoInput)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetvideoinput',
				deviceId:0,
				videoCaptureInputSelected:videoInput
			});
		}
	}
	setDeviceSystemLogMQTTFilter(device_id,systemEventLogsMQTTFilter)
	{
    let pairingKey = getWowzaPairingKeyFromUUID(device_id);
    if (pairingKey !== '')
    {
      this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
        command:'devicesetsystemlogmqttfilter',
        deviceId:0,
        systemEventLogsMQTTFilter:systemEventLogsMQTTFilter
      });
    }
	}

	deviceStopBroadcast(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			ClearCasterStore.dispatch({
				pairingKey:pairingKey,
				type: ActionTypes.SET_ENCODER_UPDATING,
				updating:true
			});
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicestopbroadcast',
				deviceId:0
			});
		}
	}

	deviceFBUnpair(device_id,callback)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			ClearCasterStore.dispatch({
				pairingKey:pairingKey,
				type: ActionTypes.SET_ENCODER_UPDATING,
				updating:true
			});
			this.mqttConnection.sendMessagePromise(getEncoderTopicByPairingId(pairingKey),{
				command:'devicefbunpair',
				deviceId:0
			})
			.then((data)=>{
				if (callback !== undefined) callback();
			});
		}
		else
		{
			if (callback !== undefined) callback();
		}
	}

	deviceFBPair(device_id)
	{
		let state = ClearCasterStore.getState();
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			ClearCasterStore.dispatch({
				pairingKey:pairingKey,
				type: ActionTypes.SET_ENCODER_UPDATING,
				updating:true
			});
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicesetgraphapiaccesscode',
				accessCodeStr:state.userFacebook.accessToken,
				deviceId:0
			});
		}
	}

	getStreamStats(device_id)
	{
		// console.log('----. ClearCasterAdminController.getStreamStats:' + device_id);
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'streamgetstats',
				deviceId:0
			});
		}
	}


	sendLogs(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'deviceexportlogdata',
				deviceId:0
			});
		}
	}

	restart(device_id)
	{
		let pairingKey = getWowzaPairingKeyFromUUID(device_id);
		if (pairingKey !== '')
		{
			let state = ClearCasterStore.getState();
			let deviceName = state.wowzaPairings.pairings[pairingKey].name;
			this.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicereboot',
				deviceId:0
			});
			this.raiseAlert({
				type:'warning',
				headline: (AppStrings.app.Restarting+' '+deviceName),
				message: ''
			});
		}
	}

	getEncoderInfo(encoder_serial)
	{
    let state = ClearCasterStore.getState();
    const clearCasterObj={
      subject:'encoder info',
      encoder_serial:state.changeUUIDForm.serial,
      wowza_jwt:state.userWowza.jwt
    };
    this.mqttConnection.sendMessage(getEncoderInfoTopic(state.userWowza.username),clearCasterObj);
	}
  clearEncoderChangeInfo(encoder_serial,change){
    ClearCasterStore.dispatch({
      type: ActionTypes.SET_ENCODER_INFO_CHANGE_STATUS,
      status: null,
      error: null,
      encoder_serial: encoder_serial,
      change: change
    });
	}

	// INCOMING MESSAGE HANDLING

	onMqttMessage( message, pairingKey )
	{
		let currentTime = (new Date()).getTime();
		// console.log('<### ClearCasterController.onMessage. pairingKey: ' + pairingKey);
		// console.log(JSON.stringify(message));
		if (message.connectionError !== undefined || message.messageFormatError !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SHOW_EXPIRED_WOWZA_SESSION
			});
			return;
		}

		// Handle messages from the Broker
		if (message.subject && message.subject !== '')
		{
			switch(message.subject)
			{
			case 'user encoder pair status':
				if (!message.paired)
				{
					if (message.error && message.error !== '')
					{
						let alertMessage = message.error;
						if (typeof message.error !== 'string')
						{
							alertMessage = JSON.stringify(message.error);
						}
						this.raiseAlert({
							type:'danger',
							headline: AppStrings.app.Error,
							message: alertMessage
						});
					}
					else
					{
						this.raiseAlert({
							type:'danger',
							headline: AppStrings.app.Error,
							message: AppStrings.app.ErrorUnknown
						});
					}
				}
				else
				{
					let state = ClearCasterStore.getState();
					if (state.activateForm.pendingPersonalityChanges[message.correlationid])
					{
						this.mqttConnection.sendMessagePromise(getEncoderTopicByPairingId(message.pairing_id),{
							command:'devicesetencoderpersonality',
							deviceId:0,
							personality:state.activateForm.pendingPersonalityChanges[message.correlationid]
						})
						.then((data)=>{
							ClearCasterStore.dispatch({
								type:ActionTypes.REMOVE_PENDING_PERSONALITY_CHANGE,
								correlationId:message.correlationid
							});		
						});
					}
				}
				break;
			case 'user pairing status':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_WOWZA_PAIRING_STATUS,
					paired: message.paired,
					pairings: message.pairings,
					super: message.super,
					jwtExpiration: message['jwt_expiration'],
					jwtExpired:message['jwt_expired']
				});
				for (var pairingId in message.pairings)
				{
					let state = ClearCasterStore.getState();
					this.mqttConnection.openConnectionWithPairingID(pairingId);
					if (state.currentLiveEncoder !== null && state.currentLiveEncoder.encoder_uuid)
					{
						let currentPairingId = getWowzaPairingKeyFromUUID(state.currentLiveEncoder.encoder_uuid);
						if (pairingId === currentPairingId)
						{
							ClearCasterStore.dispatch({
								type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
								currentLiveEncoder: message.pairings[pairingId]
							});
						}
					}
				}
				let _this = this;
				setTimeout(function () {
					_this.getPairedDeviceStatuses();
				},process.env.REACT_APP_STATUS_DELAY);
				break;
			case 'encoder info':
				if (!message.error) {
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_ENCODER_INFO,
						encoder_serial: message.encoder_serial,
						pairing_status: message.pairing_status,
						wowza_org: message.wowza_org,
						wowza_org_name: message.wowza_org_name,
						uuid: message.uuid,
						registrant: message.registrant,
						pairing_date: message.pairing_date,
						encoder_name: message.encoder_name
					});
				}
        break;
			case 'encoder change uuid response':
				const status = message.status;
				const error = message.error;
				const encoder_serial = message.encoder_serial;
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_ENCODER_INFO_CHANGE_STATUS,
					status: status,
					error: error,
					encoder_serial: encoder_serial,
					change: 'change_uuid'
				});
				break;
			case 'get streamTarget allObjects':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_SIMULCAST_STREAM_TARGETS,
					status_code: message.status_code,
					objects: message.objects,
				});
				break;
			case 'get streamTarget object':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_SIMULCAST_STREAM_TARGET,
					status_code: message.status_code,
					streamTarget: message.streamTarget,
				});
				break;
			case 'remove streamTarget object':
				ClearCasterStore.dispatch({
					type: ActionTypes.REMOVE_SIMULCAST_STREAM_TARGET,
					status_code: message.status_code,
					streamTarget: message.streamTarget,
				});
				break;
			case 'state wsc streamTarget':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_SIMULCAST_STREAM_TARGET_STATE,
					status_code: message.status_code,
					streamTargetId: message.streamTargetId,
					state: message.state,
				});
				break;
			case 'stop wsc streamTarget':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_SIMULCAST_STREAM_TARGET_STATE,
					status_code: message.status_code,
					streamTargetId: message.streamTargetId,
					state: message.state,
				});
				break;
			case 'start wsc streamTarget':
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_SIMULCAST_STREAM_TARGET_STATE,
						status_code: message.status_code,
						streamTargetId: message.streamTargetId,
						state: message.state,
					});
					break;
			case 'reset wsc streamTarget':
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_SIMULCAST_STREAM_TARGET_STATE,
						status_code: message.status_code,
						streamTargetId: message.streamTargetId,
						state: message.state,
					});
					break;
			case 'add streamTarget object':
        this.addSimulcastStreamTargetResponse(message);
				break;
			case 'update streamTarget object':
				this.updateSimulcastStreamTargetResponse(message);
				break;
			default:
			}
		}
		// Handle messages from the encoder
		if (message.status && message.status === 200)
		{
			let state = ClearCasterStore.getState();
			switch(message.command.toLowerCase())
			{
			case 'devicegetstatus':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_ENCODER_STATUS,
					currentLiveEncoderStatus:message,
					timestamp:currentTime,
					pairingKey:pairingKey
				});
				// update currentliveencoder if this is the current live encoder
				break;
			case 'devicegetgraphapiuseraccesstoken':
				ClearCasterStore.dispatch({
					type:ActionTypes.SET_FACEBOOK_ACCESS_TOKEN,
					accessToken:message['userAccessToken'],
					pairingKey:pairingKey
				});
				break;
			case 'devicesetaudiochannelmap':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetcountdownconfig':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetbroadcastinputconfig':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetoverlaygraphicsconfig':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesettalentviewconfig':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetvideoinput':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetencodingsettings':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetvideocapturemaxframerate':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicesetencoderfacebookpublishpathoptimization':
				if (state.currentLiveEncoder !== null)
				{
					this.getDeviceStatus(state.currentLiveEncoder.encoder_uuid || state.currentLiveEncoder.device_id);
				}
				break;
			case 'devicegetlocalversions':
				try {
					let localVersionsStr = atob(message['localVersionsBase64']);
					let localVersionsObj = JSON.parse(localVersionsStr);
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_ENCODER_LOCAL_VERSIONS,
						localVersions:localVersionsObj,
						pairingKey:pairingKey
					});
				}
				catch(e) {
					console.log('ClearCasterAdminController: devicegetlocalversions: ERROR: ' + e.message);
				}
				break;
			case 'devicesetname':
				this.getPairedDeviceStatuses();
				break;
			case 'deviceexportlogdata':
				this.raiseAlert({
					type:'success',
					headline: 'Log data sent',
					message: ''
				});
				break;
			case 'networkgetinfo':
				let adapters = message.networkInfo;
				for (var i2 = 0; i2 < adapters.length; i2++)
				{
					let adapterinfo = message.networkInfo[i2];
					if (adapterinfo.name === 'eth0')
					{
						ClearCasterStore.dispatch({
							type: ActionTypes.SET_CURRENT_LIVE_ENCODER_NETWORK_INFO,
							pairingKey: pairingKey,
							networkinfo:adapterinfo
						});
					}
				}
				break;
			case 'streamgetstats':
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_FACEBOOK_GO_LIVE_BROADCAST_STREAM_STATS,
					pairingKey: pairingKey,
					viewerCount:message.viewerCount,
					commentCount:message.commentCount,
					reactionCounts:message.reactionCounts
				});
				break;

				case "get streamTarget allObjects":
				if (message.objects !== undefined)
				{
					//message.objects
					//var rightNow = new Date();

					let localObjs = message.objects;
					
					/*
					[
						{
							streamTarget: { 
							  id: '654321',
							  type: 'wsc',
							  date: rightNow,
							  name: 'So Cool',
							  data: {
								id: '123456',
								name: 'Charlie Stream',
								type: 'wsc',
								aspect_ratio_width: 1920,
								aspect_ratio_height: 1080,
								protocol: 'rtmp',
								domain_name: 'cloud.wowza.com',
								backup_domain_name: 'cloud-backup.wowza.com',
								port: 1935,
								application_name: 'live',
								stream_name: 'myStream',
								publish_username: 'wowza',
								publish_password: 'wowza',
								source_url: 'rtmp://cloud-backup.wowza.com/live/myStream',
								state: 'started',
								publish_url: 'rtmp://cloud-backup.wowza.com/live/myStream'
								}
							},
							},
							{
								streamTarget: { 
								  id: '56789',
								  type: 'wsc',
								  date: rightNow,
								  name: 'So Cool',
								  data: {
									id: '123456',
									name: 'Charlie Test',
									type: 'wsc',
									aspect_ratio_width: 1920,
									aspect_ratio_height: 1080,
									protocol: 'rtmp',
									domain_name: 'cloud.wowza.com',
									backup_domain_name: 'cloud-backup.wowza.com',
									port: 1935,
									application_name: 'live',
									stream_name: 'myStream',
									publish_username: 'wowza',
									publish_password: 'wowza',
									source_url: 'rtmp://cloud-backup.wowza.com/live/myStream',
									state: 'started',
									publish_url: 'rtmp://cloud-backup.wowza.com/live/myStream'
									}
								},
								}
						];
					*/

					ClearCasterStore.dispatch({
						type: ActionTypes.SET_SIMULCAST_STREAMTARGETS,
						simulcastStreamTargets: localObjs
					});

					/*
					var streamTargetId = 11;
					var broadcastId = '1932305587084412';

					var broadcastToStreamTargetMap = {};
					broadcastToStreamTargetMap[broadcastId] = streamTargetId;
					
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_SIMULCAST_STREAMTARGETTOBROADCASTMAP,
						broadcastToStreamTargetMap: broadcastToStreamTargetMap
					});

					this.getStreamTargetStatus(streamTargetId);
					*/
				}
				break;
			case "get streamTargetToFBBroadcast all":
				this.getSimulcastTargetToBroadcastMapResponse(message);
				break;
			case "add streamTarget object":
				this.addSimulcastStreamTargetResponse(message);
				break;
			case "remove streamTarget object":
				this.removeSimulcastStreamTargetResponse(message);
				break;
			case "add streamTargetToFBBroadcast relationship":
			case "remove streamTargetToFBBroadcast allByFBBroadcast":
				this.updateSimulcastTargetToBroadcastEntryResponse(message);
				break;
			case "remove streamTargetToFBBroadcast allByStreamTargetId":
				this.removeSimulcastStreamTargetRelationshipsResponse(message);
				break;
			case "state wsc streamTarget":
				this.getStreamTargetStatusResponse(message);
				break;
			case "start wsc streamTarget":
				this.startStreamTargetResponse(message);
				break;
			case "stop wsc streamTarget":
				this.stopStreamTargetResponse(message);
				break;
			case "set fbBroadcast value":
				this.setBrokerBroadcastValueResponse(message);
				break;
			case "get fbBroadcast value":
				this.getBrokerBroadcastValueResponse(message);
				break;
			case "remove fbBroadcast value":
				this.removeBrokerBroadcastValueResponse(message);
				break;
			default:
			}
		}
		else if (message.status && message.status === "system log event") 
		{
			let inflate = new pako.Inflate();
			inflate.push(atob(message.systemLogEventBase64), true);
			let bin_data = inflate.result;
			var systemLogEventJSON = "";
			let systemLogEvent;
			for (var i = 0; i < bin_data.length; i++) {
				systemLogEventJSON += String.fromCharCode(bin_data[i]);
			}
			try {
				systemLogEvent = JSON.parse(systemLogEventJSON);
			}
			catch (e) {
				systemLogEvent = {};
			}
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_CURRENT_LIVE_ENCODER_SYSTEM_LOG_EVENT,
				systemLogEvent: systemLogEvent
			})
		}
		else if (message.status && message.command) 
		{
			switch (message.command.toLowerCase()) {
				case 'deviceexportlogdata':
					this.raiseAlert({
						type: 'danger',
						headline: 'Error',
						message: message.description
					});
					break;
				default:
			}
		}
	}
}

export default ClearCasterAdminController
