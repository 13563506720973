import React, { Component } from 'react';
import DialogActivate from './DialogActivate';
import DialogChangeUUID from './DialogChangeUUID';
import DialogConfirm from './DialogConfirm';
import DialogCreateBroadcast from './DialogCreateBroadcast';
import DialogFacebook from './DialogFacebook';
import DialogFacebookUnpair from './DialogFacebookUnpair';
import DialogInfoPaired from './DialogInfoPaired';
import DialogIntelligentMonitoring from './DialogIntelligentMonitoring';
import DialogReboot from './DialogReboot';
import DialogRedundantPreview from './DialogRedundantPreview';
import DialogRollback from './DialogRollback';
import DialogSettingsPaired from './DialogSettingsPaired';
import DialogDeviceInput from './DialogDeviceInput';
import DialogSimulcastAddTarget from './DialogSimulcastAddTarget';
import DialogSimulcastRemoveTarget from './DialogSimulcastRemoveTarget';
import DialogUpdates from './DialogUpdates';

class Dialogs extends Component
{
    render()
    {
        return (
            <span id="dialogs">
                { !this.props.clearcaster.dialogs.showError && !this.props.clearcaster.dialogs.showConfirm && (
                    <span>
                        <DialogActivate clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogActivate>
                        <DialogChangeUUID clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogChangeUUID>
                        <DialogCreateBroadcast clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogCreateBroadcast>
                        <DialogFacebook clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogFacebook>
                        <DialogFacebookUnpair clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogFacebookUnpair>
                        <DialogInfoPaired clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogInfoPaired>
                        <DialogIntelligentMonitoring clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogIntelligentMonitoring>
                        <DialogReboot clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogReboot>
                        <DialogRedundantPreview clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogRedundantPreview>
                        <DialogRollback clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogRollback>
                        <DialogSettingsPaired clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogSettingsPaired>
                        <DialogDeviceInput clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogDeviceInput>
                        <DialogSimulcastAddTarget clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogSimulcastAddTarget>
                        <DialogSimulcastRemoveTarget clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogSimulcastRemoveTarget>
                        <DialogUpdates clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogUpdates>
                    </span>
                )}
                { this.props.clearcaster.dialogs.showConfirm && (
                    <DialogConfirm clearcaster={this.props.clearcaster} controller={this.props.controller} strings={this.props.strings}></DialogConfirm>
                )}
            
            </span>
        );
    }
}
export default Dialogs;