import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import FacebookConnectionState from '../model/FacebookConnectionState';
import { getEncoderFromWowzaPairingKey } from './ClearCasterAdminUtils';
import CheckWithTimeoutPromiseFactory from './CheckWithTimeoutPromiseFactory';

export default function ClearCasterAdminControllerGoLiveMixin (controller)
{
    controller.setFacebookGoLiveForm = (facebookGoLiveForm) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_GO_LIVE_FORM,
            facebookGoLiveForm: facebookGoLiveForm
        });
    };
    controller.getGoLiveInitialStatePromise = (pairingKey) =>
    {
        return new Promise((resolve, reject) =>
        {

            controller.ensureWowzaPairingStatusPromise()
            .then(()=>
            {
                controller.ensureFacebookConnectedPromise()
                .then(()=>
                {
                    controller.getGoLiveStatePromise(pairingKey)
                    .then((resultObj)=>
                    {
                        resolve(resultObj);
                    });
                });
            })
            .catch((errObj)=>
            {
                reject(errObj);
            });
        });
    };
    controller.getGoLiveStatePromise = (pairingKey) =>
    {
        let requestedDeviceStatus = false;
        let requestedBroadcastInfo = false;
        let requestedVideoInfo = false;
        return CheckWithTimeoutPromiseFactory(() =>
        {
            let returnObj = {
                pairingKey:pairingKey,
                device:null,
                broadcast:null,
                video:null
            };
            let state = ClearCasterStore.getState();
            let device = getEncoderFromWowzaPairingKey (pairingKey);
            returnObj['device'] = device;
            if (device == null || typeof device === 'undefined')
            {
                return null;
            }
            if (!device.status)
            {
                if (!requestedDeviceStatus)
                {
                    requestedDeviceStatus = true;
                    controller.getDeviceStatus(device.encoder_uuid);
                }
                return null;
            }
            if (device.status.broadcastId !== null && device.status.broadcastId !== 0)
            {
                for (var i = 0; i < state.facebookLiveBroadcasts.length; i++)
                {
                    if (state.facebookLiveBroadcasts[i].id === device.status.broadcastId)
                    {
                        returnObj['broadcast'] = state.facebookLiveBroadcasts[i];
                        if (state.facebookLiveBroadcasts[i]["video"] && state.facebookLiveBroadcasts[i]["video"]["id"])
                        {
                            if (!requestedVideoInfo)
                            {
                                requestedVideoInfo = true;
                                controller.facebookGetVideoInfo(state.facebookLiveBroadcasts[i]["video"]["id"]);
                            }
                            else if (state.broadcasts.facebookLiveVideosMap[state.facebookLiveBroadcasts[i]["video"]["id"]])
                            {
                                returnObj['video'] = state.broadcasts.facebookLiveVideosMap[state.facebookLiveBroadcasts[i]["video"]["id"]];
                                return returnObj;
                            }
                        }
                        else
                        {
                            return returnObj;
                        }                      
                    }
                }
                if (!requestedBroadcastInfo && state.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED)
                {
                    requestedBroadcastInfo = true;
                    controller.facebookGetBroadcastInfoRequest(device.status.broadcastId);
                }
                return null;
            }
            else
            {
                return returnObj;
            }
        },
        process.env.REACT_APP_REQUEST_TIMEOUT,
        {
			type:'danger',
			headline:'Timeout',
			message:'Timeout getting Go Live state'
		});

    };

    controller.resetGoLivePageState = () =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.RESET_FACEBOOK_GO_LIVE_PAGE_STATE
        });
    };

    controller.resetGoLiveFormState = () =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.RESET_FACEBOOK_GO_LIVE_FORM_STATE
        });
    };

    controller.setGoLiveBroadcast = (broadcast) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_GO_LIVE_BROADCAST,
            broadcast:broadcast
        });
    };
    controller.setGoLiveVideo = (video) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_GO_LIVE_VIDEO,
            video:video
        });
    };

    controller.setGoLivePageState = (pageState) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_GO_LIVE_PAGE_STATE,
            pairingKey:pageState['pairingKey'] || null,
            device:pageState['device'] || null,
            broadcast:pageState['broadcast'] || null,
            video:pageState['video'] || null,
            page:pageState['page'] || null
        });
    };

    controller.setGoLiveCurrentEncoder = (device) =>
    {
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
    }

    controller.clearGoLiveCurrentEncoder = () =>
    {
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
    }

    /*
    controller.getGoLiveDeviceGraphAPIUserAccessTokenFromPairingKey = (pairingKey) =>
	{
		if (pairingKey !== '')
		{
			controller.mqttConnection.sendMessage(getEncoderTopicByPairingId(pairingKey),{
				command:'devicegetgraphapiuseraccesstoken',
				deviceId:0
			});
		}
	}
	controller.getGoLiveDeviceGraphAPIUserAccessTokenFromPairingKeyPromise = (pairingKey) =>
	{
		return new Promise((resolve,reject)=>
		{
			let state = ClearCasterStore.getState();
			let checkAccessToken = (localState) => {
				if (localState.golive.accessTokens && localState.golive.accessTokens[pairingKey] && localState.golive.accessTokens[pairingKey] !== '')
				{
					return {
						accessToken:localState.golive.accessTokens[pairingKey]
					};
				}
				else
				{
					return false;
				}
			};
			let token = checkAccessToken(state);
			if (token)
			{
				resolve({
					accessToken:token
				});
			}
			else
			{
				let resolved = false;
				let storeUpdated = () =>
				{
					let state2 = ClearCasterStore.getState();
					let token2 = checkAccessToken(state2);
					if (token2)
					{
						resolved = true;
						resolve({
							accessToken:token
						});
					}
				};
				let unsubscribeListener = ClearCasterStore.subscribe(storeUpdated);
				let timeout = setTimeout(() => {
					unsubscribeListener();
					if (!resolved)
					{
						reject({
							type:'danger',
							headline: 'Timeout',
							message: 'Timeout getting encoder access token'
						});
					}
				}, 5000);
				controller.getGoLiveDeviceGraphAPIUserAccessTokenFromPairingKey(pairingKey);
			}
		})
	}
    */

}
