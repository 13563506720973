import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import {
	getBroadcastKeyValueRequestTopicByUser,
	getStreamTargetToBroadcastRequestTopicByUser
} from '../transport/MQTTConnection';
import Log from '../log/Log';

export default function ClearCasterAdminControllerBrokerMixin (controller)
{
    controller.setBrokerBroadcastValue = (broadcastId, key, value, version = "1.0") =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"set fbBroadcast value",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId,
				"key": key,
				"value": JSON.stringify(value),
				"version": version
			};

            Log.debug(controller.tag+' setBrokerBroadcastValue:' + JSON.stringify(messageObj));

			controller.mqttConnection.sendMessage(getBroadcastKeyValueRequestTopicByUser(userId), messageObj);
		}
        else
        {
            Log.warn(controller.tag+' setBrokerBroadcastValue:  No paringId');
        }
	}

	controller.setBrokerBroadcastValuePromise = (broadcastId, key, value, version = "1.0") =>
	{

		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"set fbBroadcast value",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId,
				"key": key,
				"value": JSON.stringify(value),
				"version": version
			};

            Log.debug(controller.tag+' setBrokerBroadcastValue:' + JSON.stringify(messageObj));

			return controller.mqttConnection.sendMessagePromise(getBroadcastKeyValueRequestTopicByUser(userId), messageObj);
		}
		else
        {
            Log.warn(controller.tag+' setBrokerBroadcastValue:  No paringId');
        }

		return null;
	}

	controller.setBrokerBroadcastValueResponse = (message) =>
	{
        Log.debug(controller.tag+' setBrokerBroadcastValueResponse:' + JSON.stringify(message));
	}

	controller.removeBrokerBroadcastValue = (broadcastId, key, value) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"remove fbBroadcast value",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId,
				"key": key
			};

            Log.debug(controller.tag+' removeBrokerBroadcastValue:' + JSON.stringify(messageObj));

			controller.mqttConnection.sendMessage(getBroadcastKeyValueRequestTopicByUser(userId), messageObj);
		}
		else
        {
            Log.warn(controller.tag+' removeBrokerBroadcastValue:  No paringId');
        }
	}

	controller.removeBrokerBroadcastValueResponse = (message) =>
	{
        Log.debug(controller.tag+' removeBrokerBroadcastValueResponse:' + JSON.stringify(message));
	}

	controller.getBrokerBroadcastValue = (broadcastId, key) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"get fbBroadcast value",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId,
				"key": key
			};

			var userObj = {
				"fbBroadcast":broadcastId
			}

			controller.mqttConnection.sendMessage(getBroadcastKeyValueRequestTopicByUser(userId), messageObj, userObj);
		}
		else
        {
            Log.warn(controller.tag+' getBrokerBroadcastValue:  No paringId');
		}
	}

	controller.getBrokerBroadcastValuePromise = (broadcastId, key) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"get fbBroadcast value",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId,
				"key": key
			};

			var userObj = {
				"fbBroadcast":broadcastId
			}

			return controller.mqttConnection.sendMessagePromise(getBroadcastKeyValueRequestTopicByUser(userId), messageObj, userObj);
		}
		else
        {
            Log.warn(controller.tag+' getBrokerBroadcastValue:  No paringId');
        }
		return null;
	}
	controller.getBrokerBroadcastToSimulcastRelationshipPromise = (broadcastId) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var messageObj = {
				"subject":"get streamTargetToFBBroadcast allByFBBroadcast",
				"wowza_jwt":state.userWowza.jwt,
				"fbBroadcast":broadcastId
			};

			var userObj = {
				"fbBroadcast":broadcastId
			}

			return controller.mqttConnection.sendMessagePromise(getStreamTargetToBroadcastRequestTopicByUser(userId), messageObj, userObj);
		}
		else
		{
			Log.warn(controller.tag+' getBrokerBroadcastValue:  No paringId');
		}
		return null;
	}
	controller.getBrokerBroadcastValueResponse = (message) =>
	{
		var userObj = {};
		if (message.userObj !== undefined)
			userObj = message.userObj;

		var fbBroadcast = undefined;
		if (userObj.fbBroadcast !== undefined)
		fbBroadcast = userObj.fbBroadcast;

		if (fbBroadcast !== undefined && message.subject !== undefined && message.subject === "get fbBroadcast value" && message.key !== undefined && message.key === "overlayGraphics")
		{
			var overlayGraphicsInfo = {};

			if (message.status !== undefined && message.status === "success" && message.value !== undefined)
				overlayGraphicsInfo = JSON.parse(message.value);

			ClearCasterStore.dispatch({
				type: ActionTypes.SET_BROADCAST_OVERLAYGRAPHICS_INFO,
				fbBroadcast:fbBroadcast,
				overlayGraphicsInfo:overlayGraphicsInfo
			});
		}
	}


}
