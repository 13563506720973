
export function uiClearTextSelection()
{
	if (window['getSelection'])
	{
		if (window['getSelection']()['empty'])
			window['getSelection']()['empty']();
		else if (window['getSelection']()['removeAllRanges'])
			window['getSelection']()['removeAllRanges']();
	}
	else if (document['selection'])
	{
		document['selection']['empty']();
	}
}

export function getParameterByName(name, url)
{
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function formatMSDuration(durationMS)
{
	let seconds = parseInt((durationMS/1000)%60,10),
		minutes = parseInt((durationMS/(1000*60))%60,10),
		hours = parseInt((durationMS/(1000*60*60))%24,10),
		days = parseInt(durationMS/(1000*60*60*24),10),
		returnStr = '';

	let pad2 = (v) => {return v < 10 ? "0" + v : v;};

	if (days > 0)
		returnStr = days + 'd' + pad2(hours) + ':' + pad2(minutes) + ':' + pad2(seconds);
	else if (hours > 0)
		returnStr = pad2(hours) + ':' + pad2(minutes) + ':' + pad2(seconds);
	else if (minutes > 0)
		returnStr = pad2(minutes) + ':' + pad2(seconds);
	else
		returnStr = '00:' + pad2(seconds);

	return returnStr;
}
