import React, { Component } from 'react';
import ClearCasterStore from '../../model/ClearCasterStore';
import { redirectToLogin } from '../../controller/ClearCasterAdminUtils';

/* eslint-disable jsx-a11y/anchor-is-valid */

class UserSessionWarning extends Component
{
  render ()
  {
    let state = ClearCasterStore.getState();
    if (!state.userWowza.showExpiredSession)
    {
      return null;
    }
    return (
      <div className="alert alert-danger">
        {this.props.strings.error.UserSessionExpired} <a onClick={redirectToLogin} style={{cursor:'pointer'}}>{this.props.strings.error.UserSessionSignInAgain}</a>
      </div>
    );
  }
}
export default UserSessionWarning;