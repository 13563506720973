import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import FacebookConnectionState from '../model/FacebookConnectionState';
import { getWowzaPairingKeyFromEncoderId } from './ClearCasterAdminUtils.js';
import Log from '../log/Log';
import AppStrings from '../model/AppStrings';

export default function ClearCasterAdminControllerFacebookMixin (controller)
{
    controller.setFacebookConnectedResponse = (facebookConnectionState) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_FACEBOOK_CONNECTED,
			connected: facebookConnectionState
		});
        if (facebookConnectionState === FacebookConnectionState.CONNECTED )
        {
			controller.facebookUpdateState();
        }
	}

	controller.facebookUpdateState = (pageIdMap = {}) =>
	{
		if (controller.facebookUpdateStateTimer !== null)
		{
			clearTimeout(controller.facebookUpdateStateTimer);
		}
		let state = ClearCasterStore.getState();

		if (state.userFacebook !== undefined && state.userFacebook.pageIdMap !== undefined)
			pageIdMap = state.userFacebook.pageIdMap;

		controller.facebookGetLiveEncoders();
		controller.facebookGetLiveBroadcastsRequest();

		// this.facebookConnection.getAllActiveBroadcasts(pageIdMap);
		// this.facebookConnection.getLiveEncoders();
		// this.getSimulcastStreamTargets();
		// this.getSimulcastTargetToBroadcastMap();

		controller.facebookUpdateStateTimer = setTimeout(()=>{
			controller.facebookUpdateState();
		},process.env.REACT_APP_FACEBOOK_STATUS_INTERVAL)
	}

    // Login

	controller.userFacebookLoginResponse = (response,callback) =>
	{
		controller.facebookConnection.getUserInfo(response.authResponse.accessToken,true)
		.then((userInfo)=>{
			ClearCasterStore.dispatch({
				type: ActionTypes.USER_FACEBOOK_LOGIN,
				userInfo:userInfo
			});
			if (callback)
				callback(userInfo);
		});
	}
	controller.userFacebookLogin = (callback) =>
	{
		let state = ClearCasterStore.getState();
		if (state.settings && state.settings.facebook_scopes)
		{
			controller.facebookConnection.login(state.settings.facebook_scopes)
			.then((response)=>
			{
				controller.userFacebookLoginResponse(response,callback);
			})
			.catch((e)=>
			{
				Log.error('Error logging in to Facebook',e);
				controller.raiseAlert({
					type:'danger',
					headline: AppStrings.app.Error,
					message: 'Error logging in to Facebook'
				});
			});
		}
		else
		{
			Log.error('Error logging in to Facebook: no scopes',null);
			controller.raiseAlert({
				type:'danger',
				headline: AppStrings.app.Error,
				message: 'Error logging in to Facebook'
			});
		}
	}
	controller.setFacebookHasRequiredScopes = (hasRequiredScopes) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_FACEBOOK_HAS_REQUIRED_SCOPES,
			hasRequiredScopes:hasRequiredScopes
		});
	}

    // Logout

	controller.userFacebookLogout = () =>
	{
		controller.facebookConnection.logout()
		.then((response)=>{
			ClearCasterStore.dispatch({
				type: ActionTypes.USER_FACEBOOK_LOGOUT
			});
		});
	}
	controller.userFacebookLogoutReload = (pairfb) =>
	{
		let state = ClearCasterStore.getState();
		ClearCasterStore.dispatch({
			type: ActionTypes.USER_FACEBOOK_RELOAD_ON_LOGOUT,
			facebookReloadOnLogout: true,
			facebookReloadParams: {
				code: state.activateForm.activationCode,
				name: state.activateForm.activateName,
				pairfb: pairfb
			}
		});
		controller.facebookConnection.logout()
		.then((response)=>
		{
			let state2 = ClearCasterStore.getState();
			let path = '/devices?';
			for (var key in state2.facebookReloadParams)
			{
				path = path + key + '=' + state2.facebookReloadParams[key] + '&';
			}
			controller.props.router.push(path);
		});
	}

    // Get Live Encoders

	controller.facebookGetLiveEncoders = () =>
	{
		controller.facebookConnection.getLiveEncoders()
		.then((response)=>
		{
			let liveEncoders = response.liveEncoders === null ? [] : response.liveEncoders;
			let liveEncoderMap = response.liveEncoderMap === null ? {} : response.liveEncoderMap;

			ClearCasterStore.dispatch({
				type: ActionTypes.SET_FACEBOOK_LIVE_ENCODERS,
				liveEncoders:liveEncoders,
				liveEncoderMap:liveEncoderMap
			});
		});
	}

	// Get Live Broadcasts
	// TODO: deleteme

    controller.facebookGetLiveBroadcastsRequest = () =>
    {
        controller.facebookConnection.getLiveBroadcasts();
    }
    controller.facebookGetLiveBroadcastsResponse = (data) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_LIVE_BROADCASTS,
            facebookLiveBroadcasts:data
        });
    }

    // Get Broadcast Info

    controller.facebookGetBroadcastInfoRequest = (broadcastId,accessToken) =>
    {
        controller.facebookConnection.getBroadcastInfo(broadcastId,accessToken);
    }
    controller.facebookGetBroadcastInfoResponse = (data) =>
    {
        if (data.status === 'UNPUBLISHED' || data.status === 'LIVE')
        {
            let state = ClearCasterStore.getState();
            let facebookLiveBroadcasts = state.facebookLiveBroadcasts;

            // update this broadcast in the list, or add it
            let updated = false;
            if (facebookLiveBroadcasts !== null)
        	{
        		for (var key1 in facebookLiveBroadcasts)
        		{
                    let curr = facebookLiveBroadcasts[key1];
                    if (curr.id === data.id)
                    {
                        facebookLiveBroadcasts[key1] = data;
                        updated = true;
                    }
        		}
        	}
            if (!updated)
                facebookLiveBroadcasts.push(data);
			
            ClearCasterStore.dispatch({
                type: ActionTypes.SET_FACEBOOK_LIVE_BROADCASTS,
                facebookLiveBroadcasts:facebookLiveBroadcasts
            });
        }
	}
	controller.facebookGetVideoInfo = (videoId,accessToken) =>
	{
		controller.facebookConnection.getVideoInfo(videoId,accessToken)
		.then((data) => 
		{
			let state = ClearCasterStore.getState();
			let facebookLiveVideosMap = state.broadcasts.facebookLiveVideosMap;
	
			if (data['id'])
			{
				facebookLiveVideosMap[data['id']] = data;
			}
	
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_FACEBOOK_LIVE_VIDEOS_MAP,
				facebookLiveVideosMap:facebookLiveVideosMap
			});

			if (data['content_tags'] && data['content_tags'].length > 0)
			{
				let promises = [];
				for (var i = 0; i < data['content_tags'].length; i++)
				{
					let currObjId = data['content_tags'][i];
					if (!state.facebookSearchObjectsMap[currObjId])
					{
						promises.push(controller.facebookConnection.getSearchObject(currObjId));
					}
				}
				if (promises.length > 0)
				{
					Promise.all(promises)
					.then( (allData) =>
					{
						if(allData && allData.length > 0)
						{
							for (var i=0; i < allData.length; i++)
							{
								let data = allData[i];
								if (data['id'])
								{
									ClearCasterStore.dispatch({
										type: ActionTypes.ADD_FACEBOOK_SEARCH_OBJECT,
										id:data['id'],
										obj:data
									});
								}
							}
						}
					});
				}
			}
		});
	}

    controller.facebookGetBroadcastReactionCountsRequest = (broadcastId,accessToken) =>
    {
        controller.facebookConnection.getBroadcastReactionCounts(broadcastId,accessToken);
    }
    controller.facebookGetBroadcastReactionCountsResponse = (data) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SET_FACEBOOK_GO_LIVE_BROADCAST_STREAM_STATS,
            reactionCounts:data
        });
    }

    // STOP STREAMS

	controller.facebookStreamStopByEncoderIdRequest = (encoderId) =>
	{
		let pairingKey = getWowzaPairingKeyFromEncoderId(encoderId);
		if (pairingKey !== null)
		{
			ClearCasterStore.dispatch({
				pairingKey:pairingKey,
				type: ActionTypes.SET_ENCODER_UPDATING,
				updating:true
			});
		}
		controller.facebookConnection.streamStopByEncoderId(encoderId);
	}
	controller.facebookStreamStopByEncoderIdResponse = (data) =>
	{
        controller.facebookGetLiveBroadcastsRequest();
	}


	controller.facebookStreamStopRequest = (broadcastId,accessToken,callback) =>
	{
        console.log('---> facebookStreamStopRequest:' + broadcastId);
		controller.facebookConnection.streamStop(broadcastId,accessToken,callback);
	}
	controller.facebookStreamStopResponse = (data) =>
	{
        console.log('<--- facebookStreamStopResponse:');
        console.log(data);
		controller.getPairedDeviceStatuses();
        controller.facebookGetLiveBroadcastsRequest();
	}

	// Create and Update Broadcast
	
	controller.facebookEnsureRedundancy = (broadcastId, broadcast, liveEncoders) =>
	{
		let _promise = new Promise(function (resolve,reject)
		{
			if (broadcast['ingest_streams'] === undefined || broadcast['ingest_streams'] === null || broadcast['ingest_streams'].length < liveEncoders.length)
			{
				controller.facebookConnection.createLiveBroadcastAddIngestStream(broadcastId)
				.then(()=>{
					resolve({});
				})
				.catch((e)=>{
					reject(e);
				});
			}
			else
			{
				resolve({});
			}
		});
		return _promise;
	}

	controller.facebookCreateBroadcastRequest = (broadcastInfo,callback) =>
	{
		controller.facebookConnection.createBroadcast(broadcastInfo,callback);
	}
	controller.facebookCreateBroadcastResponse = (data) =>
	{
        console.log('<--- facebookCreateBroadcastResponse');
        console.log(data);
        if (data.id)
        {
            controller.facebookGetBroadcastInfoRequest(data.id);
        }
	}

    controller.facebookUpdateBroadcastRequest = (broadcastId,broadcastInfo,callback) =>
    {
        controller.facebookConnection.updateBroadcast(broadcastId,broadcastInfo,callback);
    }
    controller.facebookUpdateBroadcastResponse = (data) =>
    {
        console.log('<--- facebookUpdateBroadcastResponse');
        console.log(data);
        if (data.id)
        {
            controller.facebookGetBroadcastInfoRequest(data.id);
        }
	}
	
	// Repair

	controller.repairBroadcastEncoder = (broadcastId, encoderId, accessToken = undefined) =>
	{
		controller.facebookConnection.repairBroadcastEncoder(broadcastId, encoderId, accessToken)
		.then((response)=>{
			controller.facebookUpdateState();
		});
	}

	// Search Objects

	controller.getSearchObjectPromise = (objectId) =>
	{
		let _promise = new Promise(function(resolve,reject) 
		{
			let state = ClearCasterStore.getState();
			if (state.facebookSearchObjectsMap[objectId] !== undefined)
			{
				resolve(state.facebookSearchObjectsMap[objectId]);
			}
			else
			{
				controller.facebookConnection.getSearchObjectPromise(objectId)
				.then((obj) => 
				{
					ClearCasterStore.dispatch({
						type: ActionTypes.ADD_FACEBOOK_SEARCH_OBJECT,
						id:objectId,
						obj:obj
					});
					resolve(obj);
				});
			}
		});
		return _promise;
	}
}
