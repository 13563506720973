import React, { Component } from 'react';

class Notifications extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			isLoading: true,
			message: ""
		};
	}

	// TODO: use the store for the notifications
	componentDidMount()
	{
		function sort_dates(a, b) {
			return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
		}		
		var that = this;
		fetch('https://clearcaster.wowza.com/updates/v1/versions.json')
		.then(function(response) {
		if (response.status >= 400) {
		  throw new Error("Bad response from server");
		}
		return response.json();
		})
		.then(function(data) {
			var obj = data.notifications;
			var messageSend ="";
			if (obj){
				obj.sort(sort_dates);
				for(var i = 0; i < obj.length; i++) {
					var curDate = new Date().getTime();
					var item = obj[i];
					if (item.env === window.config.REACT_APP_ENV && (new Date(item.startDate).getTime() <= curDate) && ( item.endDate === "" || new Date(item.endDate).getTime() > curDate)){
						if (messageSend === ""){
							messageSend = item.message;
						}
					}
				}
			}
			if (messageSend !== ""){
				that.setState({
					isLoading: false,
					message: messageSend
				});
			}
		});		
	}
	render()
	{
		if(this.state.isLoading){
	      return(
	      	null
	      )
	    }
	    return(
		<div className="alert alert-warning">{this.state.message}</div>	    	
	    );
	}
}

export default Notifications;
