import React, { Component } from 'react';
import './AudioChannelMapper.css';

class AudioChannelMapper extends Component
{
  constructor(props)
	{
    super(props);
    this.onLeft = this.onLeft.bind(this);
    this.onRight = this.onRight.bind(this);
  }

  onLeft (event)
  {
    if (this.props.onChange)
    {
      this.props.onChange({
        left:parseInt(event.target.value,10),
        right:this.props.audioChannelMap.right
      });
    }
  }

  onRight (event)
  {
    if (this.props.onChange)
    {
      this.props.onChange({
        left:this.props.audioChannelMap.left,
        right:parseInt(event.target.value,10)
      });
    }
  }

  render ()
  {
    let thisId = this.props.id ? this.props.id : "audioChannelMapper";

    if (this.props.updating === undefined || this.props.updating === false)
    {
      return (
        <div className="audioChannelMapper" id={thisId}>
          <div className="form-inline">
            <div className="form-group">
              <label>{this.props.strings.app.AudioChannelMapping}</label>
              <div className="row">
                <div className="input-group col-xs-6">
                  <div className="input-group-addon nostyle">Left</div>
                  <select className="form-control" id={thisId+'_left'} value={this.props.audioChannelMap.left} onChange={this.onLeft}>
                    {this.props.showDefaults &&
                      <option value="-1">Default</option>
                    }
                    <option value="0">Channel 1</option>
                    <option value="1">Channel 2</option>
                    <option value="2">Channel 3</option>
                    <option value="3">Channel 4</option>
                    <option value="4">Channel 5</option>
                    <option value="5">Channel 6</option>
                    <option value="6">Channel 7</option>
                    <option value="7">Channel 8</option>
                  </select>
                </div>
                <div className="input-group col-xs-6">
                  <div className="input-group-addon nostyle">Right</div>
                  <select className="form-control" id={thisId+'_right'} value={this.props.audioChannelMap.right} onChange={this.onRight}>
                    {this.props.showDefaults &&
                      <option value="-1">Default</option>
                    }
                    <option value="0">Channel 1</option>
                    <option value="1">Channel 2</option>
                    <option value="2">Channel 3</option>
                    <option value="3">Channel 4</option>
                    <option value="4">Channel 5</option>
                    <option value="5">Channel 6</option>
                    <option value="6">Channel 7</option>
                    <option value="7">Channel 8</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="audioChannelMapper" id={thisId}>
          <div className="form-group">
            <label>{this.props.strings.app.AudioChannelMapping}</label>
            <div className="row text-muted" style={{marginBottom:'1em'}}>
              <label className="radio-inline">{ this.props.strings.app.UpdatingElipsis }</label>
            </div>
          </div>
        </div>
      );
    }

  }
}
export default AudioChannelMapper;