import React, { Component } from 'react';
import ClearCasterStore, { ActionTypes } from '../../model/ClearCasterStore';
import FacebookConnectionState from '../../model/FacebookConnectionState';

/* eslint-disable jsx-a11y/anchor-is-valid */

class FacebookScopeWarning extends Component
{
  constructor(props)
	{
    super(props);
    this.handleLogInAgain = this.handleLogInAgain.bind(this);
	}

  handleLogInAgain()
  {
    let _this = this;
    this.props.controller.facebookConnection.logout()
		.then((response)=>{
			ClearCasterStore.dispatch({
				type: ActionTypes.USER_FACEBOOK_LOGOUT
      });
      _this.props.controller.userFacebookLogin();
		});
  }

  render ()
  {
    let state = ClearCasterStore.getState();
    if (state.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED && !state.userFacebook.hasRequiredScopes)
    {
      return (
        <div className="alert alert-danger">
          Your Facebook Access Token needs to be updated to receive new permissions. <a onClick={this.handleLogInAgain} style={{cursor:'pointer'}}>Please log in to Facebook again</a>.
        </div>
      );
    } else {
      return null;
    }
  }
}
export default FacebookScopeWarning;