import ClearCasterStore from '../model/ClearCasterStore';

/**
 * checkFunction: returns an object if successful, null if not
 * timeoutMS: time in ms before rejecting promise
 */

let CheckWithTimeoutPromiseFactory = (checkFunction, timeoutMS, errorObj) =>
{
    return new Promise((resolve, reject) => 
    {
        let returnObj = checkFunction();
        if (returnObj)
        {
            resolve(returnObj);
            return;
        }
        let unsubscribeListener = () => {};
        let timeoutPointer = null;
        let storeUpdated = () =>
        {
            let returnObj2 = checkFunction();
            if (returnObj2)
            {
                unsubscribeListener();
                if (timeoutPointer !== null)
                {
                    clearTimeout(timeoutPointer);
                }
                resolve(returnObj2);
            }
        };
        unsubscribeListener = ClearCasterStore.subscribe(storeUpdated);
        timeoutPointer = setTimeout(()=>
        {
            unsubscribeListener();
            reject(errorObj);
        }, timeoutMS);
    
    });
}

export default CheckWithTimeoutPromiseFactory;