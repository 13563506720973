import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import AppStrings from '../model/AppStrings';
import { mixObjects } from './ClearCasterAdminUtils';

export default function ClearCasterAdminControllerDialogsMixin (controller)
{
	// DialogConfirm

	// resolves on confirmation, rejects on cancel
	controller.showDialogConfirmPromise = ( confirmProperties ) =>
	// confirmTitle = '', confirmMessage = '', confirmButtonText = AppStrings.app.Yes, cancelButtonText = AppStrings.app.No ) =>
	{
		confirmProperties = mixObjects({
			title:'',
			message:'',
			confirmText:AppStrings.app.Yes,
			cancelText:AppStrings.app.No,
			showWarning:false
		},confirmProperties);

		return new Promise((resolve, reject) => 
		{
			let unsubscribeListener = () => {};
			let storeUpdated = () =>
			{
				let state = ClearCasterStore.getState();
				if (state.dialogs.confirmResolve)
				{
					unsubscribeListener();
					ClearCasterStore.dispatch({
						type: ActionTypes.DIALOG_CONFIRM_RESOLVE,
						confirmResolve:false
					});
					ClearCasterStore.dispatch({
						type: ActionTypes.HIDE_DIALOG_CONFIRM
					});
					resolve({confirm:true});
				}
				else if (state.dialogs.confirmReject)
				{
					unsubscribeListener();
					ClearCasterStore.dispatch({
						type: ActionTypes.DIALOG_CONFIRM_REJECT,
						confirmReject:false
					});
					ClearCasterStore.dispatch({
						type: ActionTypes.HIDE_DIALOG_CONFIRM
					});
					reject({confirm:false});
				}
			};
			ClearCasterStore.dispatch({
				type: ActionTypes.SHOW_DIALOG_CONFIRM,
				confirmProperties:confirmProperties
			});
			unsubscribeListener = ClearCasterStore.subscribe(storeUpdated);
		});
	}

    // DialogActivate

    controller.showDialogActivate = () =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SHOW_DIALOG_ACTIVATE
        });
    }

    controller.hideDialogActivate = () =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.HIDE_DIALOG_ACTIVATE
        });
	}
		
    // DialogEmbedCode

    controller.showDialogEmbedCode = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_EMBED_CODE
		});
	}

	controller.hideDialogEmbedCode = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_EMBED_CODE
		});
	}

    // DialogFacebook

	controller.showDialogFacebook = (device) =>
	{
		device = device || null;
		if (device !== null)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
				currentLiveEncoder: device
			});
		}
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_FACEBOOK
		});
	}

	controller.hideDialogFacebook = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_FACEBOOK
		});
	}

	controller.hideDialogFacebookPair = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_FACEBOOK
		});
	}

	controller.showDialogFacebookUnpair = (device) =>
	{
		console.log('---- showDialogFacebookUnpair');
		console.log(device);
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_FACEBOOK_UNPAIR
		});
	}

	controller.hideDialogFacebookUnpair = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_FACEBOOK_UNPAIR
		});
	}

    // DialogInfo

    controller.showDialogInfo = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_INFO
		});
	}

	controller.hideDialogInfo = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_INFO
		});
	}

	// DialogIntelligentMonitoring

	controller.showDialogIntelligentMonitoring = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_INTELLIGENTMONITORING
		});
	}

	controller.hideDialogIntelligentMonitoring = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_INTELLIGENTMONITORING
		});
	}


    // DialogReboot

  controller.showDialogReboot = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_REBOOT
		});
	}

	controller.hideDialogReboot = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_REBOOT
		});
	}

	// DialogRedundantPreview

  controller.showDialogRedundantPreview = (encoderId,encoderName,streamUrl,broadcastProperties) =>
	{
		let redundantPreviewData = {
			encoderId:encoderId,
			encoderName:encoderName,
			broadcast:{'dash_preview_url':streamUrl},
			broadcastProperties:broadcastProperties
		};
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_REDUNDANTPREVIEW,
			redundantPreviewData: redundantPreviewData
		});
	}

	controller.hideDialogRedundantPreview = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_REDUNDANTPREVIEW
		});
	}

    // DialogRollback

    controller.showDialogRollback = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_ROLLBACK
		});
	}

	controller.showDialogRollbackCurrentDevice = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_SETTINGS
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_ROLLBACK
		});
	}

	controller.hideDialogRollback = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_ROLLBACK
		});
	}

    // DialogSettings

    controller.showDialogSettings = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_SETTINGS
		});
	}

	controller.hideDialogSettings = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_SETTINGS
		});
	}

	controller.showDialogDeviceInput = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_DEVICEINPUT
		});
	}

	controller.hideDialogDeviceInput = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_DEVICEINPUT
		});
	}

    // DialogUpdates

    controller.showDialogUpdates = (device) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_UPDATES
		});
	}

	controller.showDialogUpdatesCurrentDevice = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_SETTINGS
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_UPDATES
		});
	}

	controller.hideDialogUpdates = () =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: null
		});
		ClearCasterStore.dispatch({
            type: ActionTypes.SET_FORCE_UPDATE_VERSION,
			forceUpdateVersion: ''
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_UPDATES
		});
	}
    
    controller.showForceUpdate = (showForceUpdate) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_FORCE_UPDATE,
			showForceUpdate:showForceUpdate
		});
	}

    controller.forceUpdateToVersion = (device,versionStr) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_CURRENT_LIVE_ENCODER,
			currentLiveEncoder: device
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_FORCE_UPDATE_VERSION,
			forceUpdateVersion:versionStr
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SHOW_DIALOG_UPDATES
		});
	}

	//DialogChangeUUID

  controller.hideDialogChangeUUID = () =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.HIDE_DIALOG_CHANGE_UUID
    });
  }
  controller.showDialogChangeUUID = () =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.SHOW_DIALOG_CHANGE_UUID
    });
  }

  controller.showDialogSimulcastAddTarget = (id) =>
{
  ClearCasterStore.dispatch({
    type: ActionTypes.SHOW_DIALOG_SIMULCAST_ADD_TARGET,
    id: id
  });
}

  controller.hideDialogSimulcastAddTarget = () =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.HIDE_DIALOG_SIMULCAST_ADD_TARGET
    });
  }

  controller.showDialogSimulcastRemoveTarget = (ids) =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.SHOW_DIALOG_SIMULCAST_REMOVE_TARGET,
      ids: ids
    });
  }

  controller.hideDialogSimulcastRemoveTarget = () =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.HIDE_DIALOG_SIMULCAST_REMOVE_TARGET
    });
  }
}
