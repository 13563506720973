import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import FacebookPrivacyLevel from '../model/FacebookPrivacyLevel';
import AppStrings from '../model/AppStrings';

export default function ClearCasterAdminControllerBroadcastsMixin (controller)
{
    controller.broadcasts_stateUpdateTimer = null;
    controller.broadcasts_forceUpdateHaveBroadcasts = false;
    controller.broadcasts_forceUpdateHaveStreamTargetMap = false;

    // Default broadcastInfo
    controller.getDefaultBroadcastInfo = () =>
    {
        return {            
            access_token:undefined,
            contentTags:[],
            createdByClearCaster: undefined,
			description: '',
			destination: undefined,
			embeddable:undefined,
			disturbing:false,
            id: undefined,
            isLive:false,
			isSpherical:undefined,
			liveEncoders: [],
			privacy: FacebookPrivacyLevel.ALL_FRIENDS,
			simulcastingTarget:0,
			status:undefined,
            title: '',
            unpublishAtEnd:false
        }
    }

    // Facebook actions
	controller.switchRedundantStream = (broadcastId, streamId, accessToken = undefined) =>
	{
        // TODO: port this
		controller.facebookConnection.switchRedundantStream(broadcastId, streamId, accessToken);
	}

	controller.repairBroadcastEncoder = (broadcastId, encoderId, accessToken = undefined) =>
	{
		controller.facebookConnection.repairBroadcastEncoder(broadcastId, encoderId, accessToken)
        .then(() => 
        {
            controller.broadcasts_forceStateUpdate();
        });
	}

    controller.createBroadcast = (broadcastId = undefined, createBroadcastInfo) =>
    {
        return new Promise(function(resolve,reject) 
        {
            controller.facebookConnection.createLiveBroadcast(broadcastId, createBroadcastInfo)
            .then((data) => {
                ClearCasterStore.dispatch({
                    type: ActionTypes.SET_BROADCASTS_LIST_RELOAD,
                });
                resolve(data);
            });
        });
    }

	controller.finishBroadcast = (broadcastId) =>
	{
		controller.facebookConnection.finishLiveBroadcast(broadcastId)
        .then(() => 
        {
            controller.broadcasts_forceStateUpdate();
        });
	}

    controller.deleteBroadcast = (broadcastId) =>
	{
        controller.facebookConnection.deleteLiveBroadcast(broadcastId)
        .then(() => 
        {
            controller.broadcasts_forceStateUpdate();
        });
	}

	controller.goLiveBroadcast = (broadcastId) =>
	{
        controller.facebookConnection.goLiveBroadcast(broadcastId)
        .then(() => 
        {
            controller.broadcasts_forceStateUpdate();
        });
    }
    
    // Page state

    controller.broadcasts_clearReloadTimer = () =>
    {
        if (controller.broadcasts_stateUpdateTimer != null)
            clearInterval(controller.broadcasts_stateUpdateTimer);
        controller.broadcasts_stateUpdateTimer = null;
    }

    controller.broadcasts_forceStateUpdate = (pageIdMap = {}) =>
    {
        controller.broadcasts_clearReloadTimer();

        controller.broadcasts_forceUpdateHaveBroadcasts = false;
        controller.broadcasts_forceUpdateHaveStreamTargetMap = false;

        let state = ClearCasterStore.getState();

        if (state.userFacebook !== undefined && state.userFacebook.pageIdMap !== undefined)
            pageIdMap = state.userFacebook.pageIdMap;

        controller.facebookConnection.getAllActiveBroadcasts(pageIdMap)
        .then((response) => 
        {
            ClearCasterStore.dispatch({
                type: ActionTypes.SET_FACEBOOK_ACTIVE_BROADCASTS,
                activeBroadcasts: response['activeBroadcasts'],
                activeBroadcastMap: response['activeBroadcastMap'],
                crosspostedBroadcasts: response['crosspostedBroadcasts']
            });

            controller.broadcasts_forceUpdateHaveBroadcasts = true;

            // if (controller.broadcasts_forceUpdateHaveBroadcasts && controller.broadcasts_forceUpdateHaveStreamTargetMap)
            //     controller.getStreamTargetStatusAll();

            controller.broadcasts_stateUpdateTimer = setTimeout( () => {
                controller.broadcasts_forceStateUpdate();
            },process.env.REACT_APP_STATUS_INTERVAL);
            
        });
        controller.facebookGetLiveEncoders();
        // controller.getSimulcastStreamTargets();
        // controller.getSimulcastTargetToBroadcastMap();
    }

    controller.broadcasts_getVideoInfo = (videoId, accessToken) =>
    {
        controller.facebookGetVideoInfo(videoId, accessToken);
    }

    controller.broadcasts_getBrokerBroadcastInfo = (broadcastId) =>
    {
        let brokerRequests = [];
        let brokerBroadcastInfo = {};

        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"encodingSettings"));
        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"maxFrameRate"));
        if (process.env.REACT_APP_ENABLE_TARGETING === 'true')
        {
            brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"targeting"));
        }
        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"overlayGraphics"));
        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"talentViewBrowserGraphics"));
        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"audioChannelMap"));
        brokerRequests.push(controller.getBrokerBroadcastValuePromise(broadcastId,"extraValues"));
        brokerRequests.push(controller.getBrokerBroadcastToSimulcastRelationshipPromise(broadcastId));

        Promise.all(brokerRequests)
        .then(function(allData) 
        {
            if(allData && allData.length > 0)
            {
                for (var i=0; i < allData.length; i++)
                {
                    let req = allData[i];
                    if (req['key'])
                    {
                        if (req['key'] === 'encodingSettings')
                        {
                            try
                            {
                                let encodingSettingsValue = JSON.parse(req['value']);
                                brokerBroadcastInfo['encodingSettings'] = encodingSettingsValue;
                            }
                            catch(e)
                            {}
                        }
                        if (req['key'] === 'maxFrameRate')
                        {
                            try
                            {
                                let maxFrameRateValue = JSON.parse(req['value']);
                                brokerBroadcastInfo['maxFrameRate'] = maxFrameRateValue;
                            }
                            catch(e)
                            {}
                        }
                        if (process.env.REACT_APP_ENABLE_TARGETING === 'true' && req['key'] === 'targeting')
                        {
                            try
                            {
                                let targetingValue = JSON.parse(req['value']);
                                brokerBroadcastInfo['targeting'] = targetingValue;
                            }
                            catch(e)
                            {}
                        }
                        if (req['key'] === 'overlayGraphics')
                        {
                            try
                            {
                              let overlayGraphicsValue = JSON.parse(req['value']);
                              brokerBroadcastInfo['overlayGraphics'] = overlayGraphicsValue;
                            }
                            catch(e)
                            {
                                brokerBroadcastInfo['overlayGraphics'] = {};
                            }
                        }
                        if (req['key'] === 'talentViewBrowserGraphics')
                        {
                            try
                            {
                                let browserTalentViewValue = JSON.parse(req['value']);
                                brokerBroadcastInfo['browserTalentView'] = browserTalentViewValue;
                            }
                            catch(e)
                            {
                                brokerBroadcastInfo['browserTalentView'] = {};
                            }
                        }
                        if (req['key'] === 'audioChannelMap')
                        {
                            try
                            {
                                let audioChannelMapValue = JSON.parse(req['value']);
                                brokerBroadcastInfo['audioChannelMap'] = audioChannelMapValue;
                            }
                            catch(e)
                            {
                                brokerBroadcastInfo['audioChannelMap'] = {};
                            }
                        }
                        if (req['key'] === 'extraValues')
                        {
                            try
                            {
                                let extraValues = JSON.parse(req['value']);
                                for (var valueKey in extraValues)
                                {
                                    brokerBroadcastInfo[valueKey] = extraValues[valueKey];
                                }
                            }
                            catch(e)
                            {}
                        }
                    }
                    else if (req['data']) //Process BroadcastToSimulcast Relationship Responses.
                    {
                        if (req['data'].length && req['data'].length > 0)
                        {
                            if (req['data'][0].streamTargetId || req['data'][0].streamTargetId === 0) {
                                brokerBroadcastInfo['simulcastingTarget'] = req['data'][0].streamTargetId;
                            }
                        }
                    }
                }
            }
            let brokerBroadcastInfoMap = ClearCasterStore.getState().broadcasts.brokerBroadcastInfoMap;
            brokerBroadcastInfoMap[broadcastId] = brokerBroadcastInfo;
            ClearCasterStore.dispatch({
                type: ActionTypes.SET_BROKER_BROADCAST_INFO_MAP,
                brokerBroadcastInfoMap: brokerBroadcastInfoMap
            });
        },function(response) {
			controller.raiseAlert({
				type:'danger',
				headline: AppStrings.app.Error,
				message: response.error
			});
        });
    }

	// DialogCreateBroadcast

    controller.showDialogCreateBroadcast = (broadcastId = undefined, broadcastInfo = undefined) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.SHOW_DIALOG_CREATE_BROADCAST,
            broadcastId: broadcastId,
            broadcastInfo: broadcastInfo
        });
    }

    controller.hideDialogCreateBroadcast = () =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.HIDE_DIALOG_CREATE_BROADCAST
        });
	}
    
}
