import FacebookDestinationType from './FacebookDestinationType';
import FacebookPrivacyLevel from './FacebookPrivacyLevel';
import FacebookProjectionType from './FacebookProjectionType';
import FacebookStreamType from './FacebookStreamType';

export const GoLiveActionTypes =
{
    SET_FACEBOOK_ACCESS_TOKEN: 'SET_FACEBOOK_ACCESS_TOKEN',
    SET_FACEBOOK_GO_LIVE_BROADCAST: 'SET_FACEBOOK_GO_LIVE_BROADCAST',
    SET_FACEBOOK_GO_LIVE_BROADCAST_STREAM_STATS: 'SET_FACEBOOK_GO_LIVE_BROADCAST_STREAM_STATS',
    SET_FACEBOOK_GO_LIVE_VIDEO: 'SET_FACEBOOK_GO_LIVE_VIDEO',
    SET_FACEBOOK_GO_LIVE_FORM: 'SET_FACEBOOK_GO_LIVE_FORM',
    SET_FACEBOOK_GO_LIVE_PAGE_STATE: 'SET_FACEBOOK_GO_LIVE_PAGE_STATE',
    RESET_FACEBOOK_GO_LIVE_PAGE_STATE: 'RESET_FACEBOOK_GO_LIVE_PAGE_STATE',
    RESET_FACEBOOK_GO_LIVE_FORM_STATE: 'RESET_FACEBOOK_GO_LIVE_FORM_STATE',
    SET_FACEBOOK_GO_LIVE_PAIRING_KEY: 'SET_FACEBOOK_GO_LIVE_PAIRING_KEY'
};

export const GoLiveGender = 
{
    MALE:1,
    FEMALE:2
};

export const initialPageState = {
    initialized:false,
    accordionID:0,
    stopping:false,
    creating:false,
    goinglive:false
};

export const initialFormState = {
    description:"",
    destination:FacebookDestinationType.TIMELINE,
    embeddable:true,
    id:"0",
    is_spherical:false,
    privacy:FacebookPrivacyLevel.ALL_FRIENDS,
    projection:FacebookProjectionType.EQUIRECTANGULAR,
    stream_type:FacebookStreamType.REGULAR,
    targeting:{
        age_min:null,
        age_max:null,
        genders:[],
        geo_locations:{}
    },
    title:""
};

export default function ClearCasterGoLiveReducer (
    state = {
        pairingKey:'',
        accessTokens:{},
        broadcast:null,
        video:null,
        device:null,
        streamStats:{
            viewerCount:-1,
            commentCount:-1,
            reactionCounts:null
        },
        form:{
            description:"",
            destination:FacebookDestinationType.TIMELINE,
            disturbing:false,
            embeddable:false,
            id:"0",
            is_spherical:false,
            privacy:FacebookPrivacyLevel.ALL_FRIENDS,
            projection:FacebookProjectionType.EQUIRECTANGULAR,
            save_vod:true,
            stream_type:FacebookStreamType.REGULAR,
            targeting:{
                age_min:null,
                age_max:null,
                genders:[],
                geo_locations:{}
            },
            title:""
        },
        page:{
            initialized:false,
            accordionID:0,
            stopping:false,
            creating:false,
            goinglive:false
        }
    },
    action )
{
    switch (action.type)
	{
        case GoLiveActionTypes.SET_FACEBOOK_ACCESS_TOKEN:
			state.accessTokens[action.pairingKey] = action.accessToken;
			return state;
        case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_BROADCAST:
			state.broadcast = action.broadcast;
            return state;
        case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_BROADCAST_STREAM_STATS:
			state.streamStats.viewerCount = action.viewerCount;
            state.streamStats.commentCount = action.commentCount;
            state.streamStats.reactionCounts = action.reactionCounts;
            return state;
        case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_VIDEO:
            state.video = action.video;
            return state;
		case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_FORM:
			state.form = action.facebookGoLiveForm;
            return state;
        case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_PAGE_STATE:
            if (action['page'] !== null)
                state.page = action.page;
            if (action['device'] !== null)
                state.device = action.device;
            if (action['broadcast'] !== null)
                state.broadcast = action.broadcast;
            if (action['video'] !== null)
                state.video = action.video;
            if (action['pairingKey'] !== null)
                state.pairingKey = action.pairingKey;
            return state;
        case GoLiveActionTypes.RESET_FACEBOOK_GO_LIVE_PAGE_STATE:
            for(var k1 in initialPageState)
            {
                state.page[k1] = initialPageState[k1];
            }
            return state;
        case GoLiveActionTypes.RESET_FACEBOOK_GO_LIVE_FORM_STATE:
            for(var k2 in initialFormState)
            {
                state.form[k2] = initialFormState[k2];
            }
            return state;
        case GoLiveActionTypes.SET_FACEBOOK_GO_LIVE_PAIRING_KEY:
            state.pairingKey = action.pairingKey;
            return state;
        default:
            return state;
    }
}
