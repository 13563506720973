import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import { getStreamTargetRequestTopicByUser, getStreamTargetToBroadcastRequestTopicByUser } from '../transport/MQTTConnection';
import AppStrings from "../model/AppStrings";

export default function ClearCasterAdminControllerSimulcastMixin (controller)
{
    controller.getUserIdForStreamTargets = () =>
	{
		return ClearCasterStore.getState().userWowza.username;
    }
    
	controller.getPairIdForStreamTargets = () =>
	{
		let state = ClearCasterStore.getState();

		for (var pairingIdKey in state.wowzaPairings.pairings)
		{
			return pairingIdKey;
		}

		return undefined;
	}
    
	controller.addSimulcastStreamTarget = (addTargetInfo) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			if (addTargetInfo.connectionCode !== undefined)
			{
				controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
					"subject":"add streamTarget object",
					"wowza_jwt":state.userWowza.jwt,
					"streamTarget": {
						"type": 'wsc',
						"name": 'noname',
						"data": {
							"connectionCode": addTargetInfo.connectionCode
						}
					}
				});
			}
			else
				console.log("WARNING: addSimulcastStreamTarget: No addTargetInfo.connectionCode");
		}
		else
			console.log("WARNING: addSimulcastStreamTarget: No paringId");
    }

	controller.updateSimulcastStreamTarget = (updateTargetInfo) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			if (updateTargetInfo.id !== undefined)
			{
				controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
					"subject":"update streamTarget object",
					"wowza_jwt":state.userWowza.jwt,
					"streamTarget": {
						"type": 'wsc',
						"name": 'noname',
						"id": updateTargetInfo.id,
						"start_mode": updateTargetInfo.start_mode || 0,
						"data": updateTargetInfo.data || {}
					}
				});
			}
			else
				console.log("WARNING: updateSimulcastStreamTarget: No updateTargetInfo.id");
		}
		else
			console.log("WARNING: updateSimulcastStreamTarget: No paringId");
	}
    
	controller.getStreamTargetStatus = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		console.log("getStreamTargetStatus");

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
				"subject":"state wsc streamTarget",
				"wowza_jwt":state.userWowza.jwt,
				"streamTargetId": streamTargetId
			});
		}
		else
			console.log("WARNING: getStreamTargetStatus: No paringId");
	}

    controller.getStreamTargetStatusResponse = (message) =>
	{
		//console.log("getStreamTargetStatusResponse: "+JSON.stringify(message));

		if (message.state !== undefined && message.streamTargetId !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_SIMULCAST_STREAMTARGETSTATE,
				streamTargetId:message.streamTargetId,
				streamTargetState:message.state
			});

			//console.log("getStreamTargetStatusResponse: streamTargetId:"+message.streamTargetId+" state:"+message.state);

			if (message.state === 'started')
				controller.keepAliveStreamTarget(message.streamTargetId);
		}
    }
    
	controller.keepAliveStreamTarget = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		//console.log("keepAliveStreamTarget: streamTargetId:"+streamTargetId);

		var streamTarget = state.simulcastStreamTargetMap[streamTargetId];
		if (streamTarget !== undefined)
		{
			controller.wscRestAPI.keepAliveStreamTarget(streamTarget);
			//controller.wscRestAPI.getKeepAliveStreamTarget(streamTarget);
		}
	}
    
    controller.getStreamTargetStatusAll = () =>
	{
		let state = ClearCasterStore.getState();

		//console.log("================== getStreamTargetStatusAll");

		for(var broadcastId in state.facebookActiveBroadcastMap)
		{
			if (state.simulcastBroadcastToStreamTargetMap[broadcastId] !== undefined)
			{
				//console.log("================== getStreamTargetStatusAll: "+state.simulcastBroadcastToStreamTargetMap[broadcastId]);
				controller.getStreamTargetStatus(state.simulcastBroadcastToStreamTargetMap[broadcastId]);
			}
		}
    }
    
    controller.startStreamTarget = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
				"subject":"start wsc streamTarget",
				"wowza_jwt":state.userWowza.jwt,
				"streamTargetId": streamTargetId
			});
		}
		else
			console.log("WARNING: startStreamTarget: No paringId");
    }   
    
	controller.startStreamTargetResponse = (message) =>
	{
		if (message.state !== undefined && message.streamTargetId !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_SIMULCAST_STREAMTARGETSTATE,
				streamTargetId:message.streamTargetId,
				streamTargetState:message.state
			});
		}
    }    
    
	controller.stopStreamTarget = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
				"subject":"stop wsc streamTarget",
				"wowza_jwt":state.userWowza.jwt,
				"streamTargetId": streamTargetId
			});
		}
		else
			console.log("WARNING: stopStreamTarget: No paringId");
    }   
    
    controller.stopStreamTargetResponse = (message) =>
	{
		if (message.state !== undefined && message.streamTargetId !== undefined)
		{
			ClearCasterStore.dispatch({
				type: ActionTypes.SET_SIMULCAST_STREAMTARGETSTATE,
				streamTargetId:message.streamTargetId,
				streamTargetState:message.state
			});
		}
    }
    
	controller.addSimulcastStreamTargetResponse = (message) =>
	{
		let alertMessage = undefined;

		if (message.status !== undefined && message.streamTarget !== undefined)
		{
			if (message.status === "success")
			{
        ClearCasterStore.dispatch({
          type: ActionTypes.SET_SIMULCAST_STREAM_TARGET,
          streamTarget: message.streamTarget
        });
				controller.showDialogSimulcastAddTarget(message.streamTarget.id);
			}
			else
			{
				if (message.streamTarget.error !== undefined)
				{
					var msg = message.streamTarget.error;
					let idx = msg.indexOf("{");
					if (idx >= 0)
						msg = msg.substr(0, idx);

					alertMessage =  msg;
				}
				else
					alertMessage = 'Internal Error, Unkown error';
			}
		}
		else
			alertMessage = 'Internal Error, Unkown error';

		if (alertMessage !== undefined)
		{
			let alertObj = {
				type: 'danger',
				headling: AppStrings.app.Error,
				message: alertMessage
			}
			controller.raiseAlert(alertObj);
		}

		controller.getSimulcastStreamTargets();
	}

	controller.updateSimulcastStreamTargetResponse = (message) =>
	{
		let alertMessage = undefined;
		let state = ClearCasterStore.getState();

		if (message.status !== undefined && message.streamTarget !== undefined)
		{
			if (message.status === "success")
			{
				let id=message.streamTarget.id;
				if (state.simulcast.streamTargets[id] && state.simulcast.streamTargets[id].date)
					message.streamTarget.date = state.simulcast.streamTargets[id].date;
				ClearCasterStore.dispatch({
					type: ActionTypes.SET_SIMULCAST_STREAM_TARGET,
					streamTarget: message.streamTarget
				});
			}
			else
			{
				if (message.streamTarget.error !== undefined)
				{
					var msg = message.streamTarget.error;
					let idx = msg.indexOf("{");
					if (idx >= 0)
						msg = msg.substr(0, idx);

					alertMessage =  msg;
				}
				else
					alertMessage = 'Internal Error, Unkown error';
			}
		}
		else
			alertMessage = 'Internal Error, Unkown error';

		if (alertMessage !== undefined)
		{
			let alertObj = {
				type: 'danger',
				headling: AppStrings.app.Error,
				message: alertMessage
			};
			controller.raiseAlert(alertObj);
		}
	};

    controller.removeSimulcastStreamTargetRelationships = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			var userObj = {
				streamTargetId: streamTargetId
			};

			controller.mqttConnection.sendMessage(getStreamTargetToBroadcastRequestTopicByUser(userId),{
				"subject":"remove streamTargetToFBBroadcast allByStreamTargetId",
				"wowza_jwt":state.userWowza.jwt,
				"streamTargetId": streamTargetId
			}, userObj);
		}
		else
			console.log("WARNING: removeSimulcastStreamTargetRelationships: No paringId");
	}

    controller.removeSimulcastStreamTargetRelationshipsResponse = (message) =>
	{
		console.log("removeSimulcastStreamTargetRelationshipsResponse: "+JSON.stringify(message));

		if (message.userObj !== undefined && message.userObj.streamTargetId !== undefined)
			controller.removeSimulcastStreamTarget(message.userObj.streamTargetId);
	}

    controller.removeSimulcastStreamTarget = (streamTargetId) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
				"subject":"remove streamTarget object",
				"wowza_jwt":state.userWowza.jwt,
				"streamTargetId": streamTargetId
			});
		}
		else
			console.log("WARNING: getSimulcastStreamTargets: No paringId");
	}

    controller.removeSimulcastStreamTargetResponse = (message) =>
	{
		console.log("removeSimulcastStreamTargetResponse: "+JSON.stringify(message));
		controller.getSimulcastStreamTargets();
    }
    
    controller.getSimulcastStreamTargets = () =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			controller.mqttConnection.sendMessage(getStreamTargetRequestTopicByUser(userId),{
				"subject":"get streamTarget allObjects",
				"wowza_jwt":state.userWowza.jwt,
			});
		}
		else
			console.log("WARNING: getSimulcastStreamTargets: No paringId");
	}
   
	controller.getSimulcastTargetToBroadcastMap = () =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined)
		{
			//console.log("getSimulcastTargetToBroadcastMap");
			controller.mqttConnection.sendMessage(getStreamTargetToBroadcastRequestTopicByUser(userId),{
				"subject":"get streamTargetToFBBroadcast all",
				"wowza_jwt":state.userWowza.jwt,
			});
		}
		else
			console.log("WARNING: getSimulcastTargetToBroadcastMap: No paringId");
    }
    
	controller.getSimulcastTargetToBroadcastMapResponse = (message) =>
	{
		//let state = ClearCasterStore.getState();

		if (message.data !== undefined)
		{
			let newMap = {};
			for(var i in message.data)
			{
				let broadcastId = message.data[i].fbBroadcast;
				let streamTargetId = message.data[i].streamTargetId;

				newMap[broadcastId] = streamTargetId;
			}

			ClearCasterStore.dispatch({
				type: ActionTypes.SET_SIMULCAST_STREAMTARGETTOBROADCASTMAP,
				broadcastToStreamTargetMap: newMap
			});

			controller.forceUpdateHaveStreamTargetMap = true;

			if (controller.forceUpdateHaveBroadcasts && controller.forceUpdateHaveStreamTargetMap)
                controller.getStreamTargetStatusAll();
		}
    }
    
	controller.updateSimulcastTargetToBroadcastEntry = (streamTargetId, fbBroadcastId, broadcastInfo = undefined) =>
	{
		let state = ClearCasterStore.getState();

		var userId = controller.getUserIdForStreamTargets();
		if (userId !== undefined) {
			if (broadcastInfo !== undefined && streamTargetId !== undefined && Number(streamTargetId) !== 0) {
				controller.mqttConnection.sendMessage(getStreamTargetToBroadcastRequestTopicByUser(userId), {
					"subject": "set streamTargetToFBBroadcast relationship",
					"wowza_jwt": state.userWowza.jwt,
					"fbBroadcast": fbBroadcastId,
					"streamTargetIds": [streamTargetId]
				});
			} else {
				controller.mqttConnection.sendMessage(getStreamTargetToBroadcastRequestTopicByUser(userId), {
					"subject": "remove streamTargetToFBBroadcast allByFBBroadcast",
					"wowza_jwt": state.userWowza.jwt,
					"fbBroadcast": fbBroadcastId
				});
			}
		} else
			console.log("WARNING: updateSimulcastTargetToBroadcastEntry: No paringId");
		controller.broadcasts_forceStateUpdate();
	}

	controller.updateSimulcastTargetToBroadcastEntryResponse = (message) =>
	{
	}


}