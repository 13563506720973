import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Dropdown } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import './WZHeader.css';
import { logout, redirectToLogin } from '../../controller/ClearCasterAdminUtils';
import FacebookConnectionState from '../../model/FacebookConnectionState';
//import { eventNames } from 'cluster';


class WZHeader extends Component
{
	constructor(props)
	{
		super(props);
		this.support = this.support.bind(this);
		this.signinWowzaUser = this.signinWowzaUser.bind(this);
		this.signinFacebookUser = this.signinFacebookUser.bind(this);
		this.signoutWowzaUser = this.signoutWowzaUser.bind(this);
		this.signoutFacebookUser = this.signoutFacebookUser.bind(this);
		this.renderSupportTools = this.renderSupportTools.bind(this);
	}
	support()
	{

	}
	signinWowzaUser()
	{
		redirectToLogin();
	}
	signinFacebookUser()
	{
		this.props.controller.userFacebookLogin();
	}
	signoutWowzaUser()
	{
		this.props.controller.showDialogConfirmPromise({
			title:this.props.strings.nav.SignOutWowzaPrompt,
			message:"",
			confirmText:this.props.strings.app.OK,
			cancelText:this.props.strings.app.Cancel
		})
			.then(()=>{
				logout();
			}, ()=>{
				// rejected
			});
	}
	signoutFacebookUser()
	{
		let _this = this;
		this.props.controller.showDialogConfirmPromise({
			title:this.props.strings.nav.SignOutFacebookPrompt,
			message:"",
			confirmText:this.props.strings.app.OK,
			cancelText:this.props.strings.app.Cancel
		})
			.then(()=>{
				_this.props.controller.userFacebookLogout();
			}, ()=>{
				// rejected
			});
	}
	renderUserPicture()
	{
		return (
			<i className='fa fa-user fa-lg'></i>
		);
	}
	renderSupportTools()
	{
		if (this.props.clearcaster.userWowza && this.props.clearcaster.userWowza.super) {
			return (
				<LinkContainer to="/support_tools">
					<NavItem eventKey={4} id="header-support-tools-link">
						<i className='fa fa-wrench fa-lg'></i>
						<span className='hidden-sm' id="header-support-tools-link">
							{this.props.strings.nav.supportTools}
						</span>
					</NavItem>
				</LinkContainer>
			);
		} else
		{
			return('');
		}
	}

	render()
	{
		const strings = this.props.strings;

		return (
			<Navbar inverse collapseOnSelect className="wzHeader">
				{
					/*
					(this.props.clearcaster.showUniversalInHeader)?
					<a href={ window.config.REACT_APP_UNIVERSAL_URL }>
						<Navbar.Header >
							<Navbar.Brand >
							<img src="/images/wowza-logo-facebook-230x50.png" alt="Wowza ClearCaster Universal" />
							</Navbar.Brand>
						</Navbar.Header>
					</a>
					:
					<Link to="/" id="header-home-link">
						<Navbar.Header>
							<Navbar.Brand>
								{

								}
								<img src="/images/wowza-logo-facebook-only-230x50.png" alt="Wowza ClearCaster" />
							</Navbar.Brand>
							<Navbar.Toggle id="navbar-toggle" />
						</Navbar.Header>
					</Link>
					*/
				}
				<div style={{position:'relative',width:'234px'}}>
					<Navbar.Brand href="/">
						<img src="/images/clearcaster-logo-header-facebook.png" className="wzbrand-image" alt="Wowza ClearCaster" />
					</Navbar.Brand>
					<div style={{position:'absolute',right:'-10px',top:'15px'}}>
						<Dropdown pullRight={true} className="toggle-ui">
							<Dropdown.Toggle bsStyle="link"></Dropdown.Toggle>
							<Dropdown.Menu>
								<MenuItem id="toggle-ui-universal"  href={ window.config.REACT_APP_UNIVERSAL_URL }>UNIVERSAL MANAGER</MenuItem>
								<MenuItem id="toggle-ui-fb" href="/">FACEBOOK MANAGER</MenuItem>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

				<Navbar.Collapse>
					<Nav>
						<IndexLinkContainer to="/broadcasts" eventKey={1}  activeClassName="active">
							<NavItem id="wzheader-menu-broadcasts" className="wzheader-menu-broadcasts">
								<i className="fa fa-video-camera"></i>
								<span className='hidden-sm'>
									{strings.nav.Broadcasts}
								</span>
							</NavItem>
						</IndexLinkContainer>
						<IndexLinkContainer to="/clearcasters" eventKey={2} activeClassName="active">
							<NavItem className="wzheader-menu-clearcasters">
								<i className="fa fa-server"></i>
								<span className='hidden-sm'>
									{strings.nav.ClearCasters}
								</span>
							</NavItem>
						</IndexLinkContainer>
					</Nav>
					<Nav pullRight>


						<NavDropdown eventKey={4} id="wzheader-menu-broadcasts" className="wzheader-menu-broadcasts" title={ 
							<span>
								<i className="fa fa-user"></i>
								<span className='hidden-sm'>
									{strings.nav.Account}
								</span>
							</span>
						}>
							{ this.props.clearcaster.userWowza.jwt !== '' &&
							<MenuItem eventKey="4.1" onClick={ this.signoutWowzaUser } >
								<img alt="" title="" src="/images/wowza-icon-company.png" style={{height:'1.2em',width:'1.2em',marginRight:'0.5em'}}></img>{this.props.clearcaster.userWowza.username} ({this.props.strings.nav.signOut})
							</MenuItem>
							}
							{ this.props.clearcaster.userWowza.jwt === '' &&
								<MenuItem eventKey="4.1" onClick={ this.signinWowzaUser } >
									<img alt="" title="" src="/images/wowza-icon-company.png" style={{height:'1.2em',width:'1.2em',marginRight:'0.5em'}}></img>{this.props.strings.nav.signIn}
								</MenuItem>
							}
							{ this.props.clearcaster.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED && (
								<MenuItem divider />
							)}
							{ this.props.clearcaster.userFacebook.facebookConnected === FacebookConnectionState.CONNECTED && (
								<MenuItem eventKey="4.2" onClick={ this.signoutFacebookUser } >
									<i className="fa fa-facebook-square fa-lg"></i>{this.props.clearcaster.userFacebook.name} ({this.props.strings.nav.signOut})
								</MenuItem>
							)}
							{ this.props.clearcaster.userFacebook.facebookConnected === FacebookConnectionState.NOT_CONNECTED && (
								<MenuItem eventKey="4.3" onClick={ this.signinFacebookUser } >
									<i className="fa fa-facebook-square fa-lg"></i>{this.props.strings.nav.SignInToFacebook}
								</MenuItem>
							)}
							<MenuItem divider />
							<MenuItem href="https://store.wowza.com/portal/account?tab=support"><i className='fa fa-external-link fa-lg'></i>{this.props.strings.nav.ManageSupportContacts}</MenuItem>
						</NavDropdown>

						{this.renderSupportTools()}
						<NavItem eventKey={3} onClick={ this.signoutWowzaUser } id="header-signout-link">
							<i className='fa fa-sign-out'></i>
							<span className='hidden-sm'>
								{strings.nav.signOut}
							</span>
						</NavItem>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default WZHeader;
