import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import ClearCasterPersonality from '../../model/ClearCasterPersonality';

class DialogActivate extends Component
{

	constructor(props)
	{
		super(props);
		this.onActivateNameChange = this.onActivateNameChange.bind(this);
		this.onAcceptedTOSChange = this.onAcceptedTOSChange.bind(this);
		this.onPersonalityChange = this.onPersonalityChange.bind(this);
		this.close = this.close.bind(this);
		this.activate = this.activate.bind(this);
	}

	onActivateNameChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.activateName = event.target.value;
		this.props.controller.setActivateForm(activateForm);
	}
	onAcceptedTOSChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.acceptedTOS = event.target.checked;
		activateForm.tosError = !event.target.checked;
		this.props.controller.setActivateForm(activateForm);
	}
	onPersonalityChange(event)
	{
		let activateForm = this.props.clearcaster.activateForm;
		activateForm.personality = event.target.value;
		this.props.controller.setActivateForm(activateForm);
	}

	close(event)
	{
		this.props.controller.hideDialogActivate();
		if (event !== null)
			event.preventDefault();
	}

	activate(event)
	{
		if (!this.props.clearcaster.activateForm.acceptedTOS)
		{
			let activateForm = this.props.clearcaster.activateForm;
			activateForm.tosError = true;
			this.props.controller.setActivateForm(activateForm);
		}
		else
		{
			this.props.controller.hideDialogActivate();
			let activateForm = this.props.clearcaster.activateForm;
			activateForm.acceptedTOS = true;
			activateForm.tosError = false;
			this.props.controller.setActivateForm(activateForm);
			if (activateForm.personality === ClearCasterPersonality.FACEBOOK)
			{
				this.props.controller.showDialogFacebook();
			}
			else
			{
				this.props.controller.activateDevice(false);
			}
		}
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		let tosMessage = this.props.strings.app.TOSMessage;
		tosMessage = tosMessage.replace('{tos-link}','<a href="https://www.wowza.com/legal/license-agreements" target="_blank">');
		tosMessage = tosMessage.replace('{privacy-link}','<a href="https://www.wowza.com/legal/privacy" target="_blank">');
		tosMessage = tosMessage.replace(/\{end-link\}/g,'</a>');

		let tosClassName = this.props.clearcaster.activateForm.tosError ? "has-error" : "";

		return (
			<Modal show={this.props.clearcaster.dialogs.showActivate} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.strings.app.ActivateTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<label style={{width:'100%'}} htmlFor="dialog-activate-name-input">
								{this.props.strings.app.NameThisClearCaster}
							</label>
							<input id="dialog-activate-name-input" className="form-control" type="text" value={this.props.clearcaster.activateForm.activateName} onChange={this.onActivateNameChange} />
						</div>
						<div className="form-group">
							<label style={{width: '100%'}} htmlFor="dialog-activate-personality">
								{this.props.strings.app.UseThisClearCasterWith}&nbsp;<span title={this.props.strings.app.WorkplaceHelpText}><i className="fa fa-question-circle" style={{color:'#428bca',cursor:'pointer'}}></i></span>
							</label>
							<select className="form-control" id="dialog-activate-personality" value={this.props.clearcaster.activateForm.personality} onChange={this.onPersonalityChange}>
								{Object.keys(ClearCasterPersonality).map((personality,keyIndex) =>
									<option key={keyIndex} value={ClearCasterPersonality[personality]}>{this.props.strings.app['ClearCasterPersonality_'+personality]}</option>
								)}
							</select>
						</div>
						<div className="form-group">
							<div className={tosClassName}>
								{ this.props.clearcaster.activateForm.tosError &&
									<div id="dialog-activate-tos-error" className="control-label"><br /><strong>{ this.props.strings.app.TOSErrorMessage }</strong></div>
								}
								<div className="checkbox">
									<label className="control-label">
										<input id="dialog-activate-tos-checkbox" type="checkbox" defaultChecked={this.props.clearcaster.activateForm.acceptedTOS} ref={(tos) => this.tos = tos} onChange={this.onAcceptedTOSChange} />
										<span dangerouslySetInnerHTML={{ __html: tosMessage }}></span>
									</label>
								</div>
							</div>
						</div>
						<div className="form-group">
							<button id="dialog-activate-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.strings.app.Cancel }</button>&nbsp;
							<button id="dialog-activate-activate-button" className="btn btn-primary" onClick={this.activate}>{ this.props.strings.app.Activate }</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogActivate;
