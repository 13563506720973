import mqtt from 'mqtt';
import Messenger from './Messenger';
import Log from '../log/Log';

const uuidv4 = require('uuid/v4');

class CommandHolder
{
	constructor(topic,commandObj)
	{
		this.topic = topic;
		this.commandObj = commandObj;
		this.sent = false;
	}
}

export function getUserStatusTopic(username)
{
	return 'user/'+username+'/status';
}
export function getUserChangeUUIDTopic(username)
{
  return 'user/'+username+'/encoder_change_uuid/request';
}
export function getEncoderInfoTopic(username)
{
  return 'user/'+username+'/encoder_info/request';
}
export function getEncoderInfoRespTopic(username)
{
  return 'user/'+username+'/encoder_info/response';
}
export function getEncoderChangeUUIDRespTopic(username)
{
  return 'user/'+username+'/encoder_change_uuid/response';
}
export function getCreatePairTopic(username)
{
	return 'user/'+username+'/create_pair';
}
export function getAllUserTopics(username)
{
	return 'user/'+username+'/+';
}
export function getEncoderTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/encoder';
}
export function getClientTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/client';
}
export function getUpdatePairTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/update_pair';
}
export function getBroadcastInfoPairTopicByPairingId(pairingId)
{
  return 'pair/'+pairingId+'/broadcast_info';
}
export function getEncoderTopicByUUID(uuid)
{
	return 'encoder/v1-'+uuid+'/encoder';
}
export function getClientTopicByUUID(uuid)
{
	return 'encoder/v1-'+uuid+'/client';
}
export function getStreamTargetRequestTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/stream_target/request';
}
export function getStreamTargetResponseTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/stream_target/response';
}
export function getStreamTargetRequestTopicByUser(username)
{
	return 'user/'+username+'/stream_target/request';
}
export function getStreamTargetResponseTopicByUser(username)
{
	return 'user/'+username+'/stream_target/response';
}

export function getStreamTargetToBroadcastRequestTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/streamtarget_to_fbbroadcast/request';
}
export function getStreamTargetToBroadcastResponseTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/streamtarget_to_fbbroadcast/response';
}
export function getStreamTargetToBroadcastRequestTopicByUser(username)
{
	return 'user/'+username+'/streamtarget_to_fbbroadcast/request';
}
export function getStreamTargetToBroadcastResponseTopicByUser(username)
{
	return 'user/'+username+'/streamtarget_to_fbbroadcast/response';
}

export function getBroadcastKeyValueRequestTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/fb_broadcast/request';
}
export function getBroadcastKeyValueResponseTopicByPairingId(pairingId)
{
	return 'pair/'+pairingId+'/fb_broadcast/response';
}
export function getBroadcastKeyValueRequestTopicByUser(username)
{
	return 'user/'+username+'/fb_broadcast/request';
}
export function getBroadcastKeyValueResponseTopicByUser(username)
{
	return 'user/'+username+'/fb_broadcast/response';
}


class MQTTConnection extends Messenger
{
	constructor()
	{
		super();
		this.tag = 'MQTTConnection';
		this.commandIndex = 0;
		this.commandsPending = [];
		this.userObjMap = {};
		this.promiseMap = {};

		this.user = null;
		this.clientID = 'user' + Math.random().toString(16).substr(2, 8);

		this.mqttClient = null;

		this.encoderTopic = '';
		this.clientTopic = '';
		//this.nodeAuthTopic = 'node/'+process.env.REACT_APP_MQTTD_PROPS.nodeID+'/auth_add';
		//this.authAckTopic = '';

		this.openConnection = this.openConnection.bind(this);
		this.openConnectionWithPairingID = this.openConnectionWithPairingID.bind(this);
		this.closeConnection = this.closeConnection.bind(this);
		this.isConnected = this.isConnected.bind(this);
		this.authenticateNode = this.authenticateNode.bind(this);
		this.sendUnsentMessages = this.sendUnsentMessages.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.sendMessagePromise = this.sendMessagePromise.bind(this);
		this.onMessage = this.onMessage.bind(this);
	}

	openConnection(user, password)
	{
		let _this = this;

		Log.info(this.tag+' connecting:' + this.clientID);

		this.mqttClient = mqtt.connect(
			window.config.REACT_APP_MQTTD_URL,
			{
				username:user,
				password:password,
				clientId:this.clientID,
				keepalive: 30
			}
		);

		this.mqttClient.on('connect', function ()
		{
			Log.info(_this.tag+' connected');
			_this.connected = true;

			// ***
			// _this.mqttClient.subscribe(getAllUserTopics(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getUserStatusTopic(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getCreatePairTopic(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getEncoderInfoRespTopic(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getEncoderChangeUUIDRespTopic(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getBroadcastKeyValueResponseTopicByUser(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
      _this.mqttClient.subscribe(getStreamTargetResponseTopicByUser(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			_this.mqttClient.subscribe(getStreamTargetToBroadcastResponseTopicByUser(user),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});

			_this.sendUnsentMessages();
		});
		this.mqttClient.on('message', this.onMessage);
		this.mqttClient.on('error', function (resultObj)
		{
			Log.error('ERROR CONNECTING TO MQTTD',resultObj);
			_this.onMessage('ERROR', '{"connectionError":"ERROR CONNECTING TO MQTTD"}');
		});
	}

	openConnectionWithPairingID(pairingID)
	{
		Log.info(this.tag+' openConnectionWithPairingID:' + pairingID);
		if (this.isConnected() && pairingID !== '')
		{
			Log.info(this.tag+' openConnectionWithPairingID: connected, subscribing to:' + getClientTopicByPairingId(pairingID));
			this.mqttClient.subscribe(getClientTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			this.mqttClient.subscribe(getUpdatePairTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			this.mqttClient.subscribe(getBroadcastInfoPairTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			this.mqttClient.subscribe(getStreamTargetResponseTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			this.mqttClient.subscribe(getStreamTargetToBroadcastResponseTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
			this.mqttClient.subscribe(getBroadcastKeyValueResponseTopicByPairingId(pairingID),{qos:parseInt(process.env.REACT_APP_MQTTD_QOS,10)});
		}
	}

	closeConnection(force)
	{
		force = force !== undefined ? force : false;
		Log.info(this.tag+' closeConnection');
		if (force || (this.mqttClient !== null && this.mqttClient.connected))
		{
			this.mqttClient.end();
		}
	}

	isConnected()
	{
		return (this.mqttClient !== null && this.mqttClient.connected);
	}

	authenticateNode()
	{

	}

	sendUnsentMessages()
	{
		if (this.mqttClient != null && this.mqttClient.connected)
		{
			for( var i = 0; i < this.commandsPending.length; i++)
			{
				let currentCommand = this.commandsPending[i];
				if (!currentCommand.sent)
				{
					Log.debug(this.tag+' sending on topic: '+ currentCommand.topic);
					Log.debug(this.tag+' '+JSON.stringify(currentCommand.commandObj));
					this.mqttClient.publish(currentCommand.topic,JSON.stringify(currentCommand.commandObj));
					currentCommand.sent = true;
				}
			}
			return true;
		}
		return false;
	}

	getNextCommandIndex()
	{
		return this.commandIndex;
	}

	sendMessage(topic, messageObj, userObj = undefined)
	{
		let uniqueId = uuidv4();

		messageObj['index'] = this.commandIndex++;
		messageObj['correlationid'] = uniqueId;

		if (userObj !== undefined)
		{
			this.userObjMap[uniqueId] = userObj;
		}

		this.commandsPending.push(new CommandHolder(topic,messageObj));
		
		return this.sendUnsentMessages();
	}

	sendMessagePromise(topic, messageObj, userObj = undefined)
	{
		let uniqueId = uuidv4();
		let _this = this;

		messageObj['index'] = this.commandIndex++;
		messageObj['correlationid'] = uniqueId;

		if (userObj !== undefined)
			this.userObjMap[uniqueId] = userObj;
		
		_this.commandsPending.push(new CommandHolder(topic, messageObj));

		let _promise = new Promise(function(resolve, reject) {

			_this.promiseMap[uniqueId] = resolve;

			_this.sendUnsentMessages();

		});

		return _promise;
	}

	onMessage(topic, message)
	{
		Log.debug(this.tag+' onMessage: topic:'+ topic + ', message: ' + message);
		let pairingKey = '';
		if (topic.indexOf("pair/") === 0 && topic.indexOf("/client") > 0)
		{
			pairingKey = topic.substring(5,topic.indexOf("/client"));
		}

		let messageObj = undefined;
		try 
		{
			messageObj = JSON.parse(message);
		}
		catch(e)
		{
			messageObj = undefined;
		}

		if (messageObj !== undefined)
		{
			if (messageObj.correlationid !== undefined && this.userObjMap[messageObj.correlationid] !== undefined)
			{
				messageObj['userObj'] = this.userObjMap[messageObj.correlationid];
				delete this.userObjMap[messageObj.correlationid];
			}

			let promiseResolve = undefined;
			if (messageObj.correlationid !== undefined && this.promiseMap[messageObj.correlationid] !== undefined)
			{
				promiseResolve = this.promiseMap[messageObj.correlationid];
				delete this.promiseMap[messageObj.correlationid];
			}
			
			if (promiseResolve !== undefined)
			{
				promiseResolve(messageObj);
			}
			else
			{
				this.sendMessageToListeners(messageObj, pairingKey);
			}
		}
		else
		{
			let messageObj = {
				messageFormatError: "Message format error"
			}
			this.sendMessageToListeners(messageObj, pairingKey);
		}

	}

}

export default MQTTConnection;
