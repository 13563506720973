import { Component } from 'react';

class Hotjar extends Component
{
	constructor(props)
	{
    super(props);
    if (window.config.REACT_APP_ENABLE_HOTJAR && window.config.REACT_APP_ENABLE_HOTJAR === 'true')
    {
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:353995,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,window.document,'https://static.hotjar.com/c/hotjar-','.js?sv=|https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
  }
  render()
  {
    return null;
  }
}
export default Hotjar;