import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';

const uuidv4 = require('uuid/v4');

export default function ClearCasterAdminControllerAlertsMixin (controller)
{
    controller.raiseAlert = (alertObj) =>
    {
        alertObj['id'] = uuidv4();
        ClearCasterStore.dispatch({
            type: ActionTypes.RAISE_ALERT,
            alert:alertObj
        });
    }
    controller.dismissAlert = (id) =>
    {
        ClearCasterStore.dispatch({
            type: ActionTypes.DISMISS_ALERT,
            id:id
        });
    }
}
