import React, { Component } from 'react';

import './Loading.css';

class Loading extends Component
{
    render() {
        return (
            <div className="loading"><img src="/images/loading-dots-21x23.gif" alt=""></img></div>
        );
    }
}
export default Loading;