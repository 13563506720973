import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ClearCasterStore ,{ ActionTypes } from '../../model/ClearCasterStore';

class DialogConfirm extends Component
{

	constructor(props)
	{
		super(props);
		this.close = this.close.bind(this);
		this.ok = this.ok.bind(this);
	}

	close(event)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.DIALOG_CONFIRM_REJECT,
			confirmReject:true
		});
		if (event !== null) 
			event.preventDefault();
	}

	ok(event)
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.DIALOG_CONFIRM_RESOLVE,
			confirmResolve:true
		});
		if (event !== null) 
			event.preventDefault();
	}

	render()
	{

		return (
			<Modal show={this.props.clearcaster.dialogs.showConfirm} onHide={this.close}>
				{ this.props.clearcaster.dialogs.confirmTitle !== '' && (
				<Modal.Header>
					<Modal.Title>{ this.props.clearcaster.dialogs.confirmTitle }</Modal.Title>
				</Modal.Header>
				)}
				<Modal.Body>
					<form>
						<div className="row">
							{ this.props.clearcaster.dialogs.confirmShowWarning && (
							<div className="col-xs-2">
								<i className="fa fa-exclamation-triangle fa-5x icon-warning" style={{marginTop:'10px'}}></i>
							</div>
							)}
							<div className="col-xs-10">
								{ this.props.clearcaster.dialogs.confirmMessage !== '' && (
									<div className="form-group">
										{this.props.clearcaster.dialogs.confirmMessage}
										<br />
									</div>
								)}
								<button id="dialog-activate-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.clearcaster.dialogs.confirmCancelText }</button>&nbsp;
								<button id="dialog-activate-activate-button" className="btn btn-primary" onClick={this.ok}>{ this.props.clearcaster.dialogs.confirmConfirmText }</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogConfirm;
