import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import BroadcastPreview from '../broadcast/BroadcastPreview';

class DialogRedundantPreview extends Component
{

	constructor(props)
	{
    super(props);
    this.state = {
      resetPreviewTimeout:null,
      resetPreview:false
    }
    this.close = this.close.bind(this);
    this.resetPreview = this.resetPreview.bind(this);
    this.onResetPreview = this.onResetPreview.bind(this);
  }

  componentWillUnmount()
  {
    if (this.state.resetPreviewTimeout)
      clearTimeout(this.state.resetPreviewTimeout);
  }

	close(event)
	{
		this.props.controller.hideDialogRedundantPreview();
		if (event !== null)
			event.preventDefault();
  }
  
  resetPreview(event)
  {
    if (this.state.resetPreviewTimeout !== null)
      clearTimeout(this.state.resetPreviewTimeout);
    
    let _this = this;
    let newResetPreviewTimeout = setTimeout(function () {
      _this.setState({resetPreview:false});
    },1000);
    this.setState({resetPreviewTimeout:newResetPreviewTimeout,resetPreview:true});

    if (event !== null)
      event.preventDefault();
  }

  onResetPreview(event)
  {
    this.setState({resetPreview:false});

    if (event !== null)
      event.preventDefault();
  }

  render()
	{
    if (!this.props.clearcaster.dialogs.redundantPreviewData)
      return null;
    
		return (
			<Modal show={this.props.clearcaster.dialogs.showRedundantPreview} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.clearcaster.dialogs.redundantPreviewData.encoderName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
          { this.state.resetPreview &&
              <div className="preview-container">
                  <div className="preview-rectangle">
                      <div className="preview-inner"></div>
                  </div>
              </div>
          }
          { this.props.clearcaster.dialogs.showRedundantPreview && !this.state.resetPreview &&
            <div>
              <BroadcastPreview 
                strings={ this.props.strings } 
                broadcast={ this.props.clearcaster.dialogs.redundantPreviewData.broadcast } 
                broadcastProperties={ this.props.clearcaster.dialogs.redundantPreviewData.broadcastProperties}
                onReset={ this.resetPreview }
                />
              <span className="status label label-warning" style={{position:'absolute',top:'2em',left:'2em'}}>PREVIEW</span>
            </div>
          }
				</Modal.Body>
        <Modal.Footer>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginRight:0}}>
								<button id="dialog-redundant-preview-close-button" className="btn btn-primary" onClick={this.close}>{ this.props.strings.app.Close }</button>
							</div>
						</div>
					</Modal.Footer>
			</Modal>
    );  
  }
}
export default DialogRedundantPreview;