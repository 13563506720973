import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class DialogReboot extends Component
{

	constructor(props)
	{
		super(props);
		this.close = this.close.bind(this);
		this.reboot = this.reboot.bind(this);
	}

	close(event)
	{
		this.props.controller.hideDialogReboot();
		if (event !== null)
			event.preventDefault();
	}

	reboot(event)
	{
		console.log('--- reboot');
		//this.props.controller.rebootRequest();
		if (event !== null)
			event.preventDefault();
	}

	render()
	{
		return (
			<Modal show={this.props.clearcaster.dialogs.showReboot} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>Reboot</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							Warning<br />
							Rebooting your ClearCaster will stop all videos from streaming. Rebooting takes about two minutes.<br />
							<br />
							<button id="dialog-reboot-cancel-button" className="btn btn-default" onClick={this.close}>{ this.props.strings.app.Cancel }</button>&nbsp;
							<button id="dialog-reboot-reboot-button" className="btn btn-primary" onClick={this.activate}>Reboot Now</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DialogReboot;
