import FacebookConnectionState from '../model/FacebookConnectionState';
//import FacebookDestinationType from '../model/FacebookDestinationType';
import Log from '../log/Log';

// TODO:
// - console.logs -> Log
// - decouple controller. this should just be a transport class
// - controller.forceStateUpdate(userInfo['pageIdMap']);
// - pull in charlie's create/finish/delete/etc/LiveBroadcast

class Facebook
{
	constructor(controller,appid,graphversion,requiredScopes)
	{
		let _this = this;
		this.tag = 'Facebook';
		this.controller = controller;
		this.requiredScopes = requiredScopes.split(',');
		this.getUserInfo = this.getUserInfo.bind(this);
		this.fbLoaded = false;

		this.validateAccessToken = this.validateAccessToken.bind(this);

		this.broadcastFields = "id,status,dash_preview_url,stream_url,title,embed_html,description,permalink_url,from,live_encoders,video,ingest_streams";

		window.fbAsyncInit = function()
		{
			window.FB.init({
				appId            : appid,
				autoLogAppEvents : true,
				xfbml            : true,
				version          : graphversion,
				cookie           : true,
				status           : true
			});
			window.FB.AppEvents.logPageView();

			// WIRE EVENT SUBSCRIPTIONS HERE
			/*
			window.FB.Event.subscribe('auth.login', function(response) {

				console.log('## FB.Event: auth.login');
				console.log(response);
			});
			window.FB.Event.subscribe('auth.statusChange', function(response) {

				console.log('## FB.Event: auth.statusChange');
				console.log(response);
			});
			*/
			window.FB.getLoginStatus(function(response) {
				if (response.status === 'connected')
				{
					// TODO: find a way to decouple this
					_this.controller.userFacebookLoginResponse(response);
					_this.controller.setFacebookConnectedResponse(FacebookConnectionState.CONNECTED);
					// Don't bother to validate since the access token needs to be a developer for this api to work
					// if (response['authResponse'] && response['authResponse']['accessToken'])
					// {
					// 	_this.validateAccessToken(response['authResponse']['accessToken']);
					// }
				}
				else
				{
					Log.error('Unknown or unauthorized Facebook user');
					_this.controller.setFacebookConnectedResponse(FacebookConnectionState.NOT_CONNECTED);
				}
			});

			_this.fbLoaded = true;
		};

		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

	}

	login(scope)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject) 
		{
			if (_this.fbLoaded)
			{
				window.FB.login( function (response)
				{
					if (response.status === 'connected')
					{
						resolve(response);
					}
					else
					{
						reject(response);
					}
				},{
					scope:scope,
					return_scopes:false
				});
			}
			else
			{
				reject({error:'Facebook not loaded'});
			}
		});
		return _promise;
	}

	// loginReauthenticate()
	// {
	// 	let _promise = new Promise(function(resolve,reject)
	// 	{
	// 		if (this.fbLoaded)
	// 		{
	// 			window.FB.login( function (response)
	// 			{
	// 				if (response.status === 'connected')
	// 				{
	// 					resolve(response);
	// 				}
	// 				else
	// 				{
	// 					reject(response);
	// 				}
	// 			},{
	// 				auth_type: 'reauthenticate',
	// 				scope:process.env.REACT_APP_FACEBOOK_SCOPE,
	// 				return_scopes:false
	// 			});
	// 		}
	// 		else
	// 		{
	// 			reject({});
	// 		}
	// 	});
	// 	return _promise;
	// }

	logout()
	{
		let _this = this;
		let _promise = new Promise(function(resolve, reject) 
		{
			if (_this.fbLoaded)
			{
				window.FB.logout( function (response)
				{
					resolve(response);
				});
			}
			else
			{
				reject({error:'Facebook not loaded'});
			}
		});
		return _promise;
	}

	validateAccessToken(accessToken)
	{
		var _this = this;

		let requiredScopesTest = {};
		for (var s = 0; s < this.requiredScopes.length; s++)
		{
			requiredScopesTest[this.requiredScopes[s]] = false;
		}
		function hasRequiredScopes()
		{
			for (var scope in requiredScopesTest)
			{
				if (requiredScopesTest[scope] === false)
				{
					return false;
				}
			}
			return true;
		}
		Log.debug(_this.tag+'.validateAccessToken: required scopes:' + JSON.stringify(this.requiredScopes));
		
		window.FB.api('/debug_token', {'input_token':accessToken}, function(response) {

			if (response['data'] && response['data']['is_valid'])
			{
				// make sure we have certain scopes
				if (response['data']['scopes'])
				{
					Log.debug(_this.tag+'.validateAccessToken: access_token scopes:' + JSON.stringify(response['data']['scopes']));
					for (var i = 0; i < response['data']['scopes'].length; i++)
					{
						let scope = response['data']['scopes'][i];
						if (requiredScopesTest[scope] !== undefined) requiredScopesTest[scope] = true;
					}
					_this.controller.setFacebookHasRequiredScopes(hasRequiredScopes());
				}
			}
			else
			{
				Log.error(_this.tag+'.validateAccessToken: ERROR validating access token. Response from Graph API: ' + JSON.stringify(response));
				_this.controller.setFacebookHasRequiredScopes(false);
			}
		});
	}

	getUserInfo(accessToken,isLogin = false)
	{
		let _promise = new Promise(function(resolve, reject) 
		{
			// window.FB.api('/me', {fields: 'id,email,name,groups.limit(1000),accounts.limit(1000),events.limit(1000),picture'}, function (response)
			window.FB.api('/me', {fields: 'id,email,name,groups.limit(1000),accounts.fields(id,name,access_token,supports_donate_button_in_live_video).limit(1000),supports_donate_button_in_live_video,picture'}, function (response)
			{
				if (response && !response.error)
				{
					let userInfo = {
						facebookConnected:FacebookConnectionState.CONNECTED,
						accessToken:accessToken,
						email:response.email,
						id:response.id,
						name:response.name,
						picture:response.picture,
						userID:response.id,
						supports_donate_button_in_live_video:response.supports_donate_button_in_live_video,
						pages:[],
						pageIdMap:{},
						groups:[],
						events:[]
					};

					if (response.accounts && response.accounts.data)
					{
						userInfo['pages'] = response.accounts.data;
						for(var key in response.accounts.data)
						{
							userInfo['pageIdMap'][response.accounts.data[key].id] = response.accounts.data[key];
						}
					}

					if (response.groups && response.groups.data)
						userInfo['groups'] = response.groups.data;

					if (response.events && response.events.data)
						userInfo['events'] = response.events.data;

					resolve(userInfo);
				}
				else
				{
					reject(response);
				}
			});
		});
		return _promise;
	}

	switchRedundantStream(broadcastId, streamId, accessToken = undefined)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['master_ingest_stream_id'] = streamId;
	
			if (accessToken !== undefined)
				fields['access_token'] = accessToken;

			Log.debug(_this.tag+"switchRedundantStream: broadcastId:" + broadcastId + ", streamId:" + streamId);
	
			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				Log.debug(_this.tag+"switchRedundantStream: "+JSON.stringify(response));
				resolve(response);
			});
		});
		return _promise;
	}


	repairBroadcastEncoder(broadcastId, encoderId, accessToken = undefined)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['fields'] = 'live_encoders';

			if (accessToken !== undefined)
				fields['access_token'] = accessToken;

			window.FB.api(broadcastId, 'get', fields, function (response)
			{
				Log.debug(_this.tag+'.repairBroadcastEncoder[1]: broadcastId:'+broadcastId+', encoderId:' + encoderId + ', response:' + JSON.stringify(response));

				var liveEncodersWith = [];
				var liveEncodersWithout = [];

				for(var i in response.live_encoders)
				{
					liveEncodersWith.push(response.live_encoders[i]['id']);

					// console.log("compare[1]: "+response.live_encoders[i]['id']+"="+encoderId);
					if (response.live_encoders[i]['id'] !== encoderId)
						liveEncodersWithout.push(response.live_encoders[i]['id']);
				}

				Log.debug(_this.tag+'.repairBroadcastEncoder[1]: liveEncodersWith:' + JSON.stringify(liveEncodersWith));
				Log.debug(_this.tag+'.repairBroadcastEncoder[1]: liveEncodersWithout:' + JSON.stringify(liveEncodersWithout));
				
				fields['live_encoders'] = liveEncodersWithout;

				window.FB.api(broadcastId, 'post', fields, function (response)
				{
					Log.debug(_this.tag+'.repairBroadcastEncoder[2]: response:' + JSON.stringify(response));
					fields['live_encoders'] = liveEncodersWith;

					window.FB.api(broadcastId, 'post', fields, function (response)
					{
						Log.debug(_this.tag+'.repairBroadcastEncoder[3]: response:' + JSON.stringify(response));

						resolve(response);
					});
				});
			});
		});
		return _promise;
	}

	createLiveBroadcastAddIngestStream(broadcastId)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			if (broadcastId === undefined)
			{
				reject();
			}
			else
			{
				window.FB.api(broadcastId+'/input_streams', 'post', {}, function (response)
				{
					Log.debug(_this.tag+'.createLiveBroadcastAddIngestStream: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
					resolve(response);
				});
			}
		});
		return _promise;
	}

	createLiveBroadcastAddEncoders(broadcastId = undefined, broadcastInfo)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['live_encoders'] = broadcastInfo.liveEncoders;
			
			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				Log.debug(_this.tag+'.createLiveBroadcastAddEncoders: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
				resolve(response);
			});
		});
		return _promise;
	}
	
	createLiveBroadcast(broadcastId = undefined, broadcastInfo)
	{
		var j,k;
		let _this = this;
		let isCreate = broadcastId === undefined;
		let isDelayLiveEncoders = isCreate && broadcastInfo.encodingSettings !== undefined && broadcastInfo.maxFrameRate !== undefined;

		let _promise = new Promise(function(resolve,reject)
		{
			let isLive = broadcastInfo.isLive;
			let createdByClearCaster = false;
			if (broadcastInfo['createdByClearCaster'] !== undefined && broadcastInfo['createdByClearCaster'] === true)
			{
				createdByClearCaster = true;
			}

			let fields = {};

			if (createdByClearCaster)
			{
				fields['title'] = broadcastInfo.title;
				fields['description'] = broadcastInfo.description;
			}
			fields['fields'] = 'id,dash_preview_url,video';
			fields['enable_backup_ingest'] = true;
			fields['num_hosts'] = 2;
			if (createdByClearCaster && broadcastInfo.enableDonateLive && broadcastInfo.donateLiveCharityId !== undefined && broadcastInfo.donateLiveCharityId.length > 0)
			{
				console.log('broadcastInfo.donateLiveCharityId:' + broadcastInfo.donateLiveCharityId);
				fields['donate_button_charity_id'] = broadcastInfo.donateLiveCharityId;

			}
			if (!isDelayLiveEncoders && broadcastInfo['liveEncoders'])
				fields['live_encoders'] = broadcastInfo.liveEncoders;
			
			// This feels hacky, but FB throws errors when this is 'CUSTOM', which
			// appears to be set that way when targeting is applied, which only
			// happens on PAGES, so don't include this on the update call
			if (createdByClearCaster && broadcastInfo.privacy !== 'CUSTOM')
			{
				fields['privacy'] = {value:broadcastInfo.privacy};
			}
			
			if (createdByClearCaster)
			{
				if (broadcastInfo.isSpherical !== undefined)
				fields['is_spherical'] = broadcastInfo.isSpherical;

				if (broadcastInfo.isSpherical && broadcastInfo.isFishEye)
				{
					fields['projection'] = 'SINGLE_FISH_EYE';
					if (broadcastInfo.fov !== undefined)
						fields['fov'] = Number(broadcastInfo.fov);
				}

				// TODO: Spatial audio when supported

				if (broadcastInfo.embeddable !== undefined)
					fields['embeddable'] = broadcastInfo.embeddable;

				if (broadcastInfo.unpublishAtEnd !== undefined)
				{
					if (broadcastInfo.unpublishAtEnd)
						fields['stream_type'] = 'AMBIENT';
				}

				if (broadcastInfo.disturbing !== undefined && broadcastInfo.disturbing === true)
					fields['disturbing'] = broadcastInfo.disturbing;
			}

			if (broadcastInfo.access_token !== undefined)
				fields['access_token'] = broadcastInfo.access_token;

			if (createdByClearCaster && process.env.REACT_APP_ENABLE_TARGETING === 'true' && !isLive && broadcastInfo.targeting !== undefined && broadcastInfo.targeting !== null)
			{
				let targetingValue = {};
				targetingValue.age_min = broadcastInfo.targeting.age_min;
				targetingValue.age_max = broadcastInfo.targeting.age_max;
				targetingValue.genders = broadcastInfo.targeting.genders;
				targetingValue.geo_locations = {};

				let loc_types = ['countries','cities','regions','zips'];
				for (j = 0; j < loc_types.length; j++)
				{
					let loc_type = loc_types[j];
					if (broadcastInfo.targeting['geo_locations'] && broadcastInfo.targeting['geo_locations'][loc_type])
					{
						targetingValue.geo_locations[loc_type] = [];
						for (k = 0; k < broadcastInfo.targeting['geo_locations'][loc_type].length; k++)
						{
							if (loc_type==='countries')
							{
								targetingValue.geo_locations[loc_type].push( broadcastInfo.targeting['geo_locations'][loc_type][k].key );
							}
							else
							{
								targetingValue.geo_locations[loc_type].push( {key:broadcastInfo.targeting['geo_locations'][loc_type][k].key} );
							}
						}
					}
				}

				fields['targeting'] = targetingValue;
			}

			if (createdByClearCaster && !isLive && broadcastInfo.contentTags !== undefined && broadcastInfo.contentTags !== null)
			{
				let contentTagsValue = [];
				for (k = 0; k < broadcastInfo.contentTags.length; k++)
				{
					contentTagsValue.push(broadcastInfo.contentTags[k].id);
				}
				fields['content_tags'] = contentTagsValue;
			}

			if (broadcastId === undefined)
			{
				if (broadcastInfo.destination !== undefined)
					broadcastId = '/'+broadcastInfo.destination+'/live_videos';
				else
					broadcastId = '/me/live_videos';
				
				fields['status'] = (broadcastInfo.status===undefined?'UNPUBLISHED':broadcastInfo.status);
			}

			Log.debug(_this.tag+'.createLiveBroadcast: broadcastId:' + broadcastId +', fields:' + JSON.stringify(fields));
			Log.debug(_this.tag+'.createLiveBroadcast: broadcastId:' + broadcastId +' createdByClearCaster:' + createdByClearCaster);

			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				if (response['error'])
				{
					Log.error(_this.tag+'.createLiveBroadcast: broadcastId:' + broadcastId +', fields: ' + JSON.stringify(fields) +', response:' + JSON.stringify(response));
					reject(response);
				}
				else
				{
					Log.debug(_this.tag+'.createLiveBroadcast: broadcastId:' + broadcastId +', response:' + JSON.stringify(response));
				}

				var broadcastIdResult = response.id;

				if (broadcastInfo.overlayGraphics !== undefined && broadcastInfo.overlayGraphics !== {})
					_this.controller.setBrokerBroadcastValue(broadcastIdResult, "overlayGraphics", broadcastInfo.overlayGraphics);
				else
					_this.controller.removeBrokerBroadcastValue(broadcastIdResult, "overlayGraphics");

				if (broadcastInfo.browserTalentView !== undefined && 
					broadcastInfo.browserTalentView !== {} && 
					broadcastInfo.browserTalentView.vendor !== 'none')
				{
					_this.controller.setBrokerBroadcastValue(broadcastIdResult, "talentViewBrowserGraphics", broadcastInfo.browserTalentView);
				}
				else
				{
					_this.controller.removeBrokerBroadcastValue(broadcastIdResult, "talentViewBrowserGraphics");
				}

				if (broadcastInfo.audioChannelMap !== undefined &&
					broadcastInfo.audioChannelMap !== {})
				{
					_this.controller.setBrokerBroadcastValue(broadcastIdResult, "audioChannelMap", broadcastInfo.audioChannelMap);
				}
				else
				{
					_this.controller.removeBrokerBroadcastValue(broadcastIdResult, "audioChannelMap");
				}
	
				_this.controller.updateSimulcastTargetToBroadcastEntry(broadcastInfo.simulcastingTarget, broadcastIdResult, broadcastInfo);
	
				let setValues = [];
				var myPromise;
				if (isDelayLiveEncoders)
				{
					myPromise = _this.controller.setBrokerBroadcastValuePromise(broadcastIdResult, "encodingSettings", broadcastInfo.encodingSettings);	
					setValues.push(myPromise);
		
					myPromise = _this.controller.setBrokerBroadcastValuePromise(broadcastIdResult, "maxFrameRate", broadcastInfo.maxFrameRate);	
					setValues.push(myPromise);
				}
				if (process.env.REACT_APP_ENABLE_TARGETING === 'true' && broadcastInfo['targeting'])
				{
					myPromise = _this.controller.setBrokerBroadcastValuePromise(broadcastIdResult, "targeting", broadcastInfo.targeting);	
					setValues.push(myPromise);
				}

				let saveExtraValues = {};
				let saveExtraValuesFlag = false;

				if (broadcastInfo['createdByClearCaster'] !== undefined)
				{
					saveExtraValues['createdByClearCaster'] = broadcastInfo.createdByClearCaster;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['destinationType'] !== undefined)
				{
					saveExtraValues['destinationType'] = broadcastInfo.destinationType;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['isSpherical'] !== undefined)
				{
					saveExtraValues['isSpherical'] = broadcastInfo.isSpherical;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['isFishEye'] !== undefined)
				{
					saveExtraValues['isFishEye'] = broadcastInfo.isFishEye;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['unpublishAtEnd'] !== undefined)
				{
					saveExtraValues['unpublishAtEnd'] = broadcastInfo.unpublishAtEnd;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['disturbing'] !== undefined)
				{
					saveExtraValues['disturbing'] = broadcastInfo.disturbing;
					saveExtraValuesFlag = true;
				}
				if (broadcastInfo['enableDonateLive'] !== undefined)
				{
					saveExtraValues['enableDonateLive'] = broadcastInfo.enableDonateLive;
					saveExtraValuesFlag = true;
					// only save this if enabled and the api call was successful
					if (broadcastInfo['enableDonateLive'] === true && broadcastInfo['donateLiveCharityId'] !== undefined)					
					{
						saveExtraValues['donateLiveCharityId'] = broadcastInfo.donateLiveCharityId;
					}
				}
				if (saveExtraValuesFlag)
				{
					myPromise = _this.controller.setBrokerBroadcastValuePromise(broadcastIdResult, "extraValues", saveExtraValues);
					setValues.push(myPromise);
				}
				if (setValues.length > 0)
				{
					Promise.all(setValues)
					.then(function(allVideoData) 
					{
						if (broadcastInfo['liveEncoders'])
						{
							fields = {};
							fields['live_encoders'] = broadcastInfo.liveEncoders;
							
							window.FB.api(broadcastIdResult, 'post', fields, function (response)
							{
								// console.log("createLiveBroadcast: updated live encoders");
							});
						}
						else
						{
						}
					});
				}
			resolve(response);
			});
		});
		return _promise;
	}

	deleteLiveBroadcast(broadcastId)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['end_live_video'] = true;

			Log.debug(_this.tag+'.deleteLiveBroadcast: broadcastId:' + broadcastId + ', fields:' + JSON.stringify(fields));

			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				Log.debug(_this.tag+'.deleteLiveBroadcast[1]: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));

				fields = {};

				window.FB.api(broadcastId, 'delete', fields, function (response)
				{
					Log.debug(_this.tag+'.deleteLiveBroadcast[1]: broadcastId:' + broadcastId + ', response:'+JSON.stringify(response));
		
					resolve(response);
				});
			});
		});
		return _promise;
	}

	finishLiveBroadcast(broadcastId)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['end_live_video'] = true;

			Log.debug(_this.tag+'.finishLiveBroadcast: broadcastId:' + broadcastId + ', fields:' + JSON.stringify(fields));

			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				Log.debug(_this.tag+'.finishLiveBroadcast[1]: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
				resolve(response);
			});
		});
		return _promise;
	}

	goLiveBroadcast(broadcastId)
	{
		let _this = this;
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {};

			fields['status'] = 'LIVE_NOW';

			Log.debug(_this.tag+'.goLiveLiveBroadcast: broadcastId:' + broadcastId + ', fields:' + JSON.stringify(fields));

			window.FB.api(broadcastId, 'post', fields, function (response)
			{
				Log.debug(_this.tag+'.goLiveLiveBroadcast[1]: broadcastId:' + broadcastId + ', response:' + JSON.stringify(response));
				resolve(response);
			});
		});
		return _promise;
	}

	getLiveEncoders()
	{
		let _promise = new Promise(function(resolve,reject) 
		{
			window.FB.api('/me/live_encoders', 'get', {fields:'id,device_id,brand,creation_time,model,name,status,version,current_broadcast'}, function (response)
			{
				if (response['data'] !== undefined && response['data'].length > 0)
				{
					var liveEncoders = response['data'];
					var liveEncoderMap = {};
					
					for(var key in liveEncoders)
					{
						var liveEncoder = liveEncoders[key];
						
						liveEncoderMap[liveEncoder.id] = liveEncoder;
					}
					resolve({
						liveEncoders:liveEncoders, 
						liveEncoderMap:liveEncoderMap
					});
				}
				else
				{
					resolve({
						liveEncoders:[], 
						liveEncoderMap:{}
					});
				}
			});
		});
		return _promise;
	}

	deleteLiveEncoder(encoderId)
	{
		let _promise = new Promise(function(resolve, reject)
		{
			var fields = {};
			window.FB.api('/'+encoderId, 'delete', fields, function (response) 
			{
				resolve(response);
			});
		});
		return _promise;
	}

	getLiveEncoderActiveBroadcast(encoderId)
	{
		let _promise = new Promise(function(resolve, reject) 
		{
			var fields = {};
		
			fields['status'] = 'none';

			window.FB.api('/'+encoderId, 'post', fields, function (response)
			{
				if (response['action'] !== undefined)
				{
					response.encoderId = encoderId;
					resolve(response);
				}
				else
				{
					resolve({encoderId: encoderId});
				}
			});

		});
		return _promise;
	}

	getPageAccounts()
	{
		let _this = this;
		let _promise = new Promise(function(resolve, reject) 
		{
			window.FB.api('/me/accounts', 'get', {fields:'id,name,access_token'}, function (response)
			{
				Log.debug(_this.tag+'.getPageAccounts: response:' + JSON.stringify(response));

				if (response['data'] !== undefined && response['data'].length > 0)
				{
					resolve(response['data']);
				}
				else
				{
					resolve(null);
				}

			});
		});
		return _promise;
	}

	registerLiveEncoder(deviceID, brand, model, name, version)
	{
		let _promise = new Promise(function(resolve, reject) 
		{
			let fields = {
				status:'register',
				device_id:deviceID,
				brand:brand,
				model:model,
				name:name,
				version:version
			};

			window.FB.api('/me/live_encoders', 'post', fields, function (response)
			{
				resolve(response);
			});
		});
		return _promise;
	}

	getAllActiveBroadcasts(pageIdMap)
	{
		let _this = this;
		let _promise = new Promise(function(resolve, reject) 
		{
			var myCalls = [];

			//console.log("getAllActiveBroadcasts[user]");
			var myPromise = _this.getActiveBroadcasts();
			myCalls.push(myPromise);

			for(var key in pageIdMap)
			{
				//console.log("getAllActiveBroadcasts[page]: "+pageIdMap[key].name);
				myPromise = _this.getActiveBroadcasts(key, pageIdMap[key].access_token);
				myCalls.push(myPromise);
			}

			//console.log("getAllActiveBroadcasts");

			Promise.all(myCalls)
			.then(function(allData) {

				// console.log("getAllActiveBroadcasts[resp1]: "+JSON.stringify(allData));

				let activeBroadcasts = [];
				let crosspostedBroadcasts = [];
				let activeBroadcastMap = {};
				var i

				for(i in allData)
					activeBroadcasts.push.apply(activeBroadcasts, allData[i]);

				var myVideos = [];
					
				//for(i in activeBroadcasts)
				for(i=activeBroadcasts.length-1;i>=0;i--)
				{
					var broadcastInfo = activeBroadcasts[i];

					// remove blank broadcasts created by live/create
					if ((broadcastInfo.title === undefined || broadcastInfo.title.length <= 0) &&
						(broadcastInfo.description === undefined || broadcastInfo.description.length <= 0) &&
						(broadcastInfo.live_encoders === undefined || broadcastInfo.live_encoders.length <= 0) &&
						(broadcastInfo.planned_start_time === undefined))
					{
						activeBroadcasts.splice(i, 1);
						continue;
					}

					// collect crossposted broadcast ids
					if (broadcastInfo['crossposted_broadcasts'] && broadcastInfo['crossposted_broadcasts']['data'])
					{
						for (var z = 0; z < broadcastInfo['crossposted_broadcasts']['data'].length; z++)
						{
							crosspostedBroadcasts.push(broadcastInfo['crossposted_broadcasts']['data'][z]);
						}
					}

					activeBroadcastMap[activeBroadcasts[i].id] = broadcastInfo;
					
					var accessToken = undefined;
					if (broadcastInfo.accessToken !== undefined)
					accessToken = broadcastInfo.accessToken;

					myPromise = _this.getActiveVideos(broadcastInfo.id, broadcastInfo.video.id, accessToken);
					myVideos.push(myPromise);
				}

				Promise.all(myVideos)
				.then(function(allVideoData) {
					//console.log("activeBroadcasts: "+JSON.stringify(allVideoData));

					//console.log("getAllActiveBroadcasts[resp2]: "+JSON.stringify(allVideoData));

					for(var i in allVideoData)
					{
						activeBroadcastMap[allVideoData[i].broadcastId].privacy = allVideoData[i].privacy;
					}
					resolve({activeBroadcasts:activeBroadcasts, activeBroadcastMap:activeBroadcastMap, crosspostedBroadcasts:crosspostedBroadcasts});
				});

			});
		});
		return _promise;
	}

	getActiveBroadcasts(objectId = 'me', accessToken = undefined)
	{
		let _this = this;
		let _promise = new Promise(function(resolve, reject) {

			var blimit = 1000;

			var fields = {};

			fields['source'] = 'owner';
		
			fields['broadcast_status'] = ['LIVE','UNPUBLISHED','SCHEDULED_LIVE','SCHEDULED_UNPUBLISHED'];

			// live_encoders,broadcast_start_time,creation_time,dash_preview_url,description,embed_html,from,is_manual_mode,permalink_url,planned_start_time,seconds_left,status,title,video,blocked_users,errors";
			// removed from - stopped working with groups 4/18/2018
			// dash_preview_url - stopped working with show pages 4/18/2018
			if (objectId === 'me')
			{
				fields['fields'] = "id,live_encoders,video,status,title,description,embed_html,broadcast_start_time,creation_time,dash_preview_url,planned_start_time,permalink_url,ingest_streams";
			}
			else
			{
				fields['fields'] = "id,from,live_encoders,video,status,title,description,embed_html,broadcast_start_time,creation_time,dash_preview_url,planned_start_time,permalink_url,ingest_streams,crossposted_broadcasts{id}";
			}
			fields['order'] = "reverse_chronological";
			fields['limit'] = blimit;

			if (accessToken !== undefined)
			{
				fields['access_token'] = accessToken;
			}

			window.FB.api('/'+objectId+'/live_videos', 'get', fields, function (response)
			{
				Log.debug(_this.tag+'.getActiveBroadcasts: objectId:' + objectId + ', fields:' + JSON.stringify(fields) + ', response:' + JSON.stringify(response));
				let videos = [];
				if (response['data'] !== undefined && response['data'].length > 0)
				{
					for(var i = 0; i < response['data'].length; i++)
					{
						let v = response['data'][i];
						
						v.objectId = objectId;
						
						if (accessToken !== undefined)
						{
							v.accessToken = accessToken;
						}

						v.ingestMap = {};
						if (v.ingest_streams !== undefined && v.live_encoders !== undefined)
						{
							for(var j = 0; j < v.ingest_streams.length; j++)
							{
								if (j < v.live_encoders.length)
								{
									v.ingestMap[v.live_encoders[j].id] = v.ingest_streams[j];
								}
							}
						}

						// console.log(JSON.stringify(v.ingestMap));

						videos.push(v);
					}

				}

				// console.log(videos);

				resolve(videos);
			});

		});

		return _promise;
	}

	getActiveVideos(broadcastId, videoId, accessToken = undefined)
	{
		let _promise = new Promise(function(resolve, reject) 
		{

			var fields = {};
		
			fields['fields'] = "id,privacy";

			if (accessToken !== undefined)
			{
				fields['access_token'] = accessToken;
			}

			window.FB.api('/'+videoId, 'get', fields, function (response)
			{
				//console.log("getActiveVideos: "+JSON.stringify(response));

				response.broadcastId = broadcastId;
				resolve(response);
			});

		});
		return _promise;
	}

	getLiveVideos(encoderId)
	{
		let _promise = new Promise(function(resolve, reject) 
		{
			window.FB.api('/me/live_videos', 'get', {fields:'live_encoder,status,title'}, function (response)
			{
				//console.log('getLiveVideos:');
				//console.log(response);

				if (response['data'] !== undefined && response['data'].length > 0)
				{
					let videos = [];
					for(var i = 0; i < response['data'].length; i++)
					{
						let v = response['data'][i];
						if (v.status === 'LIVE')
						{
							if (v.live_encoder && v.live_encoder.id && v.live_encoder.id === encoderId)
							{
								videos.push(v);
							}
						}
					}
					resolve(videos);
				}
				else
				{
					resolve([]);
				}

			});
		});
		return _promise;
	}

	// TODO: still needed?
	getLiveBroadcasts(nodeId = 'me')
	{
		let _this = this;
		let fields = {
			broadcast_status: ['LIVE','UNPUBLISHED'],
			fields: this.broadcastFields
		};

		window.FB.api('/' + nodeId + '/live_videos', 'get', fields, function(response)
		{
			if (response['data'] !== undefined)
			{
				_this.controller.facebookGetLiveBroadcastsResponse(response['data']);
			}
		});
	}

	// TODO: Promise
	getBroadcastInfo (broadcastId, accessToken, callback)
	{
		let _this = this;
		let fields = {
			fields:this.broadcastFields
		};

		if (accessToken !== null)
		{
			fields['access_token'] = accessToken;
		}

		window.FB.api(broadcastId, 'post', fields, function(response)
		{
			_this.controller.facebookGetBroadcastInfoResponse(response);
			if (callback)
			{
				callback(response);
			}
		});
	}

	// TODO: Promise
	getBroadcastReactionCounts (broadcastId, callback)
	{
		let _this = this;
		let fields = {fields:'reactions.type(LIKE).limit(0).summary(1).as(like),reactions.type(LOVE).limit(0).summary(1).as(love),reactions.type(HAHA).limit(0).summary(1).as(haha),reactions.type(WOW).limit(0).summary(1).as(wow),reactions.type(SAD).limit(0).summary(1).as(sad),reactions.type(ANGRY).limit(0).summary(1).as(angry),reactions.type(THANKFUL).limit(0).summary(1).as(thankful),reactions.type(PRIDE).limit(0).summary(1).as(pride)'};

		window.FB.api(broadcastId, 'post', fields, function(response)
		{
			_this.controller.facebookGetBroadcastReactionCountsResponse(response);
			if (callback)
			{
				callback(response);
			}
		});
	}

	// TODO: Promise
	streamStop(broadcastId,accessToken, callback)
	{
		let _this = this;

		var fields = {'end_live_video':true};
		if (accessToken !== null)
		{
			fields['access_token'] = accessToken;
		}

		window.FB.api(broadcastId, 'post', fields, function (response)
		{
			_this.controller.facebookStreamStopResponse(response);
			if (callback)
			{
				callback(response);
			}
		});
	}

	// TODO: Promise
	streamStopByEncoderId(encoderId, callback)
	{
		let _this = this;
		window.FB.api('/me/live_videos', 'get', {fields:'status,id,live_encoders'}, function (response)
		{
			if (response['data'] !== undefined && response['data'].length > 0)
			{
				for(var i = 0; i < response['data'].length; i++)
				{
					let v = response['data'][i];
					if (v['live_encoders'] && v['live_encoders'].length > 0)
					{
						for (var j = 0; j < v['live_encoders'].length; j++)
						{
							let vle = v['live_encoders'][j];
							if (vle['id'] === encoderId)
							{
								_this.streamStop(v['id']);
							}
						}
					}
				}
			}
			if (callback)
			{
				callback(response);
			}
		});
	}

	// TODO: Replace with Charlie's above
	createBroadcast(broadcastInfo, callback)
	{
		let _this = this;
		let fields = {};
		let destinationId = broadcastInfo.id;
		if (destinationId === 0 || destinationId === '0')
			destinationId = 'me';

		if (broadcastInfo.access_token)
			fields['access_token'] = broadcastInfo.access_token;

		fields['status'] = "UNPUBLISHED";
		fields['stream_type'] = broadcastInfo.stream_type || "REGULAR";
		fields['privacy'] = {value:broadcastInfo.privacy};
		fields['title'] = broadcastInfo.title;
		fields['enable_backup_ingest'] = true;
		fields['description'] = broadcastInfo.description;
		fields['is_spherical'] = broadcastInfo.is_spherical || false;
		if (broadcastInfo['save_vod'] === false)
		{
			fields['save_vod'] = false;
		}
		// fields['disturbing'] = broadcastInfo.disturbing || false;

		if (broadcastInfo.is_spherical === true)
		{
			fields['projection'] = broadcastInfo.projection;
		}

		// if (broadcastInfo.destination && broadcastInfo.destination === FacebookDestinationType.PAGE)
		// {
			fields['embeddable'] = broadcastInfo.embeddable || false;
		// }

		fields['fields'] = 'id,dash_preview_url,video,embed_html,live_encoders';

		var encoders = [];
		encoders.push(broadcastInfo.encoderId);

		fields['live_encoders'] = encoders;

		window.FB.api('/' + destinationId + '/live_videos', 'post', fields, function(response)
		{
			_this.controller.facebookCreateBroadcastResponse(response);
			if (callback)
			{
				callback(response);
			}
		});
	}

	// TODO: Replace with Charlie's above
	updateBroadcast (broadcastId, broadcastInfo, callback)
	{
		let _this = this;
		let fields = {};
		if (broadcastInfo.access_token)
			fields['access_token'] = broadcastInfo.access_token;
		fields['status'] = broadcastInfo.status || "UNPUBLISHED";
		fields['stream_type'] = broadcastInfo.stream_type || "REGULAR";
		fields['privacy'] = {value:broadcastInfo.privacy};
		fields['title'] = broadcastInfo.title;
		fields['description'] = broadcastInfo.description;
		fields['is_spherical'] = broadcastInfo.is_spherical || false;
		if (broadcastInfo['save_vod'] === false)
		{
			fields['save_vod'] = false;
		}
		// fields['disturbing'] = broadcastInfo.disturbing || false;
		if (process.env.REACT_APP_ENABLE_TARGETING === 'true' && typeof broadcastInfo['targeting'] !== 'undefined' && broadcastInfo['targeting'] !== null)
			fields['targeting'] = broadcastInfo.targeting;

		if (broadcastInfo.is_spherical === true)
		{
			fields['projection'] = broadcastInfo.projection;
		}

		// if (broadcastInfo.destination && broadcastInfo.destination === FacebookDestinationType.PAGE)
		// {
			fields['embeddable'] = broadcastInfo.embeddable || false;
		// }

		fields['fields'] = this.broadcastFields;

		window.FB.api(broadcastId, 'post', fields, function(response)
		{
			_this.controller.facebookUpdateBroadcastResponse(response);
			if (callback)
			{
				callback(response);
			}
		});
	}

	getVideoInfo (videoId, accessToken)
	{
		let _promise = new Promise(function(resolve,reject)
		{
			let fields = {fields:'id,content_category,content_tags,embeddable,event,custom_labels,is_crosspost_video,is_crossposting_eligible,from,live_status,permalink_url,privacy,published,scheduled_publish_time,status,created_time,updated_time'};
			if (accessToken !== null)
			{
				fields['access_token'] = accessToken;
			}
	
			window.FB.api(videoId, 'get', fields, function(response)
			{
				if (response && !response.error)
				{
					resolve(response);
				}
				else
				{
					reject(response);
				}
			});
		});
		return _promise;
	}

	getSearch (parameters)
	{
		let _promise = new Promise(function(resolve,reject) 
		{
			window.FB.api ('search', 'get', parameters, function(response)
			{
				if (response && !response.error)
				{
					resolve(response);
				}
				else
				{
					reject(response);
				}
			});
		});
		return _promise;
	}

	getSearchObject (objectIdStr)
	{
		let _promise = new Promise(function(resolve,reject)
		{
			window.FB.api (objectIdStr, 'get', null, function(response)
			{
				if (response && !response.error)
				{
					resolve(response);
				}
				else
				{
					reject(response);
				}
			});
		});
		return _promise;
	}
}

export default Facebook;
