import { createStore } from 'redux';
import LogLevels from '../log/LogLevels';
import ClearCasterActivateFormReducer, { ActivateFormActionTypes } from './ClearCasterActivateFormReducer';
import ClearCasterChangeUUIDFormReducer, { ChangeUUIDFormActionTypes } from './ClearCasterChangeUUIDFormReducer';
import ClearCasterAlertsReducer, { AlertsActionTypes } from './ClearCasterAlertsReducer';
import ClearCasterBroadcastsReducer, { BroadcastsActionTypes } from './ClearCasterBroadcastsReducer';
import ClearCasterDialogsReducer, { DialogsActionTypes } from './ClearCasterDialogsReducer';
import ClearCasterGoLiveReducer, { GoLiveActionTypes } from './ClearCasterGoLiveReducer';
import ClearCasterSimulcastReducer, { SimulcastActionTypes } from './ClearCasterSimulcastReducder';
import FacebookConnectionState from './FacebookConnectionState';
import { mixObjects } from '../controller/ClearCasterAdminUtils';

// TODO: 
// - pull in simulcast from Charlie's code
// - state.facebookAccessTokens?

let actionTypes =
{
	ADD_FACEBOOK_SEARCH_OBJECT: 'ADD_FACEBOOK_SEARCH_OBJECT',
	SHOW_ROLLBACK_UI: 'SHOW_ROLLBACK_UI',
	SHOW_FORCE_UPDATE: 'SHOW_FORCE_UPDATE',
	SET_CURRENT_LIVE_ENCODER: 'SET_CURRENT_LIVE_ENCODER',
	SET_CURRENT_LIVE_ENCODER_NETWORK_INFO: 'SET_CURRENT_LIVE_ENCODER_NETWORK_INFO',
	SET_CURRENT_LIVE_ENCODER_SYSTEM_LOG_EVENT: 'SET_CURRENT_LIVE_ENCODER_SYSTEM_LOG_EVENT',
	SET_ENCODER_LOCAL_VERSIONS: 'SET_ENCODER_LOCAL_VERSIONS',
	SET_ENCODER_STATUS: 'SET_ENCODER_STATUS',
	SET_ENCODER_UPDATING: 'SET_ENCODER_UPDATING',
	SET_ERROR: 'SET_ERROR',
	SET_FACEBOOK_CONNECTED: 'SET_FACEBOOK_CONNECTED',
	SET_FACEBOOK_HAS_REQUIRED_SCOPES: 'SET_FACEBOOK_HAS_REQUIRED_SCOPES',
	SET_FACEBOOK_LIVE_ENCODERS: "SET_FACEBOOK_LIVE_ENCODERS",
	SET_FACEBOOK_PAGE_ACCOUNTS: "SET_FACEBOOK_PAGE_ACCOUNTS",
	SET_FACEBOOK_LIVE_BROADCASTS: 'SET_FACEBOOK_LIVE_BROADCASTS', // TODO: deleteme
	SET_FACEBOOK_LIVE_VIDEOS_MAP: 'SET_FACEBOOK_LIVE_VIDEOS_MAP',
	SET_LOG_LEVEL: 'SET_LOG_LEVEL',
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	SET_SETTINGS: 'SET_SETTINGS',
	SET_WOWZA_PAIRING_STATUS:'SET_WOWZA_PAIRING_STATUS',
	SHOW_EXPIRED_WOWZA_SESSION:'SHOW_EXPIRED_WOWZA_SESSION',
	SET_FORCE_UPDATE_VERSION:'SET_FORCE_UPDATE_VERSION',
	USER_FACEBOOK_LOGIN: 'USER_FACEBOOK_LOGIN',
	USER_FACEBOOK_LOGOUT: 'USER_FACEBOOK_LOGOUT',
	USER_FACEBOOK_RELOAD_ON_LOGOUT: 'USER_FACEBOOK_RELOAD_ON_LOGOUT',
	SET_USER_JWT: 'SET_USER_JWT',
	SET_USER_WOWZA_REQUIRED: 'SET_USER_WOWZA_REQUIRED',
  SET_ENCODER_INFO: 'SET_ENCODER_INFO',
  SET_ENCODER_INFO_CHANGE_STATUS: 'SET_ENCODER_INFO_CHANGE_STATUS',
	SET_JWT_EXPIRATION_TIME: 'SET_JWT_EXPIRATION_TIME'
};

export const ActionTypes = mixObjects(
	actionTypes,
	ActivateFormActionTypes,
	ChangeUUIDFormActionTypes,
	AlertsActionTypes,
	BroadcastsActionTypes,
	DialogsActionTypes,
	GoLiveActionTypes,
  SimulcastActionTypes
);

const initialState = {
	error:{
		type:null,
		message:""
	},
	showUniversalInHeader:true,
	notifications:[],
	settings:{
		received:false,
		facebook_app:"",
		facebook_graph_api_version:"v2.12",
		facebook_scopes:"",
		facebook_deviceinput_serials:[]
	},
	logLevel:process.env.REACT_APP_LOG_LEVEL || LogLevels.ERROR,
	featureEncoderVersions:{
		"countdownNumberOfSteps":"1.0.1.05",
		"talentViewLayout":"1.0.1.07",
		"fbPairWithAccessToken":"1.0.0.34",
		"streamHealth":"1.0.1.05",
		"encodingConfigAspectRatioMode":"1.0.2.11",
		"enableHighMotionQuality":"1.0.2.19",
		"devicereboot":"1.0.2.23",
		"videoSignalActive":"1.0.2.23",
		"previewTools":"1.0.3.08",
		"verticalVideo":"1.0.2.26",
		"verticalRotate":"1.0.4.10",
		"workplace":"1.0.4.08",
		"broadcastDuration":"1.0.4.13",
		"broadcastAudioChannelMap":"1.0.5.15",
		"defaultAudioChannelMap":"1.0.5.16",
		"facebookPublishPathOptimization":"1.0.7.10",
		"deviceInput":"2.0.2.02"
	},
	currentLiveEncoder:{},
	facebookConnected:FacebookConnectionState.UNKNOWN,
	facebookPageAccounts:[],
	facebookLiveEncoders:[],
	facebookLiveEncoderMap:{},
	facebookLiveBroadcasts:[], // deleteme
	facebookReloadOnLogout:false,
	facebookReloadParams:{},
	facebookSearchObjectsMap:{},
	userFacebook:{
		facebookConnected:FacebookConnectionState.UNKNOWN,
		accessToken:"",
		hasRequiredScopes:true,
		email:"",
		id:"",
		name:"",
		picture:null,
		userID:"",
		supports_donate_button_in_live_video: false,
		pages:[],
		pageIdMap:{},
		receivedLiveEncoders: false,
		groups:[],
		events:[]
	},
	userWowza:{
		jwt:"",
		jwtExpiration: null,
		jwtExpired:false,
		username:"",
		name:"",
		super: false,
		receivedUserPairingStatus: false,
		showExpiredSession:false,
		redirectToLogin:false,
		required:false
	},
	wowzaPairings:{
		paired:false,
		pairings:{}
	},
	encoderInfo:{}, // TODO: is this still used?
	forceUpdateVersion:'',
	jwtExpirationTime: undefined
}

function clearCasterStore (
	state = initialState, action)
{
	state.activateForm = ClearCasterActivateFormReducer (state.activateForm, action);
	state.changeUUIDForm = ClearCasterChangeUUIDFormReducer (state.changeUUIDForm, action);
	state.alerts = ClearCasterAlertsReducer (state.alerts, action);
	state.broadcasts = ClearCasterBroadcastsReducer (state.broadcasts, action);
	state.dialogs = ClearCasterDialogsReducer (state.dialogs, action);
	state.golive = ClearCasterGoLiveReducer (state.golive, action);
	state.simulcast = ClearCasterSimulcastReducer(state.simulcast, action);

	switch (action.type)
	{
		case ActionTypes.ADD_FACEBOOK_SEARCH_OBJECT:
			state.facebookSearchObjectsMap[action.id] = action.obj;
			return state;
		case ActionTypes.SET_JWT_EXPIRATION_TIME:
			state.jwtExpirationTime = action.jwtExpirationTime;
			return state;
		case ActionTypes.SHOW_ROLLBACK_UI:
			state.showRollbackUI = action.showRollbackUI;
			return state;
		case ActionTypes.SHOW_FORCE_UPDATE:
			state.showForceUpdate = action.showForceUpdate;
			return state;
		case ActionTypes.SET_CURRENT_LIVE_ENCODER:
			if (action.currentLiveEncoder === null)
			{
				state.currentLiveEncoder = {};
			}
			else
			{
				state.currentLiveEncoder = action.currentLiveEncoder;
			}
			return state;
		case ActionTypes.SET_CURRENT_LIVE_ENCODER_NETWORK_INFO:
			for (let pairingKey in state.wowzaPairings.pairings)
			{
				if (action.pairingKey === pairingKey)
				{
					state.wowzaPairings.pairings[pairingKey].networkInfo = action.networkinfo;
					if (state.currentLiveEncoder && state.currentLiveEncoder['serial'] === state.wowzaPairings.pairings[pairingKey].serial)
					{
						state.currentLiveEncoder.networkInfo = action.networkinfo;
					}
				}
			}
			return state;
		case ActionTypes.SET_CURRENT_LIVE_ENCODER_SYSTEM_LOG_EVENT:
			for (let pairingKey in state.wowzaPairings.pairings)
			{
				if (action.systemLogEvent.serial && state.wowzaPairings.pairings[pairingKey].serial === action.systemLogEvent.serial)
				{
					if ( state.wowzaPairings.pairings[pairingKey].systemLogEvent &&
						typeof state.wowzaPairings.pairings[pairingKey].systemLogEvent === 'object' &&
            Array.isArray(state.wowzaPairings.pairings[pairingKey].systemLogEvent))
					{
						state.wowzaPairings.pairings[pairingKey].systemLogEvent.push(action.systemLogEvent)
						if (state.wowzaPairings.pairings[pairingKey].systemLogEvent.length > 3)
							state.wowzaPairings.pairings[pairingKey].systemLogEvent.shift();
					} else {
						state.wowzaPairings.pairings[pairingKey].systemLogEvent = [action.systemLogEvent]
					}
				}
			}
			return state;
		case ActionTypes.SET_ENCODER_LOCAL_VERSIONS:
			for (let pairingKey in state.wowzaPairings.pairings)
			{
				if (action.pairingKey === pairingKey)
				{
					state.wowzaPairings.pairings[pairingKey].localVersions = action.localVersions;
				}
			}
			return state;
		case ActionTypes.SET_ENCODER_STATUS:
			for (let pairingKey in state.wowzaPairings.pairings)
			{
				if (action.pairingKey === pairingKey)
				{
					state.wowzaPairings.pairings[pairingKey].status = action.currentLiveEncoderStatus;
					state.wowzaPairings.pairings[pairingKey].timestamp = action.timestamp;
					if (action.currentLiveEncoderStatus !== null && action.currentLiveEncoderStatus['currentTimeMillis'])
					{
						let deltaTime = action.timestamp - action.currentLiveEncoderStatus['currentTimeMillis'];
						if (state.wowzaPairings.pairings[pairingKey].deltas === undefined)
							state.wowzaPairings.pairings[pairingKey].deltas = [];
						state.wowzaPairings.pairings[pairingKey].deltas.push(deltaTime);
						if (state.wowzaPairings.pairings[pairingKey].deltas.length > 5)
							state.wowzaPairings.pairings[pairingKey].deltas.shift();
						let deltaSum = 0;
						for (var i = 0; i < state.wowzaPairings.pairings[pairingKey].deltas.length; i++)
							deltaSum += state.wowzaPairings.pairings[pairingKey].deltas[i];
						state.wowzaPairings.pairings[pairingKey].deltaTime = Math.round(deltaSum/state.wowzaPairings.pairings[pairingKey].deltas.length);
					}
					else
					{
						if (state.wowzaPairings.pairings[pairingKey]['deltaTime'] === undefined)
							state.wowzaPairings.pairings[pairingKey].deltaTime = 0;
					}
					if (action.currentLiveEncoderStatus === null || !action.currentLiveEncoderStatus.deviceStreamRunning)
					{
						state.wowzaPairings.pairings[pairingKey].updating = false;
					}
				}
			}

			// this is hacky but....
			/*
			if (action.currentLiveEncoderStatus && action.currentLiveEncoderStatus.versionCurrent)
			{
				if (isVersionGreaterThan(action.currentLiveEncoderStatus.versionCurrent, "1.0.8.01"))
					state.showUniversalInHeader = true;
			}
			*/
			return state;
		case ActionTypes.SET_ENCODER_UPDATING:
			for (let pairingKey in state.wowzaPairings.pairings)
			{
				if (action.pairingKey === pairingKey)
				{
					state.wowzaPairings.pairings[pairingKey].updating = action.updating;
				}
			}
			return state;
		case ActionTypes.SET_ERROR:
			state.error = action.error;
			return state;
		case ActionTypes.SET_LOG_LEVEL:
			console.log('SET_LOG_LEVEL:' + action.logLevel);
			state.logLevel = action.logLevel;
			return state;
		case ActionTypes.SET_NOTIFICATIONS:
			state.notifications = action.notifications;
			return state;
		case ActionTypes.SET_SETTINGS:
			let scopes = action.settings.remote.facebook_scopes;
			console.log('facebook scopes:'+scopes);
			state.settings = {
				received:true,
				facebook_app:action.settings.remote.facebook_app,
				facebook_graph_api_version:action.settings.remote.facebook_graph_api_version,
				facebook_scopes:scopes,
				facebook_required_scopes:action.settings.remote.facebook_required_scopes,
				force_versions:action.settings.remote.force_versions,
				facebook_deviceinput_serials:action.settings.remote.facebook_deviceinput_serials
			}
			return state;
		case ActionTypes.SET_FACEBOOK_CONNECTED:
			state.userFacebook.facebookConnected = action.connected;
			return state;
		case ActionTypes.SET_FACEBOOK_LIVE_BROADCASTS:
			// TODO: deleteme
			if (action.facebookLiveBroadcasts !== null)
			{
				state.facebookLiveBroadcasts = action.facebookLiveBroadcasts;
			}
			else
			{
				state.facebookLiveBroadcasts = [];
			}
			return state;
		case ActionTypes.SET_FACEBOOK_HAS_REQUIRED_SCOPES:
			state.userFacebook.hasRequiredScopes = action.hasRequiredScopes;
			return state;
		case ActionTypes.SET_FACEBOOK_LIVE_ENCODERS:
			state.facebookLiveEncoders = action.liveEncoders;
			state.facebookLiveEncoderMap = action.liveEncoderMap;
			state.userFacebook.receivedLiveEncoders = true;			
			return state;
		case ActionTypes.SET_FACEBOOK_PAGE_ACCOUNTS:
			state.facebookPageAccounts = action.facebookPageAccounts;
			return state;
		case ActionTypes.SET_WOWZA_PAIRING_STATUS:
			state.wowzaPairings.paired = action.paired;
			state.wowzaPairings.pairings = action.pairings;
			state.userWowza.super = action.super;
			if (action.jwtExpiration !== undefined)
				state.userWowza.jwtExpiration = action.jwtExpiration;
			if (action.jwtExpired !== undefined)
			{
				state.userWowza.jwtExpired = action.jwtExpired;
				if (action.jwtExpired === true)
				{
					state.userWowza.showExpiredSession = true;
				}
			}
			state.userWowza.receivedUserPairingStatus = true;
			return state;
		case ActionTypes.SHOW_EXPIRED_WOWZA_SESSION:
			if (state.userWowza.receivedUserPairingStatus)
			{
				state.userWowza.showExpiredSession = true;
			}
			else
			{
				state.userWowza.redirectToLogin = true;
			}
			return state;
		case ActionTypes.USER_FACEBOOK_LOGIN:
			for (var userInfoKey in action.userInfo)
			{
				state.userFacebook[userInfoKey] = action.userInfo[userInfoKey];
			}
			return state;
		case ActionTypes.USER_FACEBOOK_LOGOUT:
			state.userFacebook = {
				facebookConnected:FacebookConnectionState.NOT_CONNECTED,
				accessToken:"",
				email:"",
				id:"",
				name:"",
				picture:null,
				userID:""
			};
			return state;
		case ActionTypes.USER_FACEBOOK_RELOAD_ON_LOGOUT:
			state.facebookReloadOnLogout = action.facebookReloadOnLogout;
			state.facebookReloadParams = action.facebookReloadParams;
			return state;
		case ActionTypes.SET_USER_WOWZA_REQUIRED:
			state.userWowza.required = action.required;
			return state;
		case ActionTypes.SET_USER_JWT:
			state.userWowza.jwt = action.jwt;
			state.userWowza.username = action.username;
			return state;
		case ActionTypes.SET_FORCE_UPDATE_VERSION:
			state.forceUpdateVersion = action.forceUpdateVersion;
			return state;
		case ActionTypes.SET_ENCODER_INFO:
			state.encoderInfo[action.encoder_serial] = {
				pairing_status:action.pairing_status,
				wowza_org:action.wowza_org,
				wowza_org_name:action.wowza_org_name,
				uuid:action.uuid,
				registrant:action.registrant,
				pairing_date:action.pairing_date,
				encoder_name:action.encoder_name
			};
			return state;
		case ActionTypes.SET_ENCODER_INFO_CHANGE_STATUS:
			if (state.encoderInfo[action.encoder_serial]) {
				if (!state.encoderInfo[action.encoder_serial].changes)
					state.encoderInfo[action.encoder_serial].changes={}
			} else {
				state.encoderInfo[action.encoder_serial]={
					changes:{}
				};
			}
			state.encoderInfo[action.encoder_serial].changes[action.change]={
				status:action.status,
				error:action.error
			};
			return state;
		default:
			return state;
	}
}


export default createStore(clearCasterStore);
