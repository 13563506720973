import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class DialogSimulcastAddTarget extends Component
{

	initialState = {
		streamDuringPreview:0
	};

	constructor(props)
	{
		super(props);
		this.state=this.initialState;
		this.close = this.close.bind(this);
		this.onChangeStreamDuringPreview = this.onChangeStreamDuringPreview.bind(this);
	}

	close()
	{
		let id = this.props.clearcaster.dialogs.showSimulcastAddTarget.id;
		if (this.props.clearcaster.simulcast.streamTargets[id].start_mode !== this.state.streamDuringPreview) {
			let nextStreamTargetState = this.props.clearcaster.simulcast.streamTargets[id];
			nextStreamTargetState.start_mode=this.state.streamDuringPreview;
			this.props.controller.updateSimulcastStreamTarget(nextStreamTargetState);
		}
		this.props.controller.hideDialogSimulcastAddTarget();
	}

	onChangeStreamDuringPreview(event)
	{
		let nextState=this.state;
		nextState.streamDuringPreview=event.target.value ? 1:0;
		this.setState(nextState)
	}

	render()
	{
		if (this.props.clearcaster.dialogs.showSimulcastAddTarget.show === true && this.props.clearcaster.dialogs.showSimulcastAddTarget.id > 0) {
      let id = this.props.clearcaster.dialogs.showSimulcastAddTarget.id;
      let frame_size = this.props.clearcaster.simulcast.streamTargets[id].data.aspect_ratio_width.toString() + ' X ' + this.props.clearcaster.simulcast.streamTargets[id].data.aspect_ratio_height.toString();
      return (
				<Modal show={this.props.clearcaster.dialogs.showSimulcastAddTarget.show} onHide={this.close}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.strings.app.SimulcastingTargetAdded}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='col-md-6'>
							<b>{this.props.strings.app.SimulcastingLiveStreamName}</b><br/>
              {this.props.clearcaster.simulcast.streamTargets[id].data.name}
						</div>
						<div className='col-md-6'>
							<b>{this.props.strings.app.FrameSize}</b><br/>
              {frame_size}
						</div>
						<br/>
						<div className='col-md-12'>
							<input type='checkbox' name="streamDuringPreview" value={this.state.streamDuringPreview} onChange={this.onChangeStreamDuringPreview}/>
							<span>{this.props.strings.app.SimulcastingTargetSendStreamDuringPreview}</span>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle="primary" onClick={this.close}>{this.props.strings.app.OK}</Button>
					</Modal.Footer>
				</Modal>
      );
    } else {
			return null;
		}
	}
}

export default DialogSimulcastAddTarget;
