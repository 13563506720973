import React, { Component } from 'react';
import IntelligentMonitoring from '../components/IntelligentMonitoring';
import { Modal } from 'react-bootstrap';

class DialogIntelligentMonitoring extends Component
{

	constructor(props)
	{
		super(props);

		this.close = this.close.bind(this);
	}

	close(event)
	{
		this.props.controller.hideDialogIntelligentMonitoring();
		if (event !== null && !(typeof event === 'undefined'))
			event.preventDefault();
	}

	render()
	{
		let device = this.props.clearcaster.currentLiveEncoder;

		if (device == null)
			return null;

		return (
			<Modal show={this.props.clearcaster.dialogs.showIntelligentMonitoring} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.IntelligentMonitoringInfoColon } { device.name }</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<IntelligentMonitoring strings={this.props.strings} clearcaster={this.props.clearcaster} controller={this.props.controller} />
				</Modal.Body>

				<Modal.Footer>
					<form>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginRight:0}}>
								<button id="dialog-info-ok-button" className="btn btn-primary" onClick={this.close}>{ this.props.strings.app.OK }</button>&nbsp;
							</div>
						</div>
					</form>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default DialogIntelligentMonitoring;
