import { doubleEquals } from '../controller/ClearCasterAdminUtils';

export const EncodingSettingsAspectRatioRotation = 
{
	ROTATE_NONE:0.0,
	ROTATE_90:(Math.PI / 2),
	ROTATE_180:(Math.PI),
	ROTATE_270:(Math.PI + (Math.PI / 2))
}

const EncodingSettingsAspectRatio =
{
	NONE:{
		aspectRatioMode:0,
		aspectRatioWidth:0,
		aspectRatioHeight:0,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_NONE
	},
	SOURCE:{
		aspectRatioMode:1,
		aspectRatioWidth:0,
		aspectRatioHeight:0,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_NONE
	},
	SQUARE:{
		aspectRatioMode:2,
		aspectRatioWidth:1,
		aspectRatioHeight:1,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_NONE
	},
	STANDARD:{
		aspectRatioMode:3,
		aspectRatioWidth:4,
		aspectRatioHeight:3,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_NONE
	},
	VERTICAL_CROP:{
		aspectRatioMode:3,
		aspectRatioWidth:9,
		aspectRatioHeight:16,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_NONE
	},
	VERTICAL_ROTATE_RIGHT:{
		aspectRatioMode:3,
		aspectRatioWidth:9,
		aspectRatioHeight:16,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_90
	},
	VERTICAL_ROTATE_LEFT:{
		aspectRatioMode:3,
		aspectRatioWidth:9,
		aspectRatioHeight:16,
		aspectRatioRotation:EncodingSettingsAspectRatioRotation.ROTATE_270
	}
}

export const getEncodingSettingAspectRatio = (obj) =>
{
	var testObj = obj;
  if (obj['encodingConfigAspectRatioMode'] !== undefined &&
    obj['encodingConfigAspectRatioWidth'] !== undefined &&
		obj['encodingConfigAspectRatioHeight'] !== undefined)
  {
    var newObj = {};
    newObj['aspectRatioMode'] = obj['encodingConfigAspectRatioMode'];
    newObj['aspectRatioWidth'] = obj['encodingConfigAspectRatioWidth'];
		newObj['aspectRatioHeight'] = obj['encodingConfigAspectRatioHeight'];
		// 1. handle encoder versions prior to vertical rotation support
		if (obj['encodingConfigAspectRatioRotation'] !== undefined)
		{
			newObj['aspectRatioRotation'] = obj['encodingConfigAspectRatioRotation'];
		}
    testObj = newObj;
	}
	// 2. handle encoder versions prior to vertical rotation support
	if (testObj['aspectRatioRotation'] === undefined)
	{
		testObj['aspectRatioRotation'] = EncodingSettingsAspectRatioRotation.ROTATE_NONE;
	}
	if (testObj['aspectRatioMode'] !== undefined && 
    testObj['aspectRatioWidth'] !== undefined && 
		testObj['aspectRatioHeight'] !== undefined &&
		testObj['aspectRatioRotation'] !== undefined)
	{
		for (var key in EncodingSettingsAspectRatio)
		{
			let aspectRatio = EncodingSettingsAspectRatio[key];
			if (testObj['aspectRatioMode'] === aspectRatio['aspectRatioMode']
				&& testObj['aspectRatioWidth'] === aspectRatio['aspectRatioWidth']
				&& testObj['aspectRatioHeight'] === aspectRatio['aspectRatioHeight']
				&& doubleEquals(testObj['aspectRatioRotation'], aspectRatio['aspectRatioRotation']))
			{
				return aspectRatio;
			}
		}
	}
	return EncodingSettingsAspectRatio.NONE;
}

export const aspectRatiosEqual = (a,b) =>
{
	if (a['aspectRatioMode'] === undefined || a['aspectRatioWidth'] === undefined || a['aspectRatioHeight'] === undefined)
	{
		return false;
	}
	if (b['aspectRatioMode'] === undefined || b['aspectRatioWidth'] === undefined || b['aspectRatioHeight'] === undefined)
	{
		return false;
	}
	if (a['aspectRatioMode'] !== b['aspectRatioMode'] || 
		a['aspectRatioHeight'] !== b['aspectRatioHeight'] ||
		a['aspectRatioWidth'] !== b['aspectRatioWidth'])
	{
		return false;
	}
	if (a['aspectRatioRotation'] !== undefined && b['aspectRatioRotation'] !== undefined && !doubleEquals(a['aspectRatioRotation'],b['aspectRatioRotation']))
	{
		return false;
	}
	return true;
}

export const isAspectRatioVertical = (a) =>
{
	return aspectRatiosEqual(a,EncodingSettingsAspectRatio.VERTICAL_CROP) || aspectRatiosEqual(a,EncodingSettingsAspectRatio.VERTICAL_ROTATE_RIGHT) || aspectRatiosEqual(a,EncodingSettingsAspectRatio.VERTICAL_ROTATE_LEFT);
}

export default EncodingSettingsAspectRatio;
