import React, { Component } from 'react';

class Flags extends Component {
	render()
	{
		console.log('window.config');
		console.log(window.config);
		console.log('process.env');
		console.log(process.env);
		return (
			<div>
				<table className="table-bordered table-hover">
					<tbody>
						<tr>
							<th colSpan={2}>Flags</th>
						</tr>
						{ Object.keys(process.env).map((key,i)=>(
							<tr key={key}>
								<td>{key.replace('REACT_APP_','')}</td>
								<td>{process.env[key]}</td>
							</tr>
						))}
						<tr>
							<th colSpan={2}>Environment Config</th>
						</tr>
						{ Object.keys(window.config).map((key,i)=>(
							<tr key={key}>
								<td>{key.replace('REACT_APP_','')}</td>
								<td>{window.config[key]}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
export default Flags;