import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import { getCreatePairTopic } from '../transport/MQTTConnection';


export default function ClearCasterAdminControllerActivateMixin (controller)
{
	controller.setActivateForm = (activateForm) =>
	{
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_ACTIVATE_FORM,
			activateForm: activateForm
		});
	}

	controller.activateDevice = (pairWithFacebook) =>
	{
		let state = ClearCasterStore.getState();
		let pairingObj = {};
		pairingObj.subject = "create user encoder pair";
		pairingObj.pairing_code = state.activateForm.activationCode;
		pairingObj.pairing_name = state.activateForm.activateName;
		pairingObj.wowza_jwt = state.userWowza.jwt;
		if (pairWithFacebook)
		{
			pairingObj.fb_token = state.userFacebook.accessToken;
		}
		else
		{
			pairingObj.fb_token = '';
		}
		controller.mqttConnection.sendMessagePromise(getCreatePairTopic(state.userWowza.username),pairingObj)
		.then((data)=>{
			ClearCasterStore.dispatch({
				type: ActionTypes.ADD_PENDING_PERSONALITY_CHANGE,
				correlationid: data.correlationid,
				personality: state.activateForm.personality
			});
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.HIDE_DIALOG_FACEBOOK
		});
		ClearCasterStore.dispatch({
			type: ActionTypes.SET_ACTIVATE_FORM,
			activateForm: {
				activationCode:'',
				activateName:'',
				acceptedTOS:false
			}
		});
	}

	controller.checkJWTTimeout = () =>
	{
		let state = ClearCasterStore.getState();

		if (state.userWowza.jwtExpiration !== undefined)
		{
			let nowTime = new Date();
			let timeLeft = state.jwtExpirationTime - nowTime;
			if (timeLeft <= 0)
			{
				ClearCasterStore.dispatch({
					type:ActionTypes.SHOW_EXPIRED_WOWZA_SESSION
				});
			}
		}
	}

	controller.getPairedDeviceStatuses = () =>
	{
		controller.checkJWTTimeout();

		let currentTime = (new Date()).getTime();
		if (controller.statusTimeoutId !== null)
		{
			clearTimeout(controller.statusTimeoutId);
			controller.statusTimeoutId = null;
		}
		let state = ClearCasterStore.getState();

		for (var pairingKey in state.wowzaPairings.pairings)
		{
			let pairing = state.wowzaPairings.pairings[pairingKey];
			if (pairing.timestamp && typeof pairing.timestamp !== 'undefined')
			{
				if (currentTime - pairing.timestamp > process.env.REACT_APP_STATUS_TIMEOUT)
				{
					ClearCasterStore.dispatch({
						type: ActionTypes.SET_ENCODER_STATUS,
						currentLiveEncoderStatus:null,
						timestamp:currentTime,
						pairingKey:pairingKey
					});
				}
			}
			// console.log('----- getPairedDeviceStatuses loop, calling getDeviceStatus');
			controller.getDeviceStatus(pairing.encoder_uuid);
			controller.getDeviceLocalVersions(pairing.encoder_uuid);
		}
		controller.statusTimeoutId = setTimeout(function()
		{
			controller.getPairedDeviceStatuses();
		},process.env.REACT_APP_STATUS_INTERVAL);
	}
}
