import ClearCasterStore, { ActionTypes } from '../model/ClearCasterStore';
import { getUserChangeUUIDTopic } from '../transport/MQTTConnection';


export default function ClearCasterAdminControllerChangeUUIDMixin (controller)
{
  controller.changeUUIDForm = (changeUUIDForm) =>
  {
    ClearCasterStore.dispatch({
      type: ActionTypes.SET_CHANGE_UUID_FORM,
      changeUUIDForm: changeUUIDForm
    });
  }
  controller.clearCasterChangeUUID = () =>
	{
    let state = ClearCasterStore.getState();
    const clearCasterObj={
    subject:'encoder change uuid',
    encoder_serial:state.changeUUIDForm.serial,
    wowza_jwt:state.userWowza.jwt
  };
    controller.mqttConnection.sendMessage(getUserChangeUUIDTopic(state.userWowza.username),clearCasterObj);
	}
}