import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class DialogInfoPaired extends Component
{

	constructor(props)
	{
		super(props);

		this.renderDeviceInfo = this.renderDeviceInfo.bind(this);

		this.close = this.close.bind(this);
		this.sendLogs = this.sendLogs.bind(this);
		this.onShow = this.onShow.bind(this);
		this.formatFPS = this.formatFPS.bind(this);
	}

	onShow()
	{
		this.props.controller.getDeviceStatus(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		this.props.controller.getDeviceNetworkInfo(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
    this.props.controller.setDeviceSystemLogMQTTFilter(this.props.clearcaster.currentLiveEncoder.encoder_uuid,1);
	}

	close(event)
	{
		this.props.controller.hideDialogInfo();
    this.props.controller.setDeviceSystemLogMQTTFilter(this.props.clearcaster.currentLiveEncoder.encoder_uuid,3);
		if (event !== null && !(typeof event === 'undefined'))
			event.preventDefault();
	}

	sendLogs(event)
	{
		this.props.controller.sendLogs(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		if (event !== null)
			event.preventDefault();
	}

	renderNetworkInfo()
	{
		if (this.props.clearcaster.currentLiveEncoder.networkInfo !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.networkInfo !== 'undefined')
		{
			return(
				<div>
					{ this.props.clearcaster.currentLiveEncoder.networkInfo.macAddress !== null && (
						<p>
							<strong>MAC Address</strong><br />
							<span id="dialog-info-macaddress">{ this.props.clearcaster.currentLiveEncoder.networkInfo.macAddress }</span>
						</p>
					)}
					{ this.props.clearcaster.currentLiveEncoder.networkInfo.ipv4Address !== null && (
					<p>
						<strong>IPv4 Address</strong><br />
						<span id="dialog-info-ipv4address">{ this.props.clearcaster.currentLiveEncoder.networkInfo.ipv4Address }</span>
					</p>
					)}
					{ this.props.clearcaster.currentLiveEncoder.networkInfo.ipv6Address !== null && (
					<p>
						<strong>IPv6 Address</strong><br />
						<span id="dialog-info-ipv6address">{ this.props.clearcaster.currentLiveEncoder.networkInfo.ipv6Address }</span>
					</p>
					)}
				</div>
			);
		}
		else
		{
			return (<span></span>);
		}
	}

	renderDeviceInfo()
	{
		if (this.props.clearcaster.currentLiveEncoder.status !== null &&
			typeof this.props.clearcaster.currentLiveEncoder.status !== 'undefined' &&
			this.props.clearcaster.currentLiveEncoder.status.encoderUUID !== null &&
			this.props.clearcaster.currentLiveEncoder.status.encoderUUID === this.props.clearcaster.currentLiveEncoder.encoder_uuid)
		{
			let stateString = this.props.strings.app['EncoderState_'+this.props.clearcaster.currentLiveEncoder.status.encoderState.replace('-','_')];
			if (typeof stateString === 'undefined')
			{
				stateString = this.props.clearcaster.currentLiveEncoder.status.encoderState;
			}

			return (
				<div className="row text-muted">
					<div className="col-sm-6">
						<p>
							<strong>Encoder UUID</strong><br />
							<span id="dialog-info-encoder_uuid">{ this.props.clearcaster.currentLiveEncoder.status.encoderUUID }</span>
						</p>
						<p>
							<strong>System UUID</strong><br />
							<span id="dialog-info-system-uuid">{ this.props.clearcaster.currentLiveEncoder.status.systemUUID }</span>
						</p>
						<p>
							<strong>Serial Number</strong><br />
							<span id="dialog-info-serialnumber">{ this.props.clearcaster.currentLiveEncoder.status.clearCasterSerialNumber }</span>
						</p>
					</div>
					<div className="col-sm-6">
						<p>
							<strong>Software Version</strong><br />
							<span id="dialog-info-software-version">{ this.props.clearcaster.currentLiveEncoder.status.versionCurrent }</span>
						</p>
					{ this.renderNetworkInfo() }
					<p>
						<strong>Device Status</strong><br />
						<span id="dialog-info-status">{ stateString }</span>
					</p>
					</div>
				</div>
			);
		}
		else if (this.props.clearcaster.currentLiveEncoder.encoder_uuid || this.props.clearcaster.currentLiveEncoder.serial)
		{
			return (
				<div>
					{ this.props.clearcaster.currentLiveEncoder.encoder_uuid && (
						<div className="row text-muted">
							<div className="col-sm-12">
								<p>
									<strong>Encoder UUID</strong><br />
									<span id="dialog-info-encoder_uuid">{ this.props.clearcaster.currentLiveEncoder.encoder_uuid }</span>
								</p>
							</div>
						</div>
					)}
					{ this.props.clearcaster.currentLiveEncoder.serial && (
						<div className="row text-muted">
							<div className="col-sm-12">
								<p>
									<strong>Serial Number</strong><br />
									<span id="dialog-info-serialnumber">{ this.props.clearcaster.currentLiveEncoder.serial }</span>
								</p>
							</div>
						</div>
					)}
				</div>
			);
		}
		else
		{
			return null;
		}
	}

	formatFPS(fps)
	{
		if (Math.round(fps) === fps)
		{
			return fps+'';
		}
		else
		{
			return fps.toFixed(2);
		}

	}

	render()
	{
		let device = this.props.clearcaster.currentLiveEncoder;

		if (device == null)
			return null;

		return (
			<Modal show={this.props.clearcaster.dialogs.showInfo} onShow={this.onShow} onHide={this.close}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.props.strings.app.InfoColon } { device.name }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderDeviceInfo() }
				</Modal.Body>
				<Modal.Footer>
					<form>
						<div className="form-group" style={{marginBottom:0}}>
							<div className="row" style={{marginRight:0}}>
								<button id="dialog-info-ok-button" className="btn btn-primary" onClick={this.close}>{ this.props.strings.app.OK }</button>&nbsp;
								{/* <button className="btn btn-info" onClick={this.ok}>Restart</button>&nbsp; */}
								<button id="dialog-info-sendlogs-button" className="btn btn-default pull-left" onClick={this.sendLogs}>Send Logs</button>&nbsp;
							</div>
						</div>
					</form>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default DialogInfoPaired;
