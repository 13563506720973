import React, { Component } from 'react';
import { uiClearTextSelection } from '../UIUtils';

import './AudioInputLevelSlider.css';

class AudioInputLevelSlider extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {
			value: props.value,
			tentativeValue: props.value,
			stepNumerator: props.stepNumerator || 1,
			stepDenominator: props.stepDenominator || 1,
			visible: props.visible,
			knobStyle: {
				left: 0
			},
			levelStyle: {
				width: 0
			}
		};

		this.isDragging = false;
		this.sliderTrackBoundingRect = {left:0,width:0};
		this.sliderTrackLineBoundingRect = {left:0,width:0};
		this.sliderKnobBoundingRect = {left:0,width:0};
		this.sliderKnobOffset = 0;
		this.sliderKnobMinX = 0;
		this.sliderKnobMaxX = 0;

		this.calculateValueFromKnobX = this.calculateValueFromKnobX.bind(this);
		this.setTentativeValueFromClientX = this.setTentativeValueFromClientX.bind(this);
		this.calculateLevelWidthFromTentativeValue = this.calculateLevelWidthFromTentativeValue.bind(this);
		this.calculateKnobXFromTentativeValue = this.calculateKnobXFromTentativeValue.bind(this);
		this.onDown = this.onDown.bind(this);
		this.onUp = this.onUp.bind(this);
		this.onMove = this.onMove.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

	}
	componentDidMount()
	{
		this.getBoundingRects();

		this.setState({
			value: this.state.value,
			tentativeValue: this.state.tentativeValue,
			stepNumerator: this.state.stepNumerator,
			stepDenominator: this.state.stepDenominator,
			visible: this.state.visible,
			knobStyle: { left: this.calculateKnobXFromTentativeValue( this.state.value ) },
			levelStyle: { width: this.calculateLevelWidthFromTentativeValue( this.state.value )+'px' }
		});

		window.addEventListener('mousemove', this.onMove);
		window.addEventListener('mouseup', this.onUp);
	}
	componentWillUnmount()
	{
		window.removeEventListener('mousemove', this.onMove);
		window.removeEventListener('mouseup', this.onUp);
	}
	componentDidUpdate(prevProps, prevState)
	{
		// test if we received and update from above. if so, we won't call onChange()
		if (prevProps.tentativeValue !== this.props.tentativeValue ||
			prevProps.value !== this.props.value ||
			prevProps.visible !== this.props.visible)
		{
			this.getBoundingRects();
			this.setState({
				value: this.props.value,
				tentativeValue: this.props.tentativeValue,
				stepNumerator: this.props.stepNumerator || 1,
				stepDenominator: this.props.stepDenominator || 1,
				visible: this.props.visible,
				knobStyle: { left: this.calculateKnobXFromTentativeValue( this.props.tentativeValue ) },
				levelStyle: { width: this.calculateLevelWidthFromTentativeValue( this.props.tentativeValue )+'px' }
			});
		}
		// else see if we updated our state ourselves. if we did, call onChange()
		else if (prevState.tentativeValue !== this.state.tentativeValue || prevState.value !== this.state.value)
		{
			if (this.props.onChange)
				this.props.onChange(this.state);
		}

	}

	getBoundingRects()
	{
		this.sliderTrackBoundingRect = this.sliderTrack.getBoundingClientRect();
		this.sliderTrackLineBoundingRect = this.sliderTrackLine.getBoundingClientRect();
		this.sliderKnobBoundingRect = this.sliderKnob.getBoundingClientRect();

		this.sliderKnobOffset = Math.round( this.sliderKnobBoundingRect.width / 2 );
		this.sliderKnobMaxX = Math.round( this.sliderTrackBoundingRect.width - this.sliderKnobBoundingRect.width );
	}

	calculateValueFromKnobX(knobX)
	{
		let ratio = knobX / (this.sliderTrackBoundingRect.width - this.sliderKnobBoundingRect.width);
		let spread = this.props.max - this.props.min;
		let roughValue = ratio * spread + this.props.min;
		return Math.round( roughValue * this.state.stepDenominator / this.state.stepNumerator ) * this.state.stepNumerator / this.state.stepDenominator;
	}

	setTentativeValueFromClientX(clientX)
	{
		let knobX = Math.round( clientX - this.sliderTrackBoundingRect.left - this.sliderKnobOffset );
		knobX = Math.max(0, Math.min( (this.sliderTrackBoundingRect.width - this.sliderKnobBoundingRect.width), knobX ));
		let tentativeValue = this.calculateValueFromKnobX(knobX);
		this.getBoundingRects();
		this.setState({
			value: this.state.value,
			tentativeValue: tentativeValue,
			stepNumerator: this.state.stepNumerator,
			stepDenominator: this.state.stepDenominator,
			visible: this.state.visible,
			knobStyle: { left: this.calculateKnobXFromTentativeValue( tentativeValue )},
			levelStyle: { width: this.calculateLevelWidthFromTentativeValue( tentativeValue )+'px' }
		});
	}
	calculateLevelWidthFromTentativeValue( tentativeValue )
	{
		if (this.sliderTrackLineBoundingRect.width === 0)
			return 0;
		return ((tentativeValue - this.props.min) * this.sliderTrackLineBoundingRect.width / (this.props.max - this.props.min)) || 0;
	}
	calculateKnobXFromTentativeValue( tentativeValue )
	{
		if (this.sliderTrackBoundingRect.width === 0)
			return 0;
		let spread = this.props.max - this.props.min;
		return (tentativeValue - this.props.min) * (this.sliderTrackBoundingRect.width - this.sliderKnobBoundingRect.width) / spread;
	}
	onDown(event)
	{
		this.isDragging = true;
		this.setTentativeValueFromClientX( event.clientX );
	}
	onUp(event)
	{
		if (this.isDragging)
		{
			this.isDragging = false;
			this.getBoundingRects();
			this.setState({
				value: this.state.tentativeValue,
				tentativeValue: this.state.tentativeValue,
				stepNumerator: this.state.stepNumerator,
				stepDenominator: this.state.stepDenominator,
				visible: this.state.visible,
				knobStyle: { left: this.calculateKnobXFromTentativeValue( this.state.tentativeValue ) },
				levelStyle: { width: this.calculateLevelWidthFromTentativeValue( this.state.tentativeValue )+'px' }
			});
		}
	}
	onMove(event)
	{
		if (this.isDragging)
		{
			uiClearTextSelection();
			this.setTentativeValueFromClientX( event.clientX );
		}
	}
	onInputChange(event)
	{
		let suggestedValue = event.target.value;
		let value = Math.max( this.props.min, Math.min( this.props.max, suggestedValue));
		this.getBoundingRects();
		this.setState({
			value: value,
			tentativeValue: value,
			stepNumerator: this.state.stepNumerator,
			stepDenominator: this.state.stepDenominator,
			visible: this.state.visible,
			knobStyle: { left: this.calculateKnobXFromTentativeValue( value ) },
			levelStyle: { width: this.calculateLevelWidthFromTentativeValue( value )+'px' }
		});
	}


	render()
	{
		return (
			<div className="audioInputLevelChannel" id="audioInputLevelChannel">
				<div className="audioInputLevelChannelTitle audioInputLevelChannelRowItem"><i className="fa fa-volume-up fa-lg"></i></div>
				<div className="audioInputLevelChannelSlider audioInputLevelChannelRowItem"
					ref={(sliderTrack) => { this.sliderTrack = sliderTrack; }}
					onMouseDown={ this.onDown }
				>
					<div className="audioInputLevelChannelSliderTrackLine"
						ref={(sliderTrackLine) => { this.sliderTrackLine = sliderTrackLine; }}
					></div>
					<div className="audioInputLevelChannelSliderTrackLevel" style={ this.state.levelStyle }></div>
					<div className="audioInputLevelChannelSliderKnob"
						onMouseDown={ this.onDown }
						style={ this.state.knobStyle }
						ref={(knob) => { this.sliderKnob = knob; }}
					>
						<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" style={ {height:'100%',width:'100%' }}>
							<circle cx="50" cy="50" r="30" fill="#999" />
						</svg>
					</div>
				</div>
				<div className="audioInputLevelChannelValue audioInputLevelChannelRowItem">
					<input
						id="audio-level-input"
						className="audioInputLevelChannelValueInput"
						type="number" step="0.5"
						value={ this.state.tentativeValue }
						onChange={ this.onInputChange }
						max={ this.props.max }
						min={ this.props.min }
					/> dB
				</div>
			</div>
		);
	}
}
export default AudioInputLevelSlider
