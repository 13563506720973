import React from 'react';
import { Link } from 'react-router-dom';

// TODO: When internationalizing, solve two cases:
// 1. in App.js where we find a strings object and pass it down, and
// 2. in controller/* where we grab AppStrings directly

const date = new Date();

const AppStrings =
{
	calendarStrings: {
		lastDay : '[Yesterday at] LT',
		sameDay : '[Today at] LT',
		nextDay : '[Tomorrow at] LT',
		lastWeek : '[last] dddd [at] LT',
		nextWeek : 'dddd [at] LT',
		sameElse : 'L'
	},
	nav:{
		Account:"Account",
		Broadcasts:"Broadcasts",
		copyrightNotice:"" + String.fromCharCode(169) +
			" " + date.getFullYear() +
			" Wowza Media Systems" + String.fromCharCode(8482) + ", LLC. " +
			"All rights reserved.",
		ClearCasters:"ClearCasters",
		help:"Help",
		legalNotice:"Legal Notice",
		ManageSupportContacts:"Manage Support Contacts",
		privacyPolicy:"Privacy Policy",
		signIn:"Sign In",
		SignInToFacebook:"Sign in to Facebook",
		signOut:"Sign Out",
		SignOutFacebookPrompt:"Sign out of Facebook account: Are you sure?",
		SignOutWowzaPrompt:"Sign out of Wowza account: Are you sure?",		
		Simulcast:"Simulcast",
		Support:"Support",
		supportTools:"Support Tools",
		systemStatus:"System Status",
		wowzaHome:"Wowza Home",
		wowzaMediaSystemsLLC:"Wowza Media Systems&tm;, LLC"
	},
	error:{
		UserSessionExpired:"Your session has expired.",
		UserSessionSignInAgain:"Please sign in to continue."
	},
	app:{
		Activate:"Activate",
		ActivateEnterCode:"ClearCaster Activation Code",
		ActivateNow:"Activate",
		ActivatePrompt:"Turn on your ClearCaster and enter the activation code shown on the LCD in the box below.",
		ActivateTitle:"Activate a New ClearCaster",
		Analytics:"Analytics",
		ApplyChanges:"Save Changes",
		ApplyingChanges:"Saving Changes",
		AppliedChanges:"Changes Saved",
		AspectRatioNone:"",
		AspectRatioSource:"Match Input Source",
		AspectRatioSquare:"Square (1:1)",
		AspectRatioStandard:"Standard (4:3)",
		AspectRatioVerticalCrop:"Vertical Crop (9:16)",
		AspectRatioVerticalRotateLeft:"Vertical Rotate Left (9:16)",
		AspectRatioVerticalRotateRight:"Vertical Rotate Right (9:16)",
		AudioChannelMapping:"Audio Channel Mapping",
		AudioLevel:"Audio Level",
		Beta:"BETA",
		BroadcastConfirmGoLiveStreamTargetStopped: <span>Your simulcast target hasn't been started.  Click <strong>Go Live</strong> to start broadcasting to Facebook only, or click Cancel and start your simulcast before going live.</span>,
		BroadcastDefaultTitle:"Broadcast",
		BroadcastFacebookTarget:"Facebook Target",
		BroadcastAddClearCaster:"Add ClearCaster",
		BroadcastAddClearCasterForRedundancy:"+ add a ClearCaster for redundancy",
		BroadcastRemoveClearCaster:"Remove",
		BroadcastSelectClearCaster:"Select a ClearCaster",
		BroadcastProperty360Video:"360 video",
		BroadcastPropertyHighMotion:"High motion (60 fps)",
		BroadcastPropertyLiveDonate:"Live donate",
		BroadcastPropertyProfileVideo:"Vertical video (9:16)",
		BroadcastPropertySingleFisheye:"Single fisheye",
		BroadcastPropertySpatialAudio:"Spatial audio",
		BroadcastPropertySquareVideo:"Square video",
		BroadcastPropertyStandardVideo:"Standard video (4:3)",
		BroadcastPropertyOverlayVideoLabel:"Titling and Graphics",
		BroadcastPropertyOverlayVideoURLHint:"External URL...",
		BroadcastScheduledStartTime:"Scheduled Start Time",
		BroadcastStatus_DRAFT:"DRAFT",
		BroadcastStatus_OFFAIR:"OFF AIR",
		BroadcastStatus_PREVIEW:"PREVIEW",
		BroadcastStatus_UNPUBLISHED:"OFF AIR",
		BroadcastStatus_LIVE:"LIVE",
		BroadcastStatus_LIVE_NOW:"LIVE",
		BroadcastStatus_SCHEDULED:"SCHEDULED",
		BroadcastStatus_SCHEDULED_UNPUBLISHED:"SCHEDULED OFF AIR",
		BroadcastStatus_SCHEDULED_LIVE:"LIVE",
		BroadcastStatus_SCHEDULED_CANCELED:"CANCELED",
		BroadcastTab_BroadcastSetup:"Broadcast Setup",
		BroadcastTab_Monitoring:"Monitoring",
		BroadcastTab_MoreOptions:"More Options",
		BroadcastTab_Scheduling:"Scheduling",
		BroadcastTab_Simulcast:"Simulcast",
		BroadcastTab_Targeting:"Audience Restriction",
		BroadcastWarningGoLiveStreamTargetStopped:"Warning: Simulcast Target Not Started",
		BroadcastsActiveTitle:"Active Live Broadcasts",
		BroadcastsDraft:"Draft Broadcasts",
		BroadcastsLive:"Live Broadcasts",
		BroadcastsNoActiveLive:"No Active Live Broadcasts",
		BroadcastsOffAir:"Off Air Broadcasts",
		BroadcastsPreview:"Preview Broadcasts",
		BroadcastsScheduled:"Scheduled Broadcasts",
		Cancel:"Cancel",
		CancelConfirm:"Are you sure you want to cancel?",
		CaptureSetting:"Capture Setting",
		CaptureSettingAuto:"Auto",
		CaptureSettingHDMI:"HDMI",
		CaptureSettingPrompt:"Input to use when two sources detected",
		CaptureSettingSDI:"SDI",
		ChangesApplied:"Changes applied.",
		CheckForUpdates:"Check For Updates",
		ClearCaster:"ClearCaster",
		ClearCasterDevices:"ClearCaster Devices",
		ClearCasterLocation:"ClearCaster Location",
		ClearCasterName:"ClearCaster Name",
		ClearCasterPersonalityWarning:"Changing this will unpair the ClearCaster from your Facebook and Workplace accounts.",
		ClearCasterPersonality_FACEBOOK:"Facebook.com",
		ClearCasterPersonality_WORKPLACE:"Facebook Workplace",
		ClearCastersIManage:"ClearCasters",
		Close:"Close",
		Comments:"Comments",
		ContinuousHelp:"Continuous live videos will be deleted after your broadcast ends.",
		CountdownSettings:"Countdown Duration (in seconds)",
		Create:"Create",
		CreateBroadcast:"Create Broadcast",
		CreateBroadcastDestination:"Destination",
		CreateBroadcastPage:"Page",
		CreateBroadcastEvent:"Event",
		CreateBroadcastGroup:"Group",
		CreateLiveStream:"Create Live Stream",
		CurrentVersion:"Current version: {version}",
		CurrentVersionColon:"Current version:",
		Custom:"Custom",
		CustomMonitorView:"Engineering View",
		CustomMonitorViewEnable:"Enable (overrides Talent View Output setting)",
		CustomMonitorViewPreviewText:"http://clearcaster.wowza.com/monitor-views/engineering",
		CustomMonitorViewHelp:<span>To build your own custom monitor view, contact <a href="mailto:support@wowza.com">support@wowza.com</a>.</span>,
		DefaultNewClearCasterName:"Wowza ClearCaster",
		DefaultOnEncoder:"Default on Encoder",
		Delete:"Delete",
		DeleteBroadcast:"Delete Broadcast",
		DeleteConfirm:"Are you sure you want to delete this broadcast?",
		DeleteLiveBroadcastTitle:"Delete Live Broadcast",
		Description:"Description",
		DeviceID:"Device ID",
		DeviceInfo:"Device Info",
		Devices:"Devices",
		DeviceStatus:"Device Status",
		Disabled:"Disabled",
		DonateLiveButton:"Add a Donate button",
		DonateLiveHelpText:"Facebook only supports donations for posts to a Timeline or the verified Page of a charitable organization.",
		DonateLivePrompt:"Enter Facebook charity ID",
		DontSave:"Don't Save",
		Edit:"Edit",
		EmbeddableHelp:"If checked, this video can be embedded on third party websites",
		EncoderState_IDLE:"OFF AIR",
		EncoderState_LIVE:"LIVE",
		EncoderState_PREVIEW:"PREVIEW",
		EncoderState_READY:"OFF AIR",
		EncoderState_RUNNING:"LIVE",
		EncoderState_STARTING:"STARTING",
		EncoderState_STOPPING:"STOPPING",
		EncoderState_STREAM_PREVIEW:"PREVIEW",
		EncoderState_STREAM_RUNNING:"LIVE",
		EncoderState_UNKNOWN:"UNKNOWN",
		EncoderState_UNPAIRED_WOWZA:"UNPAIRED WITH WOWZA",
		EncoderState_UNPAIRED_GRAPHAPI:"UNPAIRED WITH FACEBOOK",
		EncoderState_UPDATING:"UPDATING",
		EndBroadcast:"End Broadcast",
		EndConfirm:"Are you sure you want to end this broadcast?",
		Error:"Error",
		ErrorUnknown:"Unknown error.",
		Everyone:"Everyone",
		FacebookConnect:"YES, Connect to Facebook",
		FacebookConnectedAs:"You are connected to Facebook as",
		FacebookDoNotConnect:"NO, Do not connect to Facebook",
		FacebookDoNotPair:"NO, Do not pair with Facebook",
		FacebookDotCom:"Facebook.com",
		FacebookIngestStreamHealth:"Facebook Ingest Stream Health",
		FacebookLiveStatistics:"Facebook Live Statistics",
		FacebookLogin:"Log in to Facebook",
		FacebookManageFacebookPrompt:<span>Manage your ClearCasters below, or you can start your broadcasts <a href="https://www.facebook.com/live/create" target="_blank" rel="noopener noreferrer">directly from Facebook <i className="fa fa-external-link"></i></a>.</span>,
		FacebookManageWorkplacePrompt:<span>Manage your ClearCasters below, or you can start your broadcasts directly from <a href="https://work.facebook.com/live/create" target="_blank" rel="noopener noreferrer">Workplace by Facebook <i className="fa fa-external-link"></i></a>.</span>,
		FacebookManageFacebookWorkplacePrompt:<span>Manage your ClearCasters below, or you can start your broadcasts from either <a href="https://www.facebook.com/live/create" target="_blank" rel="noopener noreferrer">Facebook <i className="fa fa-external-link"></i></a>, or <a href="https://work.facebook.com/live/create" target="_blank" rel="noopener noreferrer">Workplace by Facebook <i className="fa fa-external-link"></i></a>.</span>,
		FacebookNotConnected:"You are not connected to Facebook. Would you like to connect and pair this device? <br />You can pair later at {facebook-link}https://facebook.com/device{end-link}.",
		FacebookPairPrompt:"Would you like to pair with this account now? <br />You can pair later at {facebook-link}https://facebook.com/device{end-link}.",
		FacebookPairPromptWithCode:"To pair with someone else's Facebook account, have the account owner go to {facebook-link}https://facebook.com/device{end-link} and enter pairing code <strong>{pairing-code}</strong>.",
		FacebookPairPromptWithCodeChangeNotice:"(NOTE: pairing code changes every few minutes)",
		FacebookPairWithFacebook:"Pair with Facebook Account",
		FacebookPairConfirm:"Continue Pairing with {name}",
		FacebookPrivacyLevel_ALL_FRIENDS:"Friends",
		FacebookPrivacyLevel_CUSTOM:"Custom",
		FacebookPrivacyLevel_EVERYONE:"Public",
		FacebookPrivacyLevel_FRIENDS_OF_FRIENDS:"Friends of Friends",
		FacebookPrivacyLevel_SELF:"Only me",
		FacebookSignInAsDifferentUser:"To sign in as a different user, go to <a href='https://facebook.com' target='_blank'>facebook.com</a>",
		FacebookUnpairFacebookPrompt:"Would you like to unpair your ClearCaster from this Facebook account now? <br />You can pair again later at {facebook-link}https://facebook.com/device{end-link}.",
		FacebookUnpairWorkplacePrompt:"Would you like to unpair your ClearCaster from this Workplace account now? <br />You can pair again later at {facebook-link}https://work.facebook.com/device{end-link}.",
		FacebookUnpairWithFacebook:"Unpair with Facebook Account",
		FacebookUnpairWithWorkplace:"Unpair with Workplace Account",
		FacebookWorkplace:"Facebook Workplace",
		FBLivePromptIdle:"Preview on ",
		FBLivePromptManage:"or manage on ",
		FBLivePromptViewOnFB:"View Stream on Facebook",
		FBLivePromptPreview:"Start streaming on ",
		FBLivePromptStreaming:"Finish streaming on ",
		FBLivePromptStop:"End Broadcast",
		FBLivePromptStopping:"Finishing stream...",
		Female:"Female",
		Fix:"fix",
		FixHover:"Fix this encoder association",
		FixTitle:"Fix This Encoder Association",
		ForMoreInfoContactSupport:"For more information contact Wowza Support",
		FrameSize:"Frame Size",
		Gateway:"Gateway",
		GoLive:"Go Live",
		GoLive360Prompt:"This live stream is a 360 video",
		GoLive360FishEyePrompt:"This live stream is single fisheye",
		GoLive360SpatialAudioPrompt:"This live stream has spatial audio",
		GoLiveAllowEmbeddingPrompt:"Allow embedding",
		GoLiveAccordionOneHeading:"Post Location and Privacy",
		GoLiveAccordionTwoHeading:"Post Title and Description",
		GoLiveAccordionThreeHeading:"Advanced Options",
		GoLiveAccordionFourHeading:"Audience Restrictions",
		GoLiveCreatingStream:"Creating stream, please wait.",
		GoLiveDescriptionPrompt:"Description",
		GoLiveDestinationPrompt:"Where do you want to post this live stream?",
		GoLiveGoingLive:"Going live, please wait.",
		GoLiveOutputAspectRatio:"Output Aspect Ratio",
		GoLiveOutputAspectRatioSource:"Match Input Source",
		GoLiveOutputAspectRatioSquare:"Square (1:1)",
		GoLiveOutputAspectRatioStandard:"Standard (4:3)",
		GoLiveOutputAspectRatioVertical:"Vertical (9:16)",
		GoLiveOutputAspectRatioVerticalCrop:"Vertical Crop (9:16)",
		GoLiveOutputAspectRatioVerticalRotateLeft:"Vertical Rotate Left (9:16)",
		GoLiveOutputAspectRatioVerticalRotateRight:"Vertical Rotate Right (9:16)",
		GoLivePrivacyPrompt:"Who can see this live stream?",
		GoLivePrivacyTooltip:"Wowza Clearcaster is configured with a default privacy ceiling of Friends. This is used as the default audience for your posts from Wowza ClearCaster and is the most public audience you can use.",
		GoLiveShareEvent:"Share in an Event",
		GoLiveShareGroup:"Share in a Group",
		GoLiveSharePage:"Share on a Page you manage",
		GoLiveShareTimeline:"Share on your Timeline",
		GoLiveStoppingStream:"Stopping stream, please wait.",
		GoLiveTestBroadcast:"Test Broadcast",
		GoLiveTitlePrompt:"Title",
		GoLiveVideoGameTags:"Video Game Tags",
		GoLiveViewAdvancedOptions:"View Advanced Options",
		GoLiveVideoEmbedCode:"Video Embed Code",
		GoLiveWith:"Go Live with",
		GraphicContentPrompt:"Show graphic content warning",
		GraphicContentHelp:"Displays a warning and turns off autoplay. Graphic content will be hidden from people 18 and under.",
		History:"History",
		Home:"Home",
		Info:"Info",
		InfoColon:"Info:",
		IntelligentMonitoringAudioBitrate: "Audio Bitrate",
		IntelligentMonitoringBitrateCurrent: "Bitrate:Available",
		IntelligentMonitoringBitrateTarget: "Bitrate:Target",
		IntelligentMonitoringBitrateActual: "Bitrate:Actual",
		IntelligentMonitoringBytesWaitingToBeSent: "Bytes Waiting",
		IntelligentMonitoringCPUInfo: "CPU",
		IntelligentMonitoringCPUTemp: "Temperature",
		IntelligentMonitoringCPULoad: "Load",
		IntelligentMonitoringInfo:"Monitoring",
		IntelligentMonitoringInfoColon:"Monitoring:",
		IntelligentMonitoringInputResolution:"Input",
		IntelligentMonitoringLiveDuration:"Live Duration",
		IntelligentMonitoringNetworkPingTime: "Network Ping",
		IntelligentMonitoringOutputResolution:"Output",
		IntelligentMonitoringStreamBitrate: "Stream Bitrate",
		IntelligentMonitoringVideoBitrate: "Video Bitrate",
		IPAddress:"IP Address",
		LastActive:"Last active",
		LearnMore:"Learn more",
		Live:"Live",
		LiveWith:"Live with",
		MakePrimary:"Make primary",
		Male:"Male",
		MACAddress:"MAC Address",
		MobileVideoOptimization:"Mobile Video Optimization",
		MotionQuality:"High Motion Output",
		MotionQualityPrompt:"Broadcast 60 frames per second",
		MotionQualityHoverText:"If this stream has closed captions, setting to 60 fps may cause missing or duplicated captions on Facebook playback.",
		FacebookPublishPathOptimization:"Facebook Live Path Optimization",
		FacebookPublishPathOptimizationPrompt:"Optimize publish path to Facebook Live",
		FacebookPublishPathOptimizationHoverText:"If selected, a series of test are performed to determine the best network path to Facebook Live.",
		MyClearCasterDevices:"ClearCasters with Admin Access",
		NameThisClearCaster:"Name this ClearCaster",
		None:"None",
		NoneBraces:"[none]",
		Note:"Note:",
		No:"No",
		NoClearCastersAvailable:"No ClearCasters available",
		NoClearCastersAvailableSetupWarning:(props) => <span>There are no ClearCasters in this organization paired to the <strong>{props.userFacebookName}</strong> Facebook account. Go to the <Link to="/clearcasters" id="edit-broadcast-setup-clearcasters-link">ClearCasters</Link> page to pair.</span>,
		NoClearCastersAvailableWaiting:"Please wait while your ClearCasters become available",
		NoClearCastersAvailableWarning:"Broadcasts require your ClearCaster to be paired with a Facebook account.",
		OK:"OK",
		OtherClearCasterDevices:"ClearCasters with View-Only Access",
		OtherClearCasterDevicesMessage:"You have been granted limited access to the ClearCaster devices listed below",
		OutputAspectRatio:"Output Aspect Ratio",
		PairWithFacebook:"Pair with Facebook",
		PairWithWorkplace:"Pair with Workplace",
		Preview:"Preview",
		PrimaryClearCaster:"Primary ClearCaster",
		Privacy:"Privacy",
		PrivacyCeilingHover:"If you select a setting that is more public than your ClearCaster device's privacy ceiling, the privacy ceiling is used instead.",
		PrivacyCeilingHowToChange:"How to change the Facebook privacy ceiling",
		Ready:"Ready",
		Reboot:"Reboot",
		RedundantClearCaster:"Redundant ClearCaster",
		RegistrationInfo:"Registration Info",
		ReleaseNotes:"Release Notes",
		ReloadingBroadcasts:"Reloading Broadcasts ...",
		RemindMeLater:"Remind Me Later",
		RepairEncoderConfirm:(props) => <span>A ClearCaster can only be associated with one broadcast at a time. The live encoder <strong>{props.encoderName}</strong> is currently associated with the broadcast <strong>{props.broadcast1Title}</strong>.<br /><br />Would you like to change the live encoder's association from <strong>{props.broadcast1Title}</strong> to <strong>{props.broadcast2Title}</strong>?</span>,
		RepairEncoderNoBroadcastConfirm:(props) => <span>The live encoder <strong>{ props.encoderName }</strong> is currently not associated with any broadcasts.<br/><br/>Do you want to associate the live encoder with the broadcast <strong>{ props.broadcastTitle }</strong>?</span>,
		Restart:"Restart",
		RestartConfirm:"Restart: Are you sure?",
		RestartConfirmMessage:"Restarting your ClearCaster will disconnect any streams.",
		RestartConfirmButton:"Yes, Restart",
		Restarting:"Restarting",
		ReturnToBroadcastList:"<< Return to Broadcast List",
		RollbackBegin:"Begin Roll Back",
		RollbackDialogTitle:"Roll back to Prior Software Version",
		RollbackDialogPrompt1:"Rolling back is an advanced option that should only be done if absolutely necessary.",
		RollbackDialogPrompt2:"will reboot and install a prior software version. This process may take up to 30 minutes. Do not turn off the ClearCaster until the roll back is complete.",
		RollbackNotAvailable:"No roll back version available",
		RollbackToVersion:"Roll back to version: ",
		Save:"Save",
		SaveForLater:"Save For Later",
		SaveChangesPrompt:"Save changes before leaving this page?",
		Settings:"Settings",
		Input:"Input",
		SettingsColon:"Settings:",
		DeviceInputColon:"Device Input:",
		Simulcasting:"Simulcasting",
		SimulcastingAddTarget:"Add Target",
		SimulcastingConnCode:"Connection Code",
		SimulcastingEnter:"Enter the connection code from Wowza Streaming Cloud to add a new target for simulcasting.",
		SimulcastingLiveStreamName:"Live Stream Name",
		SimulcastingLoadingTargets:"Loading Targets...",
		SimulcastingRemoveTarget:"Remove Target",
		SimulcastingRemoveTargets:"Remove Targets",
		SimulcastingRemoveTargetPrompt:"Are you sure you want to remove target",
		SimulcastingRemoveTargetsPrompt:"Are you sure you want to remove the following targets?",
		SimulcastingRemoveTargetNote:"this will not delete the corresponding target from Wowza Streaming Cloud.",
		SimulcastingTargetAdded:"Target Added Successfully",
		SimulcastingTargetNotStarted:"Warning: Simulcast Target Not Started",
		SimulcastingTargets:"Targets",
		SimulcastingTargetSelectAccordionNotice:"You can stream from ClearCaster to your website or additional locations " +
			"via Wowza Streaming Cloud. Use the <a href='/simulcast'>Simulcast page</a> to add Wowza Streaming Cloud live stream targets.",
		SimulcastingTargetSelectAccordionNoTargets:"You haven't set up any simulcast targets.",
		SimulcastingTargetStreamDuringPreview:"Stream during Preview?",
		SimulcastingTargetStreamsDuringPreview:"(streams during preview)",
		SimulcastingTargetSendStreamDuringPreview:"Send stream to Wowza Streaming Cloud during preview",
		SimulcastTarget:"Simulcast Target",
		SoftwareVersion:"Software Version",
		Statistics:"Statistics",
		StatusPrompt:"Status:",
		Stop:"Stop",
		StreamHealthInfo:"Stream Health Info",
		StreamHealthInputResolution:"Input",
		StreamHealthOutputResolution:"Output",
		StreamHealthFPS:"Frame Rate",
		Support:"Support",
		Tags:"Tags",
		TagsPrompt:"Search for tags",
		TalentViewSettings:"Talent View Layout",
		TalentViewLargeComments:"Large Comments",
		TalentViewFullScreenVideo:"Full Screen Video",
		TargetAge:"Ages",
		TargetGender:"Gender",
		TargetLocation:"Location",
		TargetLocationPlaceholderText:"Enter country, state, province, or postal code to search",
		TimelineGroupEvent:"Timeline, Group, or Event",
		Title:"Title",
		TOSMessage:"I understand that my use of the Wowza ClearCaster device and included software, including ClearCaster device support, is governed by the {tos-link}Wowza ClearCaster Software End User License Agreement{end-link} (\"ClearCaster EULA\") and the {privacy-link}Wowza Privacy and Cookies policies{end-link}. I have read and I agree to be bound by the ClearCaster EULA, and the Wowza Privacy & Cookies policies.",
		TOSErrorMessage:"Please accept the Terms of Service",
		Unavailable:"Unavailable",
		Unpair:"unpair",
		UnpublishHelp:"Unpublished posts are not visible to the public. Page admins can still see unpublished posts and their insights.",
		UnpublishPrompt:"Unpublish after live video ends",
		UnsavedChanges:"Unsaved Changes",
		UpdateApply:"Apply Update",
		UpdateAvailable:"Update Available",
		UpdateAvailablePrompt:"A software update is available for your ClearCaster. This will bring your software version to {version}",
		UpdatePreview:"Update Preview",
		UpdatingElipsis:"Updating ...",
		Users:"Users",
		UseThisClearCasterWith:"Use this ClearCaster with",
		VideoEmbedCode:"Video Embed Code",
		VideoPreviewAppearsHere:"VIDEO PREVIEW APPEARS HERE",
		VideoSourceNotDetected:"Video source not detected",
		VideoSourceNotDetectedStrong:"NO VIDEO SOURCE DETECTED",
		VideoSourceMediaCaster:"Video source is IP stream",
		Vidpresso:"Vidpresso",
		ViewOnFacebook:"View on Facebook",
		Viewers:"Viewers",
		Workplace:"Workplace",
		WorkplaceCreatePrompt:<span>Or create a broadcast for <a href="https://work.facebook.com" target="_blank" rel="noopener noreferrer">Workplace by Facebook <i className="fa fa-external-link"></i></a></span>,
		WorkplaceHelpText:"Using your Wowza ClearCaster with Workplace by Facebook is by invitation only. Contact Wowza Support to request access.",
		WorkplaceWarning:<span> If you are using your ClearCaster with <strong>Workplace by Facebook</strong>, you must create broadcasts from the <a href="https://work.facebook.com" target="_blank"  rel="noopener noreferrer">Workplace site <i className="fa fa-external-link"></i></a></span>,
		Yes:"Yes",
		InputType:"Input Source",
		SourceCapture:"SDI or HDMI",
		SourceMediaCaster:"MediaCaster",
		MediaCasterType:"MediaCaster Type",
		MediaCasterStreamInfo:"MediaCaster Stream Information",
		MediaCasterTypeRTP:"rtp",
		MediaCasterTypeSRT:"srt",
		MediaCasterTypeLiveRepeater:"liverepeater",
		MediaCasterTypeAppleHLS:"applehls",
		Deinterlacing:"Deinterlacing",
		DecoderImplementation:"Decoder Implementation",
		DecoderImplementationDefault:"Default",
		DecoderImplementationQuickSync:"Intel Quick Sync",
		DeinterlacNone:"None",
		DeinterlacSingleRateDef:"Single Rate Default",
		DeinterlacSingleRateYADIF:"Single Rate YADIF",
		DeinterlacDoubleRateDef:"Double Rate Default",
		DeinterlacDoubleRateYADIF:"Double Rate YADIF",
	},
	unpairingTool:{
		ClearCasterName:"ClearCaster Name",
		ConfirmMessage:"Are you sure you want to unpair the selected ClearCasters?",
		ConfirmNo:"No, cancel",
		ConfirmYes:"Yes, unpair",
		DateCreated:"Date Created",
		NoDevices:"No Facebook Devices",
		PageTitle:"Wowza ClearCaster Unpairing Tool",
		Prompt:(props) => <span>This tool lists all ClearCasters currently paired to the <strong>{props.name}</strong> Facebook account.</span>,
		Status:"Status",
		UnpairSelected:"Unpair Selected"
	}
}

export default AppStrings
