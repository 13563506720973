export const AlertsActionTypes =
{
    RAISE_ALERT: 'RAISE_ALERT',
	DISMISS_ALERT: 'DISMISS_ALERT',
}

export default function ClearCasterAlertsReducer ( state = [], action )
{
    switch (action.type)
	{
		case AlertsActionTypes.RAISE_ALERT:
			state.push(action.alert);
			return state;
		case AlertsActionTypes.DISMISS_ALERT:
			for (var i = 0; i < state.length; i++)
			{
				if (state[i].id === action.id)
				{
					state.splice(i,1);
					return state;
				}
			}
			return state;
        default:
            return state;
    }
}
