import React, { Component } from 'react';
import { checkFeature } from '../../controller/ClearCasterAdminUtils';

class IntelligentMonitoring extends Component
{
    constructor(props)
	{
		super(props);
		this.formatFPS = this.formatFPS.bind(this);
    }

    componentWillMount()
    {
		this.props.controller.getDeviceStatus(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
		this.props.controller.getDeviceNetworkInfo(this.props.clearcaster.currentLiveEncoder.encoder_uuid);
        this.props.controller.setDeviceSystemLogMQTTFilter(this.props.clearcaster.currentLiveEncoder.encoder_uuid,1);
    }
    
    componentWillUnmount()
    {
        this.props.controller.setDeviceSystemLogMQTTFilter(this.props.clearcaster.currentLiveEncoder.encoder_uuid,3);
    }
    
	formatFPS(fps)
	{
		if (Math.round(fps) === fps)
		{
			return fps+'';
		}
		else
		{
			return fps.toFixed(2);
		}
    }

    renderVideoInputInfo() 
    {
        let inputResolution = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;

		let videoSignalMediaCaster = false;
		if (currentLiveEncoder.status.hasOwnProperty("broadcastInputConfig") && currentLiveEncoder.status.broadcastInputConfig.hasOwnProperty("inputType") && currentLiveEncoder.status.broadcastInputConfig.inputType === 7)
		{
			videoSignalMediaCaster = true;
		}

		if (videoSignalMediaCaster)
		{
			if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent.length > 0)
			{
				let logEvent = currentLiveEncoder.systemLogEvent[0];
				if (logEvent.encoder && logEvent.encoder.videoDecoderInfo)
				{
					inputResolution = logEvent.encoder.videoDecoderInfo.lastDecodedVideoFrameDisplayWidth + ' &times; ' + logEvent.encoder.videoDecoderInfo.lastDecodedVideoFrameDisplayHeight;
				}
			}
		}
        else if (checkFeature("videoSignalActive",currentLiveEncoder.status.versionCurrent) && ! currentLiveEncoder.status.deviceCaptureVideoSignalActive)
        {
            inputResolution = this.props.strings.app.VideoSourceNotDetectedStrong;
        }
        else if (currentLiveEncoder.status && currentLiveEncoder.status.captureSettingsVideo &&
            currentLiveEncoder.status.captureSettingsVideo !== null &&
            typeof currentLiveEncoder.status.captureSettingsVideo !== 'undefined')
        {
			if (typeof currentLiveEncoder.status.captureSettingsVideo.videoFrameWidth !== 'undefined' &&
                typeof currentLiveEncoder.status.captureSettingsVideo.videoFrameHeight !== 'undefined')
            {
                inputResolution = currentLiveEncoder.status.captureSettingsVideo.videoFrameWidth + ' &times; ' + currentLiveEncoder.status.captureSettingsVideo.videoFrameHeight;
    
                if (!currentLiveEncoder.status.captureSettingsVideo.videoProgressive)
                {
                    inputResolution += ' i ';
                }
                else
                {
                    inputResolution += ' p ';
                }
            }
			else if (typeof currentLiveEncoder.status.captureSettingsVideo.videoFrameRateEnum !== 'undefined' &&
                typeof currentLiveEncoder.status.captureSettingsVideo.videoFrameRateDen !== 'undefined')
            {
                inputResolution += this.formatFPS(currentLiveEncoder.status.captureSettingsVideo.videoFrameRateEnum / currentLiveEncoder.status.captureSettingsVideo.videoFrameRateDen);
            }
            else if (typeof currentLiveEncoder.status.videoCaptureCurrentFrameRateEnum !== 'undefined' &&
                typeof currentLiveEncoder.status.videoCaptureCurrentFrameRateDen !== 'undefined')
            {
                inputResolution += this.formatFPS(currentLiveEncoder.status.videoCaptureCurrentFrameRateEnum / currentLiveEncoder.status.videoCaptureCurrentFrameRateDen);
            }
        }
        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringInputResolution }</strong><br />
                    <span id="dialog-info-inputresolution" dangerouslySetInnerHTML={{ __html: inputResolution }}></span>
                </p>
            </div>
        );
    }
    
    renderVideoOutputInfo()
    {
        let outputResolution = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;
        if (currentLiveEncoder.status && currentLiveEncoder.status.publishEncodingSettingsVideo &&
            currentLiveEncoder.status.publishEncodingSettingsVideo !== null &&
            typeof currentLiveEncoder.status.publishEncodingSettingsVideo !== 'undefined')
        {
            if (typeof currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameWidth !== 'undefined' &&
                typeof currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameHeight !== 'undefined')
            {
                outputResolution = currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameWidth + ' &times; ' + currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameHeight;

                if (!currentLiveEncoder.status.publishEncodingSettingsVideo.videoProgressive)
                {
                    outputResolution += ' i ';
                }
                else
                {
                    outputResolution += ' p ';
                }
            }
            let outputFPS;
            if (typeof currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateEnum !== 'undefined' &&
                typeof currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateDen !== 'undefined')
            {
                outputFPS = currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateEnum / currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateDen;
                if (currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateDivisor)
                {
                    outputFPS = outputFPS / currentLiveEncoder.status.publishEncodingSettingsVideo.videoFrameRateDivisor;
                }
                outputResolution += this.formatFPS(outputFPS);
            }
            else if (typeof currentLiveEncoder.status.videoCaptureCurrentFrameRateEnum !== 'undefined' &&
                typeof currentLiveEncoder.status.videoCaptureCurrentFrameRateDen !== 'undefined')
            {
                outputFPS = currentLiveEncoder.status.videoCaptureCurrentFrameRateEnum / currentLiveEncoder.status.videoCaptureCurrentFrameRateDen;
                if (currentLiveEncoder.status.videoCaptureCurrentFrameRateDivisor)
                {
                    outputFPS = outputFPS / currentLiveEncoder.status.videoCaptureCurrentFrameRateDivisor;
                }
                outputResolution += this.formatFPS(outputFPS);
            }
        }
        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringOutputResolution }</strong><br />
                    <span id="dialog-info-outputresolution" dangerouslySetInnerHTML={{ __html: outputResolution }}></span>
                </p>
            </div>
        );
    }

    renderStreamBitrateInfo()
    {
        var audioBitrate = 0;
        let currentBitrate = this.props.strings.app.Unavailable;
        let targetBitrate = this.props.strings.app.Unavailable;
        let measureBitrate = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;
        let currentSystemEvent;
        if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1] )
            currentSystemEvent = currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1]
        
        let stream = undefined;
        if (currentSystemEvent && currentSystemEvent.encoder && currentSystemEvent.encoder.streams && currentSystemEvent.encoder.streams[0])
            stream = currentSystemEvent.encoder.streams[0];
        else if (currentSystemEvent && currentSystemEvent.encoder && currentSystemEvent.encoder.outputs && currentSystemEvent.encoder.outputs[0])
            stream = currentSystemEvent.encoder.outputs[0];

        if (stream !== undefined)
        {
            if (stream.audioBitrate && stream.audioBitrate != null )
            {
                audioBitrate = parseInt(stream.audioBitrate / 1000,10);
            }
            if (stream.videoBitrateCurrent && stream.videoBitrateCurrent != null )
            {
                currentBitrate = (parseInt(stream.videoBitrateCurrent / 1000,10)+audioBitrate).toString() + " Kbps";
            }
            if (stream.videoBitrate && stream.videoBitrate != null )
            {
                targetBitrate = (parseInt(stream.videoBitrate / 1000,10)+audioBitrate).toString() + " Kbps";
            }
            let bitrateMeasure = 0;
            if (stream.videoBitrateMeasure && stream.videoBitrateMeasure != null)
            {
                bitrateMeasure = (parseInt(stream.videoBitrateMeasure / 1000,10));
            }
            if (stream.audioBitrateMeasure && stream.audioBitrateMeasure != null)
            {
                bitrateMeasure += (parseInt(stream.audioBitrateMeasure / 1000,10));
            }
            if (bitrateMeasure > 0)
            {
                measureBitrate = bitrateMeasure + " Kbps";
            }
        }
        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringStreamBitrate }</strong><br />
                    <span id="dialog-info-target-video-bitrate">Target: {targetBitrate }</span><br />
                    <span id="dialog-info-current-video-bitrate">Current: {currentBitrate}</span><br />
                    <span id="dialog-info-current-video-bitrate">Actual: {measureBitrate}</span><br />
                </p>
            </div>
        );
    }
        
    renderCPUInfo()
    {
        // let cpuTemp = this.props.strings.app.Unavailable;
        let cpuLoad = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;
        let currentSystemEvent;
        if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1] )
            currentSystemEvent = currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1]
        if (currentSystemEvent && currentSystemEvent.hardwareDynamic &&
            currentSystemEvent.hardwareDynamic != null)
        {
            // if (currentSystemEvent.hardwareDynamic.cpuTempurature && currentSystemEvent.hardwareDynamic.cpuTempurature != null)
            // 	cpuTemp=parseInt(currentSystemEvent.hardwareDynamic.cpuTempurature,10).toString() + "\xB0C";
            if (currentSystemEvent.hardwareDynamic.cpuLoadSystem && currentSystemEvent.hardwareDynamic.cpuLoadSystem != null)
                cpuLoad=parseInt(currentSystemEvent.hardwareDynamic.cpuLoadSystem * 100,10).toString() + "%";
        }
        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringCPUInfo }</strong><br />
                    {/* <span id="dialog-info-cpu-temp">{this.props.strings.app.IntelligentMonitoringCPUTemp}: {cpuTemp}</span><br /> */}
                    <span id="dialog-info-cpu-load">{this.props.strings.app.IntelligentMonitoringCPULoad}: {cpuLoad}</span>
                </p>
            </div>
        )
    }

    getTransportInfo(currentSystemEvent)
    {
        let transportInfo = undefined;
        
        if (currentSystemEvent && currentSystemEvent.transport && currentSystemEvent.transport != null)
            transportInfo = currentSystemEvent.transport;
        else if (currentSystemEvent && currentSystemEvent.transports && currentSystemEvent.transports.length > 0)
            transportInfo = currentSystemEvent.transports[0];
        else if (currentSystemEvent && currentSystemEvent.streamTargets && currentSystemEvent.streamTargets.length > 0)
            transportInfo = currentSystemEvent.streamTargets[0];

        return transportInfo;
    }

    renderPingTimeInfo()
    {
        let pingTime = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;
        let currentSystemEvent;
        if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1] )
            currentSystemEvent = currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1];
        
        let transportInfo = this.getTransportInfo(currentSystemEvent);

        if (transportInfo !== undefined && transportInfo.pingTime && transportInfo.pingTime != null )
        {
            if (parseInt(transportInfo.pingTime) >= 0)
                pingTime=transportInfo.pingTime.toString() + " ms";
            else
                pingTime = "NA";
        }

        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringNetworkPingTime }</strong><br />
                    <span id="dialog-info-network-ping-time">{pingTime}</span>
                </p>
            </div>
        );
    }
    renderBytesWaitingToBeSent()
    {
        let writesToZero;
        let bytesWaiting = this.props.strings.app.Unavailable;
        let currentLiveEncoder = this.props.clearcaster.currentLiveEncoder;
        let currentSystemEvent, previousSystemEvent;
        if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1] )
        {
            currentSystemEvent = currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -1];
        }
        if (currentLiveEncoder.systemLogEvent && currentLiveEncoder.systemLogEvent.length > 1 &&
            currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -2])
        {
            previousSystemEvent = currentLiveEncoder.systemLogEvent[currentLiveEncoder.systemLogEvent.length -2];
        }

        let currentTransportInfo = this.getTransportInfo(currentSystemEvent);
        let previousTransportInfo = this.getTransportInfo(previousSystemEvent);

        if (currentTransportInfo !== undefined && currentTransportInfo.ioSessionScheduledWriteBytesToZero && currentTransportInfo.ioSessionScheduledWriteBytesToZero != null && 
            previousTransportInfo !== undefined && previousTransportInfo.ioSessionScheduledWriteBytesToZero && previousTransportInfo.ioSessionScheduledWriteBytesToZero != null)
        {
            writesToZero=currentTransportInfo.ioSessionScheduledWriteBytesToZero - previousTransportInfo.ioSessionScheduledWriteBytesToZero;
        }
        if ( writesToZero )
        {
            if ( writesToZero === 0 ) 
            {
                bytesWaiting = currentSystemEvent.transport.ioSessionScheduledWriteBytes.toString() + " bytes"
            } 
            else 
            {
                bytesWaiting = "0 bytes";
            }
        }
        return (
            <div className="col-sm-6">
                <p>
                    <strong>{ this.props.strings.app.IntelligentMonitoringBytesWaitingToBeSent }</strong><br />
                    <span id="dialog-info-bytes-waiting-to-be-sent">{bytesWaiting}</span>
                </p>
            </div>
        );
    }
    render()
    {
        if (this.props.clearcaster.currentLiveEncoder.systemLogEvent &&
            this.props.clearcaster.currentLiveEncoder.systemLogEvent != null &&
            this.props.clearcaster.currentLiveEncoder.status &&
            this.props.clearcaster.currentLiveEncoder.status.encoderState &&
            (this.props.clearcaster.currentLiveEncoder.status.encoderState === 'STREAM-RUNNING' ||
            this.props.clearcaster.currentLiveEncoder.status.encoderState === "STREAM-PREVIEW")) 
        {
            return (
                <div>
					<div className="row text-muted">
						{this.renderVideoInputInfo()}
						{this.renderVideoOutputInfo()}
					</div>
                    <div className="row text-muted">
                        {this.renderStreamBitrateInfo()}
                        {this.renderCPUInfo()}
                    </div>
                    <div className="row text-muted">
                        {this.renderPingTimeInfo()}
                        {this.renderBytesWaitingToBeSent()}
                    </div>
                </div>
            );
        } 
        else 
        {
            return null;
        }
    }
}

export default IntelligentMonitoring;