import LogLevels from './LogLevels';
import ClearCasterStore from '../model/ClearCasterStore';

const Log =
{
    log: function (level, message, error = null)
    {
        let state = ClearCasterStore.getState();
        let show = false;
        switch (state.logLevel)
        {
            case LogLevels.DEBUG:
                show = true;
                break;
            case LogLevels.INFO:
                if (level === LogLevels.ANNOUNCE || level === LogLevels.ERROR || level === LogLevels.INFO)
                    show = true;
                break;
            default:
                if (level === LogLevels.ANNOUNCE || level === LogLevels.ERROR)
                    show = true;
        }
        if (show)
        {
            console.log(this.prefix()+level+': '+message);
            if (error)
            {
                console.log(error);
                console.trace();
            }
        }
    },
    announce: function (message, error = null)
    {
        this.log (LogLevels.ANNOUNCE, message, error);
    },
    error: function (message, error = null)
    {
        this.log (LogLevels.ERROR, message, error);
    },
    info: function (message)
    {
        this.log (LogLevels.INFO, message);
    },
    debug: function (message)
    {
        this.log (LogLevels.DEBUG, message);
    },
    trace: function ()
    {
        console.log(this.prefix()+'TRACE:');
        console.trace();
    },
    prefix: function ()
    {
        let now = new Date();
        return('['+this.pad2(now.getHours())+':'+
            this.pad2(now.getMinutes())+':'+
            this.pad2(now.getSeconds())+':'+
            this.pad4(now.getMilliseconds())+'] ');
    },
    pad2: function (num)
    {
        if (num < 10)
            return '0'+num;
        else
            return ''+num;
    },
    pad4: function (num)
    {
        if (num < 10)
            return '000'+num;
        else if (num < 100)
            return '00'+num;
        else if (num < 1000)
            return '0'+num;
        else
            return ''+num;
    }
}
export default Log;
